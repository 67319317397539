import queryString from "query-string";
import { useSelector } from "react-redux";
import { People } from "@mui/icons-material";
import { Fragment, useEffect, useState } from "react";
import { Grid, Chip, Typography } from "@mui/material";

import Toast from "../../components/Alert";
import { useAxios, useComponent } from "../../hooks";
import { Loader, PageHeader } from "../../components";
import List from "./connection list components/List";
import {
  createCampaignObject,
  objectFromResponse,
} from "./connection list components/campaignObject";
import { toInternationalFormat } from "../../utils";
import { CancelButton } from "../css components/Button";
import pages from "../../constants/pages";
import { useNavigate } from "react-router-dom";

export default function Connection() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { loader } = useComponent();

  const [emptyList, setEmptyList] = useState(true);
  const [connections, setConnections] = useState([]);
  const [sentChanges, setSentChanges] = useState({
    requestSent: "",
    verifiedProof: "",
  });
  const [users, setUsers] = useState({
    common: "",
    total: "",
  });
  const [toast, setToast] = useState({
    state: false,
    type: "success",
    message: "",
  });
  const [connectNowButton, setConnectNowButton] = useState([]);
  const [formData, setFormData] = useState(createCampaignObject);
  const campaignID = queryString.parse(window.location.search).campaignID;
  const { activeBrand, userID } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    loader.start(2);

    axios({
      url:
        "/user/brand/getCampaign?brandId=" +
        parseInt(activeBrand.ID) +
        "&campaignId=" +
        parseInt(campaignID),
      method: "GET",
    }).then((response) => {
      if (response.success) {
        loader.apiComplete();

        if (response.data.status === "DRAFTED") {
          navigate(pages.campaignDetails.route + parseInt(campaignID));
        }
        setFormData(objectFromResponse(response.data));
      }
    });

    let arr = [];
    axios({
      url: "/user/getRelevantDataProviders",
      method: "POST",
      data: {
        sender_userId: parseInt(userID),
        sender_brandId: parseInt(activeBrand.ID),
        sender_campaignId: parseInt(campaignID),
      },
    }).then((response) => {
      if (response.success) {
        // axios({
        //   url: "/user/getDistictUserCount",
        //   method: "POST",
        //   data: {
        //     sender_userId: parseInt(userID),
        //     sender_brandId: parseInt(activeBrand.ID),
        //     sender_campaignId: parseInt(campaignID),
        //   },
        // }).then((user) => {
        //   if (user.success) {
        //     loader.apiComplete();
        //     setUsers({
        //       common: user.userCount,
        //       total: response.totalUsers,
        //     });
        //   }
        // });

        for (let con of response.data) {
          if (con !== null && Object.keys(con.brands).length !== 0) {
            arr.push({
              business_entity_id: con.business_entity_id,
              email: con.email,
              is_relevant_dp: con.is_relevant_dp,
              isRequested: con.isRequested,
              p_id: con.p_id,
              user_id: con.user_id,
              proof: con.proof,
              note: con.note || "",
              requestStatus: con.requestStatus,
              brand: {
                logo: con.brands?.logo,
                mau: toInternationalFormat(con.brands?.mau) || "",
                brandId: con.brands?.brandId,
                category: con.brands?.category,
                languages: con.brands?.languages,
                locations: con.brands?.locations,
                name: con.brands?.name,
              },
            });
          }
        }

        let isRelevantDpTrue = arr.filter((item) => {
          return item.is_relevant_dp;
        });
        let isRelevantDpFalse = arr.filter((item) => {
          return !item.is_relevant_dp;
        });

        let sortArray = [...isRelevantDpTrue, ...isRelevantDpFalse];

        setConnectNowButton(
          sortArray.map(() => ({
            forceConnectNow: false,
            connectNow: false,
            verifying: false,
          }))
        );

        setConnections(sortArray);
        loader.apiComplete();
      } else {
        setEmptyList(false);
        loader.stop(2);
      }
    });
  }, [axios, loader, campaignID, activeBrand.ID, userID]);

  useEffect(() => {
    let arr = [];

    axios({
      url: "/user/getRelevantDataProviders",
      method: "POST",
      data: {
        sender_userId: parseInt(userID),
        sender_brandId: parseInt(activeBrand.ID),
        sender_campaignId: parseInt(campaignID),
      },
    }).then((response) => {
      if (response.success) {
        // axios({
        //   url: "/user/getDistictUserCount",
        //   method: "POST",
        //   data: {
        //     sender_userId: parseInt(userID),
        //     sender_brandId: parseInt(activeBrand.ID),
        //     sender_campaignId: parseInt(campaignID),
        //   },
        // }).then((user) => {
        //   if (user.success) {
        //     setUsers({
        //       common: user.userCount,
        //       total: response.totalUsers,
        //     });
        //   }
        // });

        for (let con of response.data) {
          if (con !== null && Object.keys(con.brands).length !== 0) {
            arr.push({
              business_entity_id: con.business_entity_id,
              email: con.email,
              is_relevant_dp: con.is_relevant_dp,
              isRequested: con.isRequested,
              p_id: con.p_id,
              user_id: con.user_id,
              proof: con.proof,
              note: con.note || "",
              requestStatus: con.requestStatus,
              brand: {
                logo: con.brands?.logo,
                mau: toInternationalFormat(con.brands?.mau) || "",
                brandId: con.brands.brandId,
                category: con.brands?.category,
                languages: con.brands?.languages,
                locations: con.brands?.locations,
                name: con.brands?.name,
              },
            });
          }
        }
        let isRelevantDpTrue = arr.filter((item) => {
          return item.is_relevant_dp;
        });
        let isRelevantDpFalse = arr.filter((item) => {
          return !item.is_relevant_dp;
        });

        let sortArray = [...isRelevantDpTrue, ...isRelevantDpFalse];

        setConnectNowButton(sortArray.map(() => false));

        setConnections(sortArray);
      } else {
        setEmptyList(false);
      }
    });
  }, [
    axios,
    campaignID,
    activeBrand.ID,
    userID,
    sentChanges.requestSent,
    sentChanges.verifiedProof,
  ]);

  return (
    <Loader>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            heading={formData.campaign_name?.value}
            pageName={"Recommended Connections"}
          />
        </Grid>

        <Grid item xs={12} />

        <Grid item xs={6}>
          <Chip
            style={{
              color: "#fff",
              backgroundColor: "rgba(52, 181, 58, 1)",
              height: "50px",
              paddingInline: "5px",
            }}
            icon={<People style={{ color: "#fff" }} />}
            label={"Unique User Count: " + (users.total - users.common)}
          />
        </Grid>

        <Grid item xs={6} textAlign="end">
          <CancelButton
            variant="outlined"
            onClick={() => navigate(pages.campaignDetails.route + campaignID)}
          >
            {" "}
            Back
          </CancelButton>
        </Grid>

        <Grid item xs={12} />
      </Grid>

      {emptyList ? (
        <Fragment>
          <List
            campaignID={campaignID}
            connections={connections}
            setSentChanges={setSentChanges}
            toast={toast}
            setToast={setToast}
            connectNowButton={connectNowButton}
            setConnectNowButton={setConnectNowButton}
            campaignCreatedBy={formData.campaignCreatedBy}
          />
          <Toast alert={toast} setAlert={setToast} />
        </Fragment>
      ) : (
        <Grid item xs={12} textAlign="center">
          <Typography style={{ fontWeight: "500", fontSize: "30px" }}>
            No campaign request Yet!
          </Typography>
        </Grid>
      )}
    </Loader>
  );
}
