import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import { useAxios, useAlert } from "../../../hooks";
import {
  objectFromFormData,
  objectFromResponse,
  validationSchema,
} from "./brandDetailObject";
import { setActiveBrand } from "../../../redux/slices/memberSlice";

export default function Form({
  formData,
  editState,
  categories,
  location,
  languages,
  setEditState,
  setFormData,
}) {
  const axios = useAxios();

  const { alert } = useAlert();
  const dispatch = useDispatch();

  const memberDetails = useSelector((state) => state.memberDetails);

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        axios({
          url: "/user/brand/" + formData.brandID,
          method: "PUT",
          data: objectFromFormData(formData, memberDetails),
          disableRedirect: true,
        })
          .then((res) => {
            if (res.success) {
              dispatch(
                alert({
                  message: "Brand updated successfully!",
                  type: "success",
                })
              );
              sessionStorage.setItem("activeBrand", res.data.brand_id);
              dispatch(setActiveBrand(res.data));
              setFormData(objectFromResponse(res.data));
              setEditState(false);
              setSubmitting(false);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <FormDisplay
          categories={categories}
          location={location}
          languages={languages}
          editState={editState}
          formikProps={formikProps}
          setEditState={setEditState}
        />
      )}
    </Formik>
  );
}
