import React, { useRef } from "react";
import styled from "styled-components/macro";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

import APIError from "../pages/misc/APIError";
import TimeOut from "../pages/misc/TimeOut";
import InternetDown from "../pages/misc/InternetDown";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Loader({ children, disableHomeButton, height }) {
  const firstPageRender = useRef(true);
  const loader = useSelector((state) => state.loaderDetails);

  if (firstPageRender.current || loader.loading) {
    firstPageRender.current = false;

    return (
      <Wrapper style={{ height: height || "100%" }}>
        <CircularProgress sx={{ color: "#02A0FC" }} />
      </Wrapper>
    );
  }

  if (loader.error) {
    return (
      <Wrapper>
        <APIError disableHomeButton={disableHomeButton} />
      </Wrapper>
    );
  }

  if (loader.timeOut) {
    return (
      <Wrapper>
        <TimeOut disableHomeButton={disableHomeButton} />
      </Wrapper>
    );
  }

  if (loader.internetDown) {
    return (
      <Wrapper>
        <InternetDown disableHomeButton={disableHomeButton} />
      </Wrapper>
    );
  }
  return children;
}

export default Loader;
