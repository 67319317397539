import * as Yup from "yup";

export const createBrandObject = {
  ID: "",
  businessEntityId: "",
  brandName: "",
  website: "",
  audienceLocation: [],
  audienceLanguages: [],
  socialMedia: {
    facebook: "",
    instagram: "",
    twitter: "",
    whatsapp: "",
  },
  brandLogo: "",
  about: "",
  onlineStore: "",
  siteCategory: [],
  monthlyActiveUsers: "",
};

export const validationSchema = Yup.object().shape({
  brandName: Yup.string().required("Brand name is required."),
  audienceLocation: Yup.array()
    .min(1, "At least one option must be selected.")
    .required("Audience location is required."),
  audienceLanguages: Yup.array()
    .min(1, "At least one option must be selected.")
    .required("Audience language is required."),
  brandLogo: Yup.mixed().required("Brand Logo is required."),
  // .test("fileType", "Only JPEG or PNG images are allowed.", function (value) {
  //   if (!value) return true; // Skip validation if no file is selected

  //   // Check if the file type is JPEG or PNG
  //   console.log(value);
  //   return (
  //     value &&
  //     (value.type === "image/jpg" ||
  //       value.type === "image/jpeg" ||
  //       value.type === "image/png")
  //   );
  // }),
  about: Yup.string().required("About is required."),
  siteCategory: Yup.array()
    .min(1, "At least one option must be selected.")
    .required("Category is required."),
  socialMedia: Yup.object().shape({
    facebook: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.?[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    instagram: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.?[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    twitter: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.?[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
    whatsapp: Yup.string(),
  }),

  website: Yup.string().matches(
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9:@%._?=/-]{2,}(\.[a-zA-Z0-9:@%._?=/-]{2,})(\.[a-zA-Z0-9:@%._?=/-]{2,})?/,
    "Enter correct url!"
  ),
  onlineStore: Yup.string().matches(
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9:@%._?=/-]{2,}(\.[a-zA-Z0-9:@%._?=/-]{2,})(\.[a-zA-Z0-9:@%._?=/-]{2,})?/,
    "Enter correct url!"
  ),
});

export function objectFromFormData(formData, memberDetails) {
  return {
    business_entity_id: memberDetails.businessEntityID,
    about: formData.about,
    online_store: formData.onlineStore,
    brand_name: formData.brandName,
    website: formData.website,
    audience_location: formData.audienceLocation,
    social_media_handles: formData.socialMedia,
    brandLogo: formData.brandLogo,
    mau: formData.monthlyActiveUsers,
    site_category: formData.siteCategory,
    languages: formData.audienceLanguages,
  };
}
