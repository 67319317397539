import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { withRouter } from "../router/useRouter";
import { signOutMember } from "../redux/slices/memberSlice";

const AuthVerify = (props) => {
  const dispatch = useDispatch();
  let location = props.router.location;

  useEffect(() => {
    const loggedOutTime = localStorage.getItem("expirySession");

    if (loggedOutTime) {
      if (loggedOutTime < Date.now()) {
        dispatch(signOutMember());
      }
    }
  }, [location, dispatch]);

  return <div></div>;
};

export default withRouter(AuthVerify);
