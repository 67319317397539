import { Fragment } from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Paper } from "../../../components";
import connection from "../../../assets/connection.svg";
import pages from "../../../constants/pages";

export default function ExploreConnection({ formikProps }) {
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={2}
      style={{ paddingTop: "10px", paddingLeft: "10px" }}
    >
      <Grid item xs={12}>
        <img
          src={connection}
          alt="!"
          style={{ width: "100%", cursor: "pointer" }}
          onClick={() =>
            navigate(
              pages.exploreConnections.route +
                "?campaignID=" +
                formikProps.values.campaignID
            )
          }
        />
      </Grid>
    </Grid>
  );
}
