import { Fragment } from "react";
import { Grid, Typography } from "@mui/material";

import Label from "./Label";
import TextField from "./TextField";

export default function CreatedAndUpdatedInfo({ formikProps }) {
  return (
    <Fragment>
      {formikProps.values.createdBy && (
        <Fragment>
          <Grid item xs={12}>
            <Label
              title={"Created By"}
              formikProps={formikProps}
              name={"created_by"}
            />
          </Grid>

          <Grid item xs={10}>
            <TextField name="createdBy" disabled formikProps={formikProps} />
          </Grid>
        </Fragment>
      )}

      {formikProps.values.createdAt && (
        <Fragment>
          <Grid item xs={10}>
            <Typography
              variant="subtitle1"
              style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}
            >
              Created At : &nbsp; {formikProps.values.createdAt}
            </Typography>
          </Grid>

          {/* <Grid item xs={10}>
            <TextField
              name="createdAt"
              value={formikProps.values.createdAt}
              disabled
              formikProps={formikProps}
            />
          </Grid> */}
        </Fragment>
      )}

      {formikProps.values.updatedBy && (
        <Fragment>
          <Grid item xs={12}>
            <Label
              title={"Updated By"}
              formikProps={formikProps}
              name={"updated_by"}
            />
          </Grid>

          <Grid item xs={10}>
            <TextField name="updatedBy" disabled formikProps={formikProps} />
          </Grid>
        </Fragment>
      )}

      {formikProps.values.updatedAt && (
        <Fragment>
          <Grid item xs={10}>
            <Typography
              variant="subtitle1"
              style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}
            >
              Updated At : &nbsp; {formikProps.values.updatedAt}
            </Typography>
          </Grid>

          {/* <Grid item xs={10}>
            <TextField
              name="updatedAt"
              value={formikProps.values.updatedAt}
              disabled
              formikProps={formikProps}
            />
          </Grid> */}
        </Fragment>
      )}
    </Fragment>
  );
}
