import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";

import pages from "../../constants/pages";
import List from "./audience list components/List";
import { useAxios, useComponent } from "../../hooks";
import { Loader, PageHeader } from "../../components";
import Search from "./audience list components/Search";
import SortBy from "./audience list components/SortBy";
import { PrimaryButton } from "../css components/Button";
import { NoListTypography } from "../css components/Style";

export default function AudienceList() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { loader } = useComponent();

  const [audiences, setAudiences] = useState([]);

  const { activeBrand } = useSelector((state) => state.memberDetails);

  useEffect(() => {
    loader.start();
    axios({
      url: "/brand/getAllAudiences?brandId=" + activeBrand.ID,
    }).then((response) => {
      if (response.success) {
        let arr = [];

        for (let key of response.data) {
          let data = {};
          for (let fields of key.fields) {
            if (Array.isArray(Object.values(fields)[0])) {
              data[Object.keys(fields)[0]] =
                Object.values(fields)[0].length > 0
                  ? Object.values(fields)[0]
                  : [];
            }

            if (typeof Object.values(fields)[0] === "string") {
              data[Object.keys(fields)[0]] = !!Object.values(fields)[0]
                ? Object.values(fields)[0]
                : "";
            }

            if (
              typeof Object.values(fields)[0] === "object" &&
              !Array.isArray(Object.values(fields)[0])
            ) {
              data[Object.keys(fields)[0]] =
                Object.keys(key)[0].length > 0 ? Object.values(fields)[0] : {};
            }
          }
          arr.push({
            ...data,
            audience_id: key.audience_id,
          });
        }
        setAudiences(arr);
        loader.stop();
      }
    });
  }, [axios, activeBrand.ID, loader]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader pageName={"My Audiences"} />
        </Grid>

        <Grid item xs={9} />

        {/* <Search />

        <SortBy /> */}

        <Grid item xs={4} sm={3} alignSelf="center" textAlign="right">
          <PrimaryButton
            variant="contained"
            size="large"
            onClick={() => navigate(pages.createAudience.route)}
          >
            Create Audience
          </PrimaryButton>
        </Grid>

        <Grid item xs={12} />
      </Grid>

      <Loader height="75%">
        {audiences.length > 0 ? (
          <List audiences={audiences} />
        ) : (
          <Grid item xs={12} textAlign="center">
            <NoListTypography>No audience created Yet!</NoListTypography>
          </Grid>
        )}
      </Loader>
    </Fragment>
  );
}
