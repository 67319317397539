import { Formik } from "formik";

import FormDisplay from "./FormDisplay";

export default function Form({ formData, editState, setEditState, brands }) {
  return (
    <Formik enableReinitialize initialValues={formData} validateOnMount>
      {(formikProps) => (
        <FormDisplay
          formikProps={formikProps}
          editState={editState}
          setEditState={setEditState}
          brands={brands}
        />
      )}
    </Formik>
  );
}
