import { useSelector } from "react-redux";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Form from "./Form";
import { Loader } from "../../../components";
import { useAxios, useComponent } from "../../../hooks";
import {
  createCampaignObject,
  objectFromResponse,
} from "../create campaign components/campaignObject";
import {
  brandDetailsObject,
  objectFromBrandResponse,
} from "../brand details components/brandDetailsObject";
import { audienceObject } from "../../audience components/create audience components/audienceObjects";
import BASE_URL from "../../../constants/baseURL";

export default function CampaignRequestDetails() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const campaignID = params.campaignID || "";
  const { activeBrand, categories, userID, location, languages } = useSelector(
    (state) => state.memberDetails
  );
  const brandID = queryString.parse(window.location.search).brandID;
  const senderUserID = queryString.parse(window.location.search).sender_id;

  const [icon, setIcon] = useState("");

  const [audiences, setAudiences] = useState([]);
  const [formData, setFormData] = useState(createCampaignObject);
  const [audienceData, setAudienceData] = useState(audienceObject);
  const [brandDetails, setBrandDetails] = useState({
    data: brandDetailsObject,
    state: false,
  });

  const [takeUpOffer, setTakeUpOffer] = useState({
    state: false,
    action: "",
    data: {
      sender_brandId: parseInt(brandID),
      sender_campaignId: parseInt(campaignID),
      receiver_userId: parseInt(userID),
      receiver_brandId: parseInt(activeBrand.ID),
      dp_note: "",
    },
  });

  const [connectionRealStats, setConnectionRealStats] = useState([]);

  useEffect(() => {
    let connectionRequestStatus = false;

    loader.start(5);
    axios({ url: "/brand/getAllAudiences?brandId=" + brandID }).then((res) => {
      if (res.success) {
        let arr = [];

        for (let key of res.data) {
          let data = {};
          for (let fields of key.fields) {
            data[Object.keys(fields)[0]] = Object.values(fields)[0];
          }
          arr.push({
            ...data,
            audience_id: key.audience_id,
          });
        }
        setAudiences(arr);

        loader.apiComplete();
      }
    });

    if (!!campaignID) {
      let campaignData = {};
      axios({
        url:
          "/user/brand/getCampaign?brandId=" +
          parseInt(brandID) +
          "&campaignId=" +
          parseInt(campaignID),
        method: "GET",
      }).then(async (response) => {
        if (response.success) {
          if (response.data.audience.audience_id !== -1) {
            axios({
              url:
                "/brand/getDetailsOfAudience/" +
                response.data.audience.audience_id,
            }).then(async (res) => {
              if (res.success) {
                let data = {};

                for await (let key of res.data.fields) {
                  data[Object.keys(key)[0]] = {
                    value: Object.values(key)[0],
                    mandatory: Object.values(key)[1],
                  };
                }

                data = {
                  ...data,
                  ID: res.data.audience_id,
                  brandID: res.data.brand_id,
                };

                await setAudienceData(data);
                loader.apiComplete(1);
              }
            });
          } else {
            loader.apiComplete(1);
          }

          await axios({
            url: "/user/brand/campaign/creatives?campaignId=" + campaignID,
          }).then(async (creatives) => {
            if (creatives.success) {
              axios({
                url: "/adserver/listAdTypeForChannel",
              }).then(async (resp) => {
                let campaignStatus = "";
                if (response.data.connections.length > 0) {
                  for (let connection of response.data.connections) {
                    if (connection.receiver_brandId === activeBrand.ID) {
                      campaignStatus = connection.requestStatus;

                      connectionRequestStatus =
                        connection.requestStatus == "ACCEPTED" ? true : false;
                    }
                  }
                }

                campaignData = {
                  ...response.data,
                  creatives: creatives.data,
                  campaignStatus: campaignStatus,
                };

                await setFormData(
                  objectFromResponse(campaignData, resp.adTypes.items)
                );

                if (connectionRequestStatus) {
                  axios({
                    url: `/bulk/adserver/bulkcounts/${campaignID}?days=0&receiverBrand=${activeBrand.ID}`,
                    method: "GET",
                    disableError: true,
                    disableRedirect: true,
                  }).then((response) => {
                    if (response.success) {
                      setConnectionRealStats(response.data);
                      loader.apiComplete(2);
                    } else {
                      loader.apiComplete(2);
                    }
                  });
                } else {
                  loader.apiComplete(2);
                }
              });
            }
          });
        }
      });

      axios({
        url: "/getBrand/" + brandID,
        disableRedirect: true,
      }).then((response) => {
        if (response.success) {
          if (!!response.data.logo) {
            setIcon(response.data.logo);
          }
          setBrandDetails({
            data: objectFromBrandResponse(response.data),
            state: false,
          });
          loader.apiComplete();
        }
      });
    } else {
      loader.apiComplete(4);
    }
  }, [
    brandID,
    axios,
    activeBrand.ID,
    userID,
    loader,
    campaignID,
    senderUserID,
  ]);

  return (
    <Loader>
      <Form
        categories={categories}
        location={location}
        languages={languages}
        takeUpOffer={takeUpOffer}
        setTakeUpOffer={setTakeUpOffer}
        audiences={audiences}
        formData={formData}
        brandDetails={brandDetails}
        component={"request"}
        setBrandDetails={setBrandDetails}
        audienceData={audienceData}
        icon={icon}
        brandID={brandID}
        campaignID={campaignID}
        userID={userID}
        activeBrand={activeBrand}
        connectionRealStats={connectionRealStats}
        setConnectionRealStats={setConnectionRealStats}
      />
    </Loader>
  );
}
