import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Visibility } from "@mui/icons-material";
import { Grid, Typography, InputAdornment, IconButton } from "@mui/material";

import { Paper, TextField, Label } from "../../../components";

export default function FormDisplay({ formikProps }) {
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  return (
    <Paper style={{ width: "600px", padding: "50px" }}>
      <Grid item xs={12}>
        <Typography variant="h2">Reset Password</Typography>
      </Grid>

      <Grid item xs={6}>
        <Label title={"New Password"} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type={showPassword ? "text" : "password"}
          name="newPassword"
          inputProps={{ "aria-label": "Without label" }}
          placeholder="New Password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <Visibility />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <Label title={"Confirm Password"} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type={confirmPassword ? "text" : "password"}
          name="confirmPassword"
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Confirm Password"
          formikProps={formikProps}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setConfirmPassword(!confirmPassword)}
                >
                  <Visibility />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <LoadingButton
          type="submit"
          variant="contained"
          fullWidth
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Change Password
        </LoadingButton>
      </Grid>
    </Paper>
  );
}
