import { get } from "lodash";

import { Autocomplete, Checkbox } from "@mui/material";
import { lowerCase } from "change-case-all";
import TextField from "./../../../components/TextField";

export default function AutocompleteMultiSelect({
  label,
  name,
  defaults,
  options,
  formikProps,
  placeholder,
  ...props
}) {
  var initialValue = [];

  for (let val of get(formikProps.values, name, [])) {
    initialValue.push(
      options.filter((option) => option[defaults.primaryKey] === val)[0][
        defaults.displayFirstLabel
      ]
    );
  }

  if (!initialValue) {
    initialValue = [];
  }

  function handleTouch() {
    if (formikProps?.touched.length > 0) {
      for (let field in formikProps.touched) {
        if (field !== props.name) {
          formikProps.setTouched({
            ...formikProps.touched,
            [props.name]: true,
          });
        } else {
          formikProps.setTouched({ [props.name]: true });
        }
      }
    } else {
      formikProps.setTouched({ [props.name]: true });
    }
  }

  return (
    <Autocomplete
      multiple
      limitTags={1}
      onClick={handleTouch}
      fullWidth
      value={initialValue}
      options={options}
      forcePopupIcon={true}
      disableCloseOnSelect
      isOptionEqualToValue={(option, value) =>
        option[defaults.displayFirstLabel] === value
      }
      getOptionLabel={(option) => get(option, defaults.displayFirstLabel)}
      renderTags={(value) =>
        value.map((val) => (value.length === 1 ? val : val + ","))
      }
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ fontSize: "18px", cursor: "pointer" }}>
          <Checkbox
            checked={
              get(formikProps.values, name, "").indexOf(
                option[defaults.primaryKey]
              ) > -1
            }
          />
          {option[defaults.displayFirstLabel] +
            " " +
            option[defaults.displaySecLabel] +
            "x" +
            option[defaults.displayThirdLabel]}
        </li>
      )}
      {...props}
      renderInput={(params) => (
        <TextField
          multiline
          {...params}
          placeholder={
            get(formikProps.values, name, []).length === 0 ? placeholder : ""
          }
          name={name}
          error={
            Boolean(
              get(formikProps, "touched." + name) &&
                get(formikProps, "errors." + name)
            ) || initialValue.length === 0
          }
          helperText={
            get(formikProps, "touched." + name) &&
            get(formikProps, "errors." + name)
          }
          onBlur={get(formikProps, "handleBlur")}
        />
      )}
    />
  );
}
