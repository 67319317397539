import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Grid, Typography } from "@mui/material";

import pages from "../../constants/pages";
import BASE_URL from "../../constants/baseURL";
import Home from "../../assets/navbar icons/Home";
import Logout from "../../assets/navbar icons/Logout";
import { signOutMember } from "../../redux/slices/memberSlice";

const Wrapper = styled.div`
  min-height: 64px;
  border-radius: 0px;
`;

const GridContainer = styled(Grid)`
  height: 100%;
  align-items: center;
  padding-inline: 10px;
  gap: 6px;
`;

const Text = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
`;

export default function Navbar({ sidebarState, toggleSideBar }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeBrand, role, dashboardState, parentState } = useSelector(
    (state) => state.memberDetails
  );

  function handleHomeNavigation() {
    if (role === "SUPER_ADMIN") {
      navigate(pages.accountVerification.route);
    }
    if (role === "NORMAL_USER") {
      if (dashboardState === "monetization") {
        navigate(pages.myCampaign.route);
      }

      if (dashboardState === "promotion") {
        navigate(pages.campaignRequest.route);
      }
    }
  }

  function handleAvatarNavigation() {
    if (parentState === "SA") {
      navigate(pages.superadminLandingPage.route);
    } else {
      navigate(pages.welcomePage.route);
    }
  }

  return (
    <Wrapper>
      <GridContainer container>
        <Grid item xs />

        <Grid
          item
          display="flex"
          paddingRight={"41px"}
          style={{ cursor: "pointer" }}
          onClick={handleHomeNavigation}
        >
          <Home />

          <Text paddingLeft="9px"> Home </Text>
        </Grid>

        {/* <Grid item display="flex" paddingRight={"41px"} cursor="pointer">
          <Notification />

          <Text paddingLeft="9px"> Notification </Text>
        </Grid>

        <Grid item display="flex" paddingRight={"41px"} cursor="pointer">
          <Message />

          <Text paddingLeft="9px"> Message </Text>
        </Grid> */}

        <Grid
          item
          display="flex"
          paddingRight={"41px"}
          style={{ cursor: "pointer" }}
        >
          <Logout onClick={() => dispatch(signOutMember())} />
          <Text paddingLeft="9px" onClick={() => dispatch(signOutMember())}>
            {" "}
            Logout{" "}
          </Text>
        </Grid>

        <Grid item display="flex">
          <Avatar style={{ cursor: "pointer" }} />
          <Avatar
            onClick={() => handleAvatarNavigation()}
            style={{
              position: "relative",
              right: "13%",
              top: "3.5%",
              cursor: "pointer",
            }}
            src={role === "NORMAL_USER" && activeBrand.logo}
          />
        </Grid>
      </GridContainer>
    </Wrapper>
  );
}
