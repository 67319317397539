import queryString from "query-string";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  CancelButton,
  Accept,
  Reject,
  Pending,
} from "../../css components/Button";
import { useAxios, useComponent } from "../../../hooks";
import Dialog from "../../users components/user list components/Dialog";

export default function ActionButton({
  ID = "",
  editState,
  setEditState,
  formikProps,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { alert } = useComponent();

  const [dialogState, setDialogState] = useState({
    state: false,
    data: {},
  });

  const brandID = queryString.parse(window.location.search).brandID;

  const [loading, setLoading] = useState({
    REJECTED: false,
    APPROVE: false,
    PENDING: false,
  });

  function handleCancel() {
    if (editState && !!ID) {
      setEditState(!editState);
      formikProps.handleReset();
    } else {
      navigate(-1);
    }
  }

  return (
    <Fragment>
      <CancelButton
        onClick={handleCancel}
        variant="outlined"
        style={{ marginRight: "10px" }}
      >
        {"Back"}
      </CancelButton>

      {formikProps.values.campaignAdminStatus === 0 && (
        <Reject
          style={{ marginRight: "10px" }}
          size="large"
          variant="contained"
          loading={loading.REJECTED}
          onClick={() =>
            setDialogState({
              state: true,
              data: {
                status: "REJECTED",
              },
            })
          }
        >
          Reject
        </Reject>
      )}

      {formikProps.values.campaignAdminStatus === 1 && (
        <Pending
          style={{ marginRight: "10px" }}
          size="large"
          loading={loading.PENDING}
          variant="contained"
        >
          Pause
        </Pending>
      )}

      {formikProps.values.campaignAdminStatus === 0 && (
        <Accept
          style={{ marginRight: "10px" }}
          size="large"
          variant="contained"
          loading={loading.APPROVE}
          onClick={() => handleCampaignStatus("APPROVE")}
        >
          Active
        </Accept>
      )}

      <Dialog
        dialogState={dialogState}
        dialogContent={{
          title: "Reject Campaign",
          body: "You have selected the option to reject! Please confirm before going further.",
          dangerButton: "Reject",
        }}
        setDialogState={setDialogState}
        handleAgree={handleAgree}
      />
    </Fragment>
  );

  function handleAgree() {
    handleCampaignStatus(dialogState.data?.status);
  }

  function handleCampaignStatus(status) {
    setLoading((prev) => ({
      ...prev,
      [status]: true,
    }));
    axios({
      url: "/user/campaigns/changeAdminCampaignStatus",
      method: "PUT",
      disableRedirect: true,
      data: {
        campaignId: formikProps.values.campaignID,
        status: status,
        senderBrandId: brandID,
      },
    }).then((response) => {
      if (response.success) {
        formikProps.setValues((prev) => ({
          ...prev,
          campaignAdminStatus: 1,
        }));

        dispatch(alert.success(`Campaign ${status} successfully!`));
      }

      setLoading((prev) => ({
        ...prev,
        [status]: true,
      }));
    });
  }
}
