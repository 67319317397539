import { Box, Divider, Typography } from "@mui/material";
import styled from "styled-components/macro";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import Menu from "./sidebar components/Menu";
import Logo from "../../assets/Mox01.svg";
import Shortcuts from "./sidebar components/Shortcuts";
import pages from "../../constants/pages";
import { useSelector } from "react-redux";

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  gap: 10px;
  transition: 0.2s ease;
  overflow: hidden;
`;

const ImageDiv = styled.div`
  width: 65px;
  height: 115px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 39px;
  cursor: pointer;
`;

const Image = styled.img`
  height: 200px;
  margin-top: 63px;
`;

const SuperWrapper = styled.div`
  background-color: #01efc4;
  color: #fff;
  padding: 10px;
  text-align: center;
  height: 100px;
  margin-top: 62px;
`;

export default function Sidebar({ sidebarState, onMouseEnter, onMouseLeave }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const { parentState } = useSelector((state) => state.memberDetails);

  return (
    <Wrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        width: sidebarState.open ? "250px" : "100px",
        background: theme.sidebar.background,
      }}
    >
      <ImageDiv onClick={() => navigate(pages.home.route)}>
        <Image src={Logo} alt="!" />
      </ImageDiv>

      {parentState === "SA" ? (
        <SuperWrapper>
          <div style={{ margin: "14px 0" }}>
            <Typography style={{ fontSize: "15px" }}>SUPER</Typography>
            <Typography style={{ fontWeight: "600", fontSize: "18px" }}>
              ADMIN
            </Typography>
          </div>
        </SuperWrapper>
      ) : (
        <Divider style={{ margin: "0 10px" }} />
      )}

      <Menu sidebarState={sidebarState} />

      <Shortcuts sidebarState={sidebarState} />
    </Wrapper>
  );
}
