/*
Contains all the routes pertaining to Auth Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
}
*/

import pages from "../../constants/pages";

// Sign In Module
import SignUp from "../../pages/auth/SignUp";
import LogIn from "../../pages/auth/LogIn";
import Invitation from "../../pages/invitation components/Invitation";
import ResetChangePassword from "../../pages/reset password components/ChangePassword";
import ResetPassword from "../../pages/reset password components/ResetPassword";

//Member Registration

//Sign Up

const authLayoutRoutes = {
  signInRoute: {
    ID: pages.signIn.ID,
    path: pages.signIn.route,
    element: LogIn,
    role: ["SUPER_ADMIN", "monetization", "promotion"],
  },

  signUpRoute: {
    ID: pages.signUp.ID,
    path: pages.signUp.route,
    element: SignUp,
    role: ["SUPER_ADMIN", "monetization", "promotion"],
  },

  verifyAccountRoute: {
    ID: pages.verifyAccount.ID,
    path: pages.verifyAccount.route + ":code",
    element: Invitation,
    role: ["SUPER_ADMIN", "monetization", "promotion"],
  },

  resetEmailRoute: {
    ID: pages.resetEmailPasswordPage.ID,
    path: pages.resetEmailPasswordPage.route,
    element: ResetPassword,
  },

  forgotPasswordRoute: {
    ID: pages.forgotPasswordPage.ID,
    path: pages.forgotPasswordPage.route + ":code",
    element: ResetChangePassword,
  },
};

export default authLayoutRoutes;
