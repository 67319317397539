import { Grid, Box, Typography as MuiTypography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AiOutlineStop } from "react-icons/ai";
import { PrimaryButton } from "../css components/Button";
import pages from "../../constants/pages";
import { useDispatch } from "react-redux";
import { signOutMember } from "../../redux/slices/memberSlice";

const Wrapper = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled(AiOutlineStop)`
  width: 100px;
  height: 100px;
  color: red;
`;

const Typography = styled(MuiTypography)`
  text-align: center;
`;

export default function PermissionError() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Icon />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h2">Permission Denied</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            You do not have the required permissions to access this page.
          </Typography>

          <Typography variant="h5" gutterBottom>
            Please contact admin for more details!
          </Typography>
        </Grid>

        <Grid item>
          <PrimaryButton
            variant="contained"
            onClick={() => navigate(pages.brandDetails.route)}
          >
            Home
          </PrimaryButton>
        </Grid>

        <Grid item>
          <PrimaryButton
            variant="contained"
            onClick={() => dispatch(signOutMember())}
          >
            Logout
          </PrimaryButton>
        </Grid>
      </Grid>
    </Wrapper>
  );
}
