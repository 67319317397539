import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Add, Edit } from "@mui/icons-material";
import { Typography, Grid, Button, Avatar } from "@mui/material";

const FirstText = styled(Typography)`
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #000000;
`;

const SecondText = styled(Typography)`
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #bdbdbd;
`;

const PageName = styled(Typography)`
  font-weight: 500;
  color: #000000;
  line-height: 45px;
  font-size: 40px;
`;

const Heading = styled(Typography)`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
`;

const SubHeading = styled(Typography)`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
`;

const Image = styled(Avatar)`
  border: 1px solid #999999;
  height: 60px;
  width: 60px;
  background: #cbcbbc;
  font-size: 25px;
`;

export default function PageHeader({
  title = "",
  twoText = false,
  pageName,
  firstText,
  secondText,
  heading,
  subHeading,
  fontSize = "3vw",
  iconImage,
  textAvatar,
  addIcon = [],
  editIcon = [],
  buttons = [],
}) {
  const [buttonTitle, URL, createPermission] = addIcon;
  const [editState, setEditState, updatePermission] = editIcon;

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet
        title={title || pageName || firstText + secondText + SubHeading}
      />

      <Grid container mb="10px" p="20px 0" alignItems="center">
        {!!iconImage && (
          <Grid item mr="17px">
            <Image src={iconImage} />
          </Grid>
        )}

        {!!textAvatar && (
          <Grid item mr="17px">
            <Image>{textAvatar}</Image>
          </Grid>
        )}

        <Grid item xs>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Heading>{heading}</Heading>
              <SubHeading>{subHeading}</SubHeading>

              {!twoText ? (
                <PageName variant="h3" fontSize={fontSize}>
                  {pageName}
                </PageName>
              ) : (
                <div style={{ display: "flex" }}>
                  <FirstText>{firstText} &nbsp;</FirstText>
                  <SecondText>{secondText}</SecondText>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          style={{ gap: "5px" }}
        >
          {buttons.length > 0 &&
            buttons.map((icon, index) => (
              <Grid item key={index}>
                {icon}
              </Grid>
            ))}

          {createPermission && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                onClick={() => navigate(URL)}
              >
                {buttonTitle}
              </Button>
            </Grid>
          )}
        </Grid>

        {!editState && updatePermission && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Edit />}
              onClick={() => setEditState((prevValue) => !prevValue)}
            >
              Edit
            </Button>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
}
