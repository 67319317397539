import { Fragment, useState } from "react";
import { MdVerified } from "react-icons/md";
import styled from "styled-components/macro";
import { Info } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Typography,
  IconButton,
  LinearProgress,
  Button,
} from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";

import { Paper } from "../../../components";
import BASE_URL from "../../../constants/baseURL";
import brandIcon from "../../../assets/brandIcon.png";
import { useAxios, useComponent } from "../../../hooks";
import StatusDialog from "./dialog components/VerifyDialog";
import { HeadingEighteen, colors } from "../../css components/Style";
import {
  brandDetailsObject,
  objectFromBrandResponse,
} from "../../campaign components/brand details components/brandDetailsObject";
import BrandDetailsDialog from "../../campaign components/brand details components/BrandDetailsDialog";
import { toInternationalFormat } from "../../../utils";
import { LoadingButton } from "@mui/lab";

const Subtitle = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.4;
`;

const Link = styled(LoadingButton)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

const OfferStatus = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #999999;
  cursor: pointer;
`;

const Image = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #d6cbcb;
  cursor: pointer;
`;

const ArrowIcon = styled(IoIosArrowForward)`
  position: relative;
  // top: 5px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

export default function List({
  toast,
  setToast,
  campaignID,
  connections,
  setSentChanges,
  connectNowButton,
  campaignCreatedBy,
  setConnectNowButton,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { alert } = useComponent();

  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({ state: false });
  const [brandDetails, setBrandDetails] = useState({
    data: brandDetailsObject,
    state: false,
  });

  const memberDetails = useSelector((state) => state.memberDetails);

  function handleClick(connection) {
    setDetails({
      state: true,
      connection: connection,
    });
  }

  const onImageError = (e) => {
    e.target.src =
      "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png";
  };

  return (
    <Grid container spacing={3}>
      {connections?.map((connection, index) => {
        return (
          <Grid
            item
            xs={12}
            key={connection.brand.brandId}
            sx={{
              filter: !connection.is_relevant_dp && "contrast(.85)",
            }}
          >
            <Paper>
              <Grid item alignSelf="center">
                <Image
                  alt="1"
                  style={{
                    filter: !connection.is_relevant_dp && "grayscale(.7)",
                  }}
                  onClick={() => handleBrandDetails(connection.brand.brandId)}
                  src={
                    connection.brand.logo ||
                    "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png"
                  }
                  onError={onImageError}
                />
              </Grid>

              <Grid item mr="20px" />

              <Grid item xs>
                <Grid container spacing={3}>
                  <Grid item xs>
                    <HeadingEighteen sx={{ color: "rgb(0 0 0)" }}>
                      {connection?.brand?.name}

                      <img
                        alt="!"
                        src={brandIcon}
                        onClick={() =>
                          handleBrandDetails(connection.brand.brandId)
                        }
                        style={{
                          filter: !connection.is_relevant_dp && "grayscale(1)",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                      />

                      {connection.proof?.isApproved &&
                        connection.proof?.is_proof_verified && (
                          <IconButton
                            disableRipple
                            style={{ paddingBottom: "0", paddingTop: "0" }}
                          >
                            <MdVerified color="#34b53a" />
                          </IconButton>
                        )}
                    </HeadingEighteen>
                  </Grid>

                  <Grid item xs />

                  {/* {connection.proof?.isApproved && (
                    <Fragment>
                      {!connection.proof?.is_proof_verified &&
                        (connectNowButton[index].verifying ? (
                          <Grid
                            item
                            style={{ width: "100px" }}
                            alignSelf={"center"}
                            textAlign="end"
                          >
                            <LinearProgress color="primary" />
                            <Typography color="#ff9800">verifying</Typography>
                          </Grid>
                        ) : (
                          <Grid item textAlign="end" alignSelf={"center"}>
                            <Link
                              onClick={() =>
                                handleVerifyProof(
                                  connection.proof?.proofId,
                                  index
                                )
                              }
                            >
                              Verify Proof
                            </Link>
                          </Grid>
                        ))}
                    </Fragment>
                  )} */}

                  {connection.is_relevant_dp && (
                    <Grid
                      item
                      alignSelf={"center"}
                      justifyContent={"end"}
                      display="flex"
                    >
                      <Link
                        style={{
                          color:
                            connection.requestStatus === "REQUESTED"
                              ? "#FFBB0B"
                              : connection.requestStatus === "REJECTED" ||
                                connection.requestStatus ===
                                  "PID_FETCHED_FAILED"
                              ? "#FF3A29"
                              : colors.success,
                        }}
                      >
                        {connection?.requestStatus?.replace(/_/g, " ")}
                      </Link>
                      &nbsp;
                      {!!connection.note && (
                        <Info
                          color="primary"
                          style={{ cursor: "pointer", marginTop: "9px" }}
                          onClick={() => handleClick(connection)}
                        />
                      )}
                    </Grid>
                  )}

                  <Grid item xs={12} />

                  <Grid item xs={2}>
                    <Subtitle>MAU</Subtitle>
                    <HeadingEighteen
                      sx={{
                        color: !connection.is_relevant_dp && "rgb(159 159 159)",
                      }}
                    >
                      {connection?.brand?.mau}
                    </HeadingEighteen>
                  </Grid>

                  <Grid item xs={2}>
                    <Subtitle> Location</Subtitle>
                    <HeadingEighteen
                      sx={{
                        color: !connection.is_relevant_dp && "rgb(159 159 159)",
                        textTransform: "uppercase",
                      }}
                    >
                      {connection?.brand?.locations?.length > 1
                        ? connection?.brand?.locations
                            .map((loc) => loc?.substr(0, 2))
                            .join(", ")
                        : connection?.brand?.locations[0]?.substr(0, 2)}
                    </HeadingEighteen>
                  </Grid>

                  <Grid item xs={2}>
                    <Subtitle> Languages</Subtitle>
                    <HeadingEighteen
                      sx={{
                        color: !connection.is_relevant_dp && "rgb(159 159 159)",
                        textTransform: "uppercase",
                      }}
                    >
                      {connection.brand.languages.length > 1
                        ? connection?.brand?.languages
                            ?.map((lan) => lan?.substr(0, 2))
                            .join(", ")
                        : connection?.brand?.languages[0]?.substr(0, 2)}
                    </HeadingEighteen>
                  </Grid>

                  <Grid item xs={3}>
                    <Subtitle> Category</Subtitle>
                    <HeadingEighteen
                      sx={{
                        color: !connection.is_relevant_dp && "rgb(159 159 159)",
                        textTransform: "uppercase",
                      }}
                    >
                      {connection.brand.category
                        .slice(0, 6)
                        .map((loc) => loc)
                        .join(", ")}
                    </HeadingEighteen>
                  </Grid>

                  <Grid item xs={1}>
                    <Subtitle> User Count</Subtitle>
                    <HeadingEighteen
                      sx={{
                        color: !connection.is_relevant_dp && "rgb(159 159 159)",
                        textTransform: "uppercase",
                      }}
                    >
                      {connection.proof?.usersCount
                        ? toInternationalFormat(connection.proof?.usersCount)
                        : "N.A"}
                    </HeadingEighteen>
                  </Grid>

                  {connection.is_relevant_dp && (
                    <Fragment>
                      {!connection?.isRequested ? (
                        <Grid
                          item
                          xs={2}
                          alignSelf={"center"}
                          display="flex"
                          justifyContent="end"
                          flexDirection="column-reverse"
                          sx={{
                            pointerEvents:
                              connectNowButton[index].connectNow ||
                              (connectNowButton[index].forceConnectNow &&
                                "none"),
                          }}
                        >
                          <Link
                            onClick={() =>
                              handleConnection(
                                connection.user_id,
                                connection.business_entity_id,
                                connection.brand.brandId,
                                index,
                                "sendConnectionRequest"
                              )
                            }
                            sx={{
                              "&:active": { boxShadow: "1px 1px 1px 1px" },
                            }}
                            loading={connectNowButton[index].connectNow}
                          >
                            Connect Now
                            {!connectNowButton[index].connectNow && (
                              <ArrowIcon />
                            )}
                          </Link>

                          {memberDetails.parentState === "SA" && (
                            <Link
                              onClick={() =>
                                handleConnection(
                                  connection.user_id,
                                  connection.business_entity_id,
                                  index,
                                  "forceRequestConnect"
                                )
                              }
                              sx={{
                                marginBottom: "10px",
                                "&:active": { boxShadow: "1px 1px 1px 1px" },
                              }}
                              loading={connectNowButton[index].forceConnectNow}
                            >
                              Force Connect
                              {!connectNowButton[index].forceConnectNow && (
                                <ArrowIcon />
                              )}
                            </Link>
                          )}
                        </Grid>
                      ) : (
                        <Grid
                          item
                          xs={2}
                          alignSelf={"center"}
                          display="flex"
                          justifyContent="end"
                        >
                          <OfferStatus>
                            {connection?.requestStatus === "REJECTED" &&
                              "Offer Rejected"}
                            {connection?.requestStatus === "ACCEPTED" &&
                              "Offer Accepted"}
                            {connection?.requestStatus === "REQUESTED" &&
                              "Offer Requested"}
                          </OfferStatus>
                        </Grid>
                      )}
                    </Fragment>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        );
      })}
      <BrandDetailsDialog details={brandDetails} setDetails={setBrandDetails} />
      <StatusDialog detail={details} setDetail={setDetails} />
    </Grid>
  );

  function handleBrandDetails(brandID) {
    axios({
      url: "/getBrand/" + brandID,
      disableRedirect: true,
    }).then((response) => {
      if (response.success) {
        setBrandDetails({
          data: objectFromBrandResponse(response.data),
          state: true,
          component: "connection",
        });
      }
    });
  }

  function handleVerifyProof(proofID, index) {
    setLoading(true);

    setConnectNowButton((prevStates) =>
      prevStates.map(
        (items, i) =>
          i === index && {
            forceConnectNow: items.forceConnectNow,
            connectNow: items.connectNow,
            verifying: !items.verifying,
          }
      )
    );

    axios({
      url: "/user/verifyProof",
      method: "POST",
      data: {
        proofId: proofID,
      },
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setSentChanges((prevVal) => ({
          ...prevVal,
          verifiedProof: response.data.contract,
        }));
        dispatch(alert.success("Proof Verified successfully!"));
        setLoading(false);
      }
    });
  }

  function handleConnection(
    userId,
    receiverBusinessEntityId,
    receiverBrandId,
    index,
    actionType
  ) {
    setConnectNowButton((prevStates) =>
      prevStates.map(
        (items, i) =>
          i === index && {
            forceConnectNow:
              actionType === "forceRequestConnect"
                ? !items.forceConnectNow
                : items.forceConnectNow,
            connectNow:
              actionType === "sendConnectionRequest"
                ? !items.connectNow
                : items.connectNow,
            verifying: items.verifying,
          }
      )
    );

    axios({
      url: "/brand?businessEntityId=" + receiverBusinessEntityId,
    }).then((response) => {
      if (response.success) {
        axios({
          url: `/user/${actionType}`,
          method: "POST",
          disableRedirect: true,
          data: {
            sender_userId: parseInt(memberDetails.userID),
            sender_brandId: parseInt(memberDetails.activeBrand.ID),
            sender_campaignId: parseInt(campaignID),
            receiver_userId: parseInt(userId),
            receiver_brandId: parseInt(receiverBrandId),
          },
        })
          .then((res) => {
            if (res.success) {
              setSentChanges((prevVal) => ({
                ...prevVal,
                requestSent: response.data[0]?.brand_id,
              }));

              setToast({
                ...toast,
                state: true,
                message: "Connection Request Sent!",
              });
            }

            setConnectNowButton((prevStates) =>
              prevStates.map((items, i) => i === index && !items)
            );
          })
          .catch((error) => {
            setConnectNowButton((prevStates) =>
              prevStates.map((items, i) => (i === index ? false : items))
            );
          });
      }
    });
  }
}
