import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import Form from "./create brand components/Form";

export default function CreateBrand() {
  const { categories, location, languages } = useSelector(
    (state) => state.memberDetails
  );

  return (
    <Fragment>
      <Form categories={categories} location={location} languages={languages} />
    </Fragment>
  );
}
