import { Fragment } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { Grid, Typography } from "@mui/material";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import { PageHeader } from "../../../components";
import { signOutMember } from "../../../redux/slices/memberSlice";
import { PrimaryLoadingButton } from "../../css components/Button";

const Text = styled(Typography)`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
`;

export default function FormDisplay({
  formikProps,
  categories,
  location,
  languages,
  loading,
}) {
  const dispatch = useDispatch();

  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader
          heading={"My Profile"}
          twoText={true}
          firstText={"Welcome. "}
          secondText={" Let's setup your profile."}
          fontSize="25px"
          buttons={[
            <PrimaryLoadingButton
              variant="outlined"
              size="large"
              onClick={() => dispatch(signOutMember())}
            >
              Logout
            </PrimaryLoadingButton>,
            <PrimaryLoadingButton
              variant="contained"
              size="large"
              onClick={formikProps.handleSubmit}
              disabled={!formikProps.isValid}
              loading={loading}
            >
              Save
            </PrimaryLoadingButton>,
          ]}
        />
      </Grid>

      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormOne
            formikProps={formikProps}
            categories={categories}
            location={location}
            languages={languages}
          />
        </Grid>

        <Grid item xs={6}>
          <FormTwo
            formikProps={formikProps}
            categories={categories}
            location={location}
            languages={languages}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
