import { Grid, MenuItem } from "@mui/material";

import { Fragment } from "react";

import { Label, Paper, TextField } from "../../../components";

export default function FormTwo({ formikProps, fields, editState }) {
  return (
    <Paper>
      {fields.map((field, index) => (
        <Fragment key={field.id}>
          <Grid item xs={10}>
            <Label title={field.field_label} />
          </Grid>

          <Grid item xs={10}>
            <TextField
              select={Boolean(field.default_values)}
              name={"fields." + [index] + "." + [field.field_name]}
              placeholder={"Enter  " + field.field_label}
              formikProps={formikProps}
              disabled={!editState}
              inputProps={{ "aria-label": "Without label" }}
            >
              {field.default_values &&
                field.default_values.map((items) => {
                  return (
                    <MenuItem id={items} value={items}>
                      {items}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Grid>
        </Fragment>
      ))}
    </Paper>
  );
}
