import * as Yup from "yup";

export const changePasswordObject = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export function objectFromFormData(formData) {
  return {
    oldPassword: formData.oldPassword,
    newPassword: formData.newPassword,
    confirmPassword: formData.confirmPassword,
  };
}

export const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("This is required field."),
  newPassword: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#@$!."()%:;^+*,?&`~-])[A-Za-z0-9#@$!."()%:;^+*,?&`~-]{8,}$/,
      "Password should be 8 characters long with upper case,lower case, numbers and special characters #@$!.()%:;^+*,?&~-."
    )
    .max(255)
    .required("Please enter password"),
  confirmPassword: Yup.string().when("newPassword", {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Both password need to be the same")
      .required("Please enter confirm password"),
  }),
});
