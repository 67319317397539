export default function Profile({ fill }) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        fill={fill}
        clip-rule="evenodd"
        d="M20.5835 22.6297C20.5835 23.2942 20.0647 23.8334 19.4228 23.8334C18.7809 23.8334 18.2621 23.2942 18.2621 22.6297C18.2621 19.3111 15.6586 16.6112 12.4585 16.6112C9.25841 16.6112 6.65492 19.3111 6.65492 22.6297C6.65492 23.2942 6.13609 23.8334 5.49421 23.8334C4.85234 23.8334 4.3335 23.2942 4.3335 22.6297C4.3335 17.9834 7.9793 14.2038 12.4585 14.2038C16.9377 14.2038 20.5835 17.9834 20.5835 22.6297M12.4585 4.57416C13.7388 4.57416 14.7799 5.65388 14.7799 6.98156C14.7799 8.30925 13.7388 9.38897 12.4585 9.38897C11.1782 9.38897 10.1371 8.30925 10.1371 6.98156C10.1371 5.65388 11.1782 4.57416 12.4585 4.57416M12.4585 11.7964C15.019 11.7964 17.1014 9.63693 17.1014 6.98156C17.1014 4.32619 15.019 2.16675 12.4585 2.16675C9.89796 2.16675 7.81564 4.32619 7.81564 6.98156C7.81564 9.63693 9.89796 11.7964 12.4585 11.7964"
      />
    </svg>
  );
}
