export default function Grid({ fill }) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.25 20.5833V16.25H20.5833L20.5844 20.5833H16.25ZM20.5833 14.0833H16.25C15.0551 14.0833 14.0833 15.0551 14.0833 16.25V20.5833C14.0833 21.7782 15.0551 22.75 16.25 22.75H20.5833C21.7782 22.75 22.75 21.7782 22.75 20.5833V16.25C22.75 15.0551 21.7782 14.0833 20.5833 14.0833ZM5.41667 20.5833V16.25H9.75L9.75108 20.5833H5.41667ZM9.75 14.0833H5.41667C4.22175 14.0833 3.25 15.0551 3.25 16.25V20.5833C3.25 21.7782 4.22175 22.75 5.41667 22.75H9.75C10.9449 22.75 11.9167 21.7782 11.9167 20.5833V16.25C11.9167 15.0551 10.9449 14.0833 9.75 14.0833ZM16.25 9.75V5.41667H20.5833L20.5844 9.75H16.25ZM20.5833 3.25H16.25C15.0551 3.25 14.0833 4.22175 14.0833 5.41667V9.75C14.0833 10.9449 15.0551 11.9167 16.25 11.9167H20.5833C21.7782 11.9167 22.75 10.9449 22.75 9.75V5.41667C22.75 4.22175 21.7782 3.25 20.5833 3.25ZM5.41667 9.75V5.41667H9.75L9.75108 9.75H5.41667ZM9.75 3.25H5.41667C4.22175 3.25 3.25 4.22175 3.25 5.41667V9.75C3.25 10.9449 4.22175 11.9167 5.41667 11.9167H9.75C10.9449 11.9167 11.9167 10.9449 11.9167 9.75V5.41667C11.9167 4.22175 10.9449 3.25 9.75 3.25Z"
      />
    </svg>
  );
}
