import styled from "styled-components";
import { Grid, Typography } from "@mui/material";

import LOGO from "../../assets/Mox-3.svg";

const OuterGrid = styled(Grid)`
  padding-left: 13.19%;
  padding-right: 13.19%;
`;

const ImageDiv = styled.div`
  width: 320px;
  height: 154.83px;
`;

const Image = styled.img`
  height: inherit;
  // width: inherit;
`;

const Font = styled(Typography)`
  font-weight: 700;
  font-size: 20px;
`;

export function Navbar() {
  return (
    <OuterGrid container>
      <Grid item xs style={{ height: "80px" }}>
        <ImageDiv>
          <Image alt="1" src={LOGO} />
        </ImageDiv>
      </Grid>

      {/* <Grid item alignSelf={"center"} fontWeight="bold" marginRight="20px">
        <Font>FAQ</Font>
      </Grid> */}
    </OuterGrid>
  );
}
