import * as React from "react";
import { makeStyles } from "@mui/styles";

import { KeyboardArrowDown } from "@mui/icons-material";
import { Paper, InputBase, IconButton, Grid } from "@mui/material";

const useStyles = makeStyles({
  input: {
    "&::placeholder": {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#000000",
      mixBlendMode: "normal",
      opacity: 1,
    },
  },
});

export default function SortBy() {
  const classes = useStyles();

  return (
    <Grid item xs={3} style={{ paddingLeft: "22px" }} textAlign="end">
      <Paper
        component="form"
        elevation={0}
        sx={{
          p: "15px 18px",
          display: "flex",
          alignItems: "center",
          width: "inherit",
          height: "50px",
          borderRadius: "10px",
          justifyContent: "space-between",
        }}
      >
        <InputBase
          fullWidth
          inputProps={{
            className: classes.input,
          }}
          placeholder="Sort By"
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <KeyboardArrowDown color="rgba(128, 128, 128, 0.7)" />
        </IconButton>
      </Paper>
    </Grid>
  );
}
