import { Avatar } from "@mui/material";

export default function TextAvatar({ userName, size, ...props }) {
  const userInitials =
    (userName.split(" ")?.[0]?.[0] || "") +
    (userName.split(" ")?.[1]?.[0] || "");

  const colorIndex =
    ((userInitials.charCodeAt(0) || 0) + (userInitials.charCodeAt(1) || 0)) % 5;

  return (
    <Avatar
      style={{
        padding: "5px 5px",
        cursor: props.onClick && "pointer",
        height: size?.height || "40px",
        width: size?.width || "40px",
        fontSize: size?.fontSize || "20.5714px",
        lineHeight: size?.lineHeight || "31px",
        fontWeight: size?.fontWeight || "500",
        color: size?.color || "#fff",
        textTransform: "uppercase",
        background: backgroundColors[colorIndex],
      }}
      {...props}
    >
      {userInitials}
    </Avatar>
  );
}

const backgroundColors = [
  "#FFE5D3",
  "#FFF5CC",
  "#5c6bc0",
  "#CCF8FE",
  "#DAD7FE",
];
