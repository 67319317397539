import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { DialogContent, Dialog, Grid } from "@mui/material";

import { useComponent } from "../../../hooks";
import { ActiveTextEighteen } from "../../css components/Style";
import {
  CancelButton,
  PrimaryLoadingButton,
} from "../../css components/Button";

const Card = styled(Dialog)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default function ConfirmDialog({
  confirm,
  setConfirm,
  formikProps,
  handleClose,
  dialogFormikProps,
}) {
  const dispatch = useDispatch();

  const { axios, alert } = useComponent();

  const [loading, setLoading] = useState(false);

  const handleConfirmDialogClose = () => {
    setConfirm(false);
  };

  function handleDeactivateCreative() {
    setLoading(true);

    axios({
      url:
        "/user/brand/campaign/creatives?creativeId=" +
        dialogFormikProps.values.creativeID,
      method: "PATCH",
      disableRedirect: true,
    }).then((response) => {
      if (response.success) {
        setLoading(false);

        dispatch(alert.success("Creative deactivated successfully."));
        formikProps.setValues((prev) => ({
          ...prev,
          creatives: setCreatives(prev.creatives),
        }));

        function setCreatives(creatives) {
          let arr = creatives.filter(
            (creative) =>
              creative.creativeID !== dialogFormikProps.values.creativeID
          );
          return arr;
        }

        handleConfirmDialogClose();

        handleClose();
      }
    });
  }

  return (
    <Card
      open={confirm}
      onClose={handleConfirmDialogClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "800px!important",
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={3} style={{ padding: "20px" }}>
          <Grid item xs={12} display="flex">
            <ActiveTextEighteen>
              Do you want to Deactivate this Creative?
            </ActiveTextEighteen>
          </Grid>

          <Grid item xs />

          <Grid item>
            <CancelButton variant="outlined" onClick={handleConfirmDialogClose}>
              Cancel
            </CancelButton>
          </Grid>

          <Grid item>
            <PrimaryLoadingButton
              variant="contained"
              onClick={handleDeactivateCreative}
              loading={loading}
            >
              Confirm
            </PrimaryLoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Card>
  );
}
