import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";

import { Paper, TextField, Label } from "../../../components";
import {
  CancelButton,
  PrimaryLoadingButton,
} from "../../css components/Button";
import pages from "../../../constants/pages";

export default function FormDisplay({ formikProps }) {
  const navigate = useNavigate();

  return (
    <Paper style={{ width: "600px", padding: "50px" }}>
      <Grid item xs={12}>
        <Typography variant="h2">Reset Password</Typography>
      </Grid>

      <Grid item xs={6}>
        <Label title={"Email"} />
      </Grid>

      <Grid item xs={12} marginBottom={"15px"}>
        <TextField
          required
          name="email"
          formikProps={formikProps}
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter registered email"
        />
      </Grid>

      <Grid item xs />
      <Grid item>
        <CancelButton
          variant="outlined"
          onClick={() => navigate(pages.signIn.route)}
        >
          Back
        </CancelButton>
      </Grid>

      <Grid item display={"flex"} justifyContent={"end"} alignItems={"center"}>
        <PrimaryLoadingButton
          type="submit"
          variant="contained"
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Reset
        </PrimaryLoadingButton>
      </Grid>
    </Paper>
  );
}
