import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";

import Form from "./email form component/Form";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function ResetPassword() {
  return (
    <Wrapper>
      <Helmet title="Change Password" />
      <Form />
    </Wrapper>
  );
}
