import axios from "axios";
import { useRef, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import BASE_URL from "../constants/baseURL";
import { alert } from "../redux/slices/alertSlice";
import {
  apiError,
  internetDown,
  timeOutError,
} from "../redux/slices/loaderSlice";
import { deleteAccessToken } from "../redux/slices/memberSlice";

export default function useAxios({
  baseURL = "mox_api",
  customBaseURL = "",
  customAuthToken = "",
  disableAuthToken = false,
} = {}) {
  const dispatch = useDispatch();
  const { authToken } = useSelector((state) => state.memberDetails);

  const axiosInstance = useRef(axios.create());

  useEffect(() => {
    axiosInstance.current = axios.create({
      headers: {
        ...(!disableAuthToken && {
          Authorization: customAuthToken || authToken,
        }),
        "content-type": "application/json",
      },

      ...(Boolean(customBaseURL)
        ? { baseURL: customBaseURL }
        : {
            baseURL: BASE_URL[baseURL].url + BASE_URL[baseURL].version,
          }),

      validateStatus: (status) => status < 500,
    });
  }, [authToken, baseURL, customAuthToken, customBaseURL, disableAuthToken]);

  return useCallback(
    ({
      url,
      method = "GET",
      data,
      params,
      disableRedirect = false,
      disableError = false,
      timeout = 60000,
      disableTimeOut = false,
      disableSession = false,
    }) =>
      axiosInstance
        .current({
          url: url,
          method: method,
          data: data,
          params: params,
          ...(Boolean(disableTimeOut) && { timeout: timeout }),
        })
        .then((response) => {
          let session_id =
            !disableSession && response?.headers?.session_id
              ? "(" + response?.headers?.session_id + ")"
              : "";

          if (response.status && response.statusText === "No Content") {
            dispatch(
              alert({
                message: "Password changed. Please login again",
                type: "success",
              })
            );
            return dispatch(deleteAccessToken());
          }

          if (response.status === 401) {
            dispatch(
              alert({
                message: response.data?.error,
                type: "error",
              })
            );

            dispatch(deleteAccessToken());

            return {
              status: false,
              message:
                response.data?.error + " " + session_id ||
                response.data?.message + " " + session_id ||
                "Error loading data! " + session_id,
            };
          }

          if (response.status > 201 || !response.data.success) {
            if (!disableError) {
              dispatch(
                alert({
                  message:
                    response.data?.error + " " + session_id ||
                    response.data?.message + " " + session_id ||
                    "Error loading data! " + session_id,
                  type: "error",
                })
              );
            }

            if (!disableRedirect) {
              dispatch(apiError(session_id));

              throw new Error();
            } else {
              return {
                status: false,
                data: response?.data?.data !== null ? response.data.data : null,
                message:
                  response.data?.error + " " + session_id ||
                  response.data?.message + " " + session_id ||
                  "Error loading data! " + session_id,
              };
            }
          }

          return response.data;
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            dispatch(internetDown(error.code));
            return { status: false };
          }

          if (!disableTimeOut && error.code === "ECONNABORTED") {
            dispatch(timeOutError(error.code));
          }
        }),
    [dispatch]
  );
}
