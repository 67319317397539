import { CssBaseline } from "@mui/material";
import styled from "styled-components/macro";
import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import PerfectScrollBar from "react-perfect-scrollbar";

import GlobalStyle from "../components/GlobalStyle";

const ScrollBar = styled(PerfectScrollBar)`
  margin: 4px;
  height: calc(100% - 8px) !important;
  display: flex;

  > * {
    flex: 1;
  }
`;

export default function BlankLayout() {
  return (
    <Fragment>
      <CssBaseline />
      <GlobalStyle />

      <ScrollBar>
        <Outlet />
      </ScrollBar>
    </Fragment>
  );
}
