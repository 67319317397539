import { CircularProgress, CssBaseline } from "@mui/material";
import styled from "styled-components/macro";
import { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import PerfectScrollBar from "react-perfect-scrollbar";

import pages from "../constants/pages";
import GlobalStyle from "../components/GlobalStyle";
import { signInMember } from "../redux/slices/memberSlice";

const ScrollBar = styled(PerfectScrollBar)`
  margin: 0px 4px 4px 4px;
  height: calc(100% - 8px) !important;
  display: flex;

  > * {
    flex: 1;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export default function EmptyLayout() {
  const dispatch = useDispatch();

  const { authToken } = useSelector((state) => state.memberDetails);

  const userID = parseInt(sessionStorage.getItem("userID"));
  const sessionStorageToken = sessionStorage.getItem("authToken");
  const businessEntityId = sessionStorage.getItem("businessEntityId");

  useEffect(() => {
    if (!authToken && sessionStorageToken) {
      dispatch(
        signInMember({
          authToken: sessionStorageToken,
          userID: userID,
          businessEntityId: businessEntityId,
        })
      );
    }
  }, [authToken, sessionStorageToken, userID, businessEntityId, dispatch]);

  if (!authToken && sessionStorageToken) {
    return (
      <Fragment>
        <CssBaseline />
        <GlobalStyle />

        <Wrapper style={{ height: "100%" }}>
          <CircularProgress sx={{ color: "#02A0FC" }} />
        </Wrapper>
      </Fragment>
    );
  }

  if (!(authToken || sessionStorageToken)) {
    return <Navigate to={`${pages.signIn.route}`} />;
  }

  return (
    <Fragment>
      <CssBaseline />
      <GlobalStyle />

      <ScrollBar>
        <Outlet />
      </ScrollBar>
    </Fragment>
  );
}
