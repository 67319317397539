export default function convertDateTimeUTC(UTCtime) {
  let date = new Date(UTCtime);

  var year = date.getFullYear();
  var month = date.getMonth() + 1;

  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

  var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();

  var min =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();

  var time = day + "." + month + "." + year + " | " + hour + ":" + min;

  return time;
}
