import * as Yup from "yup";

export const userDetailsObject = {
  userID: "",
  givenName: "",
  familyName: "",
  email: "",
  password: "",
  role: "",
  brandID: "",
  profile: "",
  brandName: "",
};

export function objectFromFormData(formData) {
  return {
    familyName: formData.family_name,
    givenName: formData.given_name,
    email: formData.email,
    role: formData.role,
    password: formData.password,
    brand: formData.brand,
  };
}

export function objectFromResponse(response) {
  return {
    familyName: response.data.user.family_name,
    givenName: response.data.user.given_name,
    email: response.data.user.email,
    role: response.data.user.role_name,
    password: response.password,
    userID: response.data.user.user_id,
    brandName: response.data.brand.brand_name,
    brandID: response.data.brand.brand_id,
    accountStatus: response.data.user.account_status,
  };
}

export const validationSchema = Yup.object().shape({});
