import React, { useState } from "react";
import {
  Grid,
  Typography,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { ActiveTextEighteen, colors } from "../../../css components/Style";
import {
  CancelButton,
  PrimaryLoadingButton,
} from "../../../css components/Button";
import useAxios from "../../../../hooks/useAxios";
import { useDispatch } from "react-redux";
import { alert } from "../../../../redux/slices/alertSlice";

export default function UnverifiedDialog({ detail, setDetail, formikProps }) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  function handleVerification() {
    setLoading(true);
    axios({
      url: "/send/verification/email",
      method: "POST",
      data: {
        email: formikProps.values.email,
      },
    }).then((response) => {
      if (response.success) {
        dispatch(alert({ type: "success", message: response.data }));
        setLoading(false);
        handleClose();
      }
    });
  }

  const handleClose = () => {
    setDetail(false);
  };

  return (
    <React.Fragment>
      <MUIDialog open={detail} onClose={handleClose}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} textAlign={"center"}>
              <MarkEmailReadIcon
                style={{ color: colors.info, height: "40px", width: "40px" }}
              />
            </Grid>

            <Grid item xs={12} textAlign={"center"}>
              <ActiveTextEighteen color="#333333ba">
                Email Verification Pending
              </ActiveTextEighteen>
            </Grid>

            <Grid item xs={12} textAlign={"center"} marginTop="10px">
              <Typography>
                We have sent an email for Verification. Follow the instructions
                in email for logging into your account.{" "}
              </Typography>
            </Grid>

            <Grid item xs={12} textAlign={"center"} marginTop="18px">
              <PrimaryLoadingButton
                loading={loading}
                onClick={handleVerification}
                variant="contained"
              >
                Send Email Again
              </PrimaryLoadingButton>
            </Grid>

            <Grid item xs={12} textAlign={"center"} marginTop="10px">
              <Typography
                onClick={() => {
                  formikProps.handleReset();
                  handleClose();
                }}
                style={{
                  cursor: "pointer",
                  fontSize: "15px",
                  color: colors.info,
                }}
              >
                Sign up with different email
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ padding: "0px 10px 16px" }}>
          <CancelButton onClick={handleClose} variant="outlined">
            Cancel
          </CancelButton>
        </DialogActions>
      </MUIDialog>
    </React.Fragment>
  );
}
