import axios from "axios";
import { useDispatch } from "react-redux";
import { useRef, useEffect, useCallback } from "react";

import BASE_URL from "../constants/baseURL";
import { alert } from "../redux/slices/alertSlice";

export default function useAxiosVerismart({
  baseURL = "mox_api",
  customBaseURL = "",
  customAuthToken = "",
  disableAuthToken = false,
} = {}) {
  var authToken = "";
  const dispatch = useDispatch();

  const axiosInstance = useRef(axios.create());

  useEffect(() => {
    axiosInstance.current = axios.create({
      headers: {
        ...(!disableAuthToken && {
          Authorization: "Bearer " + (customAuthToken || authToken),
        }),
        "content-type": "application/json",
      },

      ...(Boolean(customBaseURL)
        ? { baseURL: customBaseURL }
        : {
            baseURL: BASE_URL[baseURL].url + BASE_URL[baseURL].version,
          }),

      validateStatus: (status) => status < 500,
    });
  }, [authToken, baseURL, customAuthToken, customBaseURL, disableAuthToken]);

  return useCallback(
    ({
      url,
      method = "GET",
      data,
      params,
      customAuthToken,
      disableRedirect = false,
      disableError = false,
    }) =>
      axiosInstance
        .current({
          url: url,
          method: method,
          data: data,
          params: params,
          headers: {
            ...(!disableAuthToken && {
              Authorization: customAuthToken || authToken,
            }),
          },
        })
        .then((response) => {
          if (!response.data.success) {
            if (!disableError) {
              dispatch(
                alert({
                  message:
                    response.data?.message?.displayMessage ||
                    "Error loading data!",
                  type: "error",
                })
              );
            }
          }

          return response.data;
        }),
    [dispatch]
  );
}
