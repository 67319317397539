import { Fragment } from "react";
import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";

import { Paper } from "../../../components";
import { TextFifteenYellow, TextDelete } from "../../css components/Style";

const DeleteContent = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: #000000;
`;

export default function Status({ action, accountStatus, formikProps }) {
  return (
    <Fragment>
      {!action.delete && (
        <Paper>
          <Grid item xs={8}>
            <DeleteContent>
              User will be deleted with immediate effect.
              <span style={{ color: "#02A0FC" }}>Please confirm.</span>
            </DeleteContent>
          </Grid>

          <Grid item xs={4} justifyContent={"end"} display={"flex"}>
            <svg
              style={{ position: "relative", margin: "9px 9px" }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="5" cy="5" r="5" fill={"#FF0000"} />
            </svg>
            <TextDelete>Delete</TextDelete>
          </Grid>
        </Paper>
      )}

      {!action.suspend && (
        <Paper>
          <Grid item xs={8}>
            <DeleteContent>
              User will be Suspend with immediate effect.{" "}
              <span style={{ color: "#02A0FC" }}>Please confirm.</span>
            </DeleteContent>
          </Grid>

          <Grid item xs={4} justifyContent={"end"} display={"flex"}>
            <svg
              style={{ position: "relative", margin: "9px 9px" }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="5" cy="5" r="5" fill={"#FFBB0B"} />
            </svg>
            <TextFifteenYellow color={"#FFBB0B"}>Suspend</TextFifteenYellow>
          </Grid>
        </Paper>
      )}

      {accountStatus && formikProps.values.accountStatus === 4 && (
        <Paper>
          <Grid item xs={8} style={{ gap: "5px" }}>
            <DeleteContent>User was suspended.</DeleteContent>
          </Grid>

          <Grid item xs={4} justifyContent={"end"} display={"flex"}>
            <svg
              style={{ position: "relative", margin: "9px 9px" }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="5" cy="5" r="5" fill={"#FFBB0B"} />
            </svg>
            <TextFifteenYellow>Suspended</TextFifteenYellow>
          </Grid>
        </Paper>
      )}

      {accountStatus && formikProps.values.accountStatus === 5 && (
        <Paper>
          <Grid item xs={8} style={{ gap: "5px" }}>
            <DeleteContent>User was deleted</DeleteContent>
          </Grid>

          <Grid item xs={4} justifyContent={"end"} display={"flex"}>
            <svg
              style={{ position: "relative", margin: "9px 9px" }}
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="5" cy="5" r="5" fill={"#FF0000"} />
            </svg>
            <TextDelete>Deleted</TextDelete>
          </Grid>
        </Paper>
      )}
    </Fragment>
  );
}
