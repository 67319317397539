import axios from "axios";
import BASE_URL from "../../../constants/baseURL";

export default async function signOut(_, { getState }) {
  const { memberDetails } = getState();

  await axios({
    baseURL: BASE_URL.mox_api.url + BASE_URL.mox_api.version,
    url: "/user/logout",
    method: "GET",
    headers: {
      Authorization: memberDetails.authToken,
      "Content-Type": "application/json",
    },
  });
}
