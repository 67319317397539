import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { Typography, Grid, Button } from "@mui/material";

import { TextField } from "../../../components";
import { useAxios, useComponent } from "../../../hooks";
import { colors } from "../../css components/Style";
import { Accept, Pending, Reject } from "../../css components/Button";

const MainHeading = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 42px;
`;

const SubHeading = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 30px;
  color: #000000;
`;

const CancelButton = styled(Button)`
  border: 1px solid #bdbdbd;
  border-radius: 30px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #bdbdbd;
  padding: 10px 16px;
  margin-right: 10px;
  height: 50px;
  &:hover {
    border: 1px solid #bdbdbd;
  }
`;

export default function FormDisplay({
  handleClose,
  formikProps,
  takeUp,
  setTakeUp,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { alert } = useComponent();

  const [loading, setLoading] = useState(false);

  return (
    <React.Fragment>
      <Grid
        item
        xs={12}
        style={{
          borderColor:
            takeUp.action === "REJECTED"
              ? colors.error
              : takeUp.action === "PENDING"
              ? colors.warning
              : colors.success,
          borderBottom: "1px solid #333",
        }}
      />
      <Grid item xs={12}>
        <MainHeading
          color={
            takeUp.action === "REJECTED"
              ? colors.error
              : takeUp.action === "PENDING"
              ? colors.warning
              : colors.success
          }
        >{`We would like to take action on the offer.`}</MainHeading>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          borderColor:
            takeUp.action === "REJECTED"
              ? colors.error
              : takeUp.action === "PENDING"
              ? colors.warning
              : colors.success,
          borderBottom: "1px solid #333",
        }}
      />

      <Grid item xs={12}>
        <SubHeading>Send a Message to Monetization Partner</SubHeading>
      </Grid>

      <Grid item xs={12}>
        <TextField
          rows={5}
          multiline
          name="dp_note"
          value={takeUp.data.dp_note}
          onChange={handleChange}
        />
      </Grid>

      <Grid item>
        <CancelButton
          style={{}}
          variant="outlined"
          onClick={() => handleDPNote("Contact Us in")}
        >
          Contact Us in
        </CancelButton>
      </Grid>

      <Grid item>
        <CancelButton
          variant="outlined"
          onClick={() => handleDPNote("Tell us more")}
        >
          Tell us more
        </CancelButton>
      </Grid>

      <Grid item>
        <CancelButton
          variant="outlined"
          onClick={() => handleDPNote("Let's Talk")}
        >
          Let's Talk
        </CancelButton>
      </Grid>

      <Grid item xs={12} />

      <Grid item xs />

      <Grid item>
        <CancelButton variant="outlined" onClick={handleClose}>
          Cancel
        </CancelButton>
      </Grid>

      <Grid item>
        {takeUp.action === "REJECTED" ? (
          <Reject
            type="submit"
            variant="contained"
            onClick={handleTakeUpOffer}
            loading={loading}
          >
            Reject
          </Reject>
        ) : takeUp.action === "PENDING" ? (
          <Pending
            type="submit"
            variant="contained"
            onClick={handleTakeUpOffer}
            loading={loading}
          >
            Pending
          </Pending>
        ) : (
          <Accept
            type="submit"
            variant="contained"
            onClick={handleTakeUpOffer}
            loading={loading}
          >
            Accept
          </Accept>
        )}
      </Grid>
    </React.Fragment>
  );

  function handleChange(e) {
    setTakeUp((prev) => ({
      ...prev,
      data: setNote(prev.data),
    }));

    function setNote(val) {
      val.dp_note = e.target.value;
      return val;
    }
  }

  function handleDPNote(text) {
    setTakeUp((prev) => ({
      ...prev,
      data: setNote(prev.data),
    }));

    function setNote(val) {
      val.dp_note = text;
      return val;
    }
  }

  function handleTakeUpOffer() {
    setLoading(true);
    axios({
      url: "/user/takeUpOffer?v=1&requestStatus=" + takeUp.action,
      method: "POST",
      data: takeUp.data,
    }).then((res) => {
      handleClose();
      if (res.success) {
        setLoading(false);
        formikProps.setValues((prev) => ({
          ...prev,
          campaignStatus: takeUp.action,
        }));
        dispatch(alert.success(res.message));
      }
    });
  }
}
