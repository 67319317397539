import { Navigate } from "react-router-dom";
import styled from "styled-components/macro";
import { useTheme } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import { CssBaseline, useMediaQuery } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";

import pages from "../constants/pages";
import GlobalStyle from "../components/GlobalStyle";
import Navbar from "./dashboard layout components/Navbar";
import { signInMember } from "../redux/slices/memberSlice";
import Sidebar from "./dashboard layout components/Sidebar";
import MainContent from "./dashboard layout components/MainContent";
import WebsiteLoader from "./dashboard layout components/WebsiteLoader";

const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const MainContentWrapper = styled.div`
  flex: 1;
  margin: 4px;
  overflow: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

export default function DashboardLayout() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [sidebarState, setSidebarState] = useState({
    static: true,
    open: false,
  });
  const { authToken, dashboardState, parentState } = useSelector(
    (state) => state.memberDetails
  );
  const isLaptop = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });

  const userID = parseInt(sessionStorage.getItem("userID"));
  const sessionStorageToken = sessionStorage.getItem("authToken");
  const businessEntityId = sessionStorage.getItem("businessEntityId");

  const activeBrand = sessionStorage.getItem("activeBrand");
  const sessionDashboardStage = localStorage.getItem(
    "dashboardState" + activeBrand
  );

  useEffect(() => {
    if (!authToken && sessionStorageToken) {
      dispatch(
        signInMember({
          authToken: sessionStorageToken,
          userID: userID,
          businessEntityId: businessEntityId,
        })
      );
    }
  }, [authToken, sessionStorageToken, userID, businessEntityId, dispatch]);

  if (!(authToken || sessionStorageToken)) {
    return <Navigate to={`${pages.signIn.route}`} />;
  }

  if (!authToken && sessionStorageToken) {
    return (
      <Fragment>
        <CssBaseline />
        <GlobalStyle />

        <WebsiteLoader />
      </Fragment>
    );
  }

  if (
    parentState !== "SA" &&
    (!dashboardState ||
      dashboardState === null ||
      dashboardState === undefined) &&
    !sessionDashboardStage
  ) {
    return <Navigate to={`${pages.signIn.route}`} />;
  }

  return (
    <Fragment>
      <CssBaseline />
      <GlobalStyle />

      <ContentWrapper>
        <Sidebar
          sidebarState={sidebarState}
          onMouseEnter={mouseEnterSidebar}
          onMouseLeave={mouseLeaveSidebar}
        />

        <MainContentWrapper>
          <Navbar
            sidebarState={sidebarState}
            toggleSideBar={toggleSidebarState}
          />

          <MainContent />
        </MainContentWrapper>
      </ContentWrapper>
    </Fragment>
  );

  function toggleSidebarState() {
    setSidebarState((prevValue) => ({
      static: !prevValue.static,
      open: !prevValue.open,
    }));
  }

  function mouseEnterSidebar() {
    if (isLaptop && !sidebarState.static) {
      setSidebarState((prevValue) => ({
        ...prevValue,
        open: false,
      }));
    }
  }

  function mouseLeaveSidebar() {
    if (isLaptop && !sidebarState.static) {
      setSidebarState((prevValue) => ({
        ...prevValue,
        open: false,
      }));
    }
  }
}
