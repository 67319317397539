import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Loader } from "../../components";
import Form from "./create audience components/Form";
import {
  audienceObject,
  objectFormedForFormik,
  objectFromResponse,
} from "./create audience components/audienceObjects";
import { useAxios, useComponent } from "../../hooks";

export default function AudienceDetails() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const audienceID = params.audienceID || "";
  const [fields, setFields] = useState([]);
  const [formData, setFormData] = useState(audienceObject);
  const [editState, setEditState] = useState(true);
  const { categories, location, languages } = useSelector(
    (state) => state.memberDetails
  );
  const [percentageSliderValue, setPercentageSliderValue] = useState(-1);

  useEffect(() => {
    loader.start(2);
    axios({
      url: "/brand/getAllDynamicFields",
      method: "GET",
      disableRedirect: true,
    })
      .then((response) => {
        if (response.success) {
          var fields = response.data.fieldsData;
          setFields(response.data.fieldsData);

          if (!!audienceID) {
            setEditState(false);

            axios({
              url: "/brand/getDetailsOfAudience/" + audienceID,
              method: "GET",
            }).then((responses) => {
              if (responses.success) {
                let data = objectFromResponse(responses.data, fields);
                setFormData(data);
                responses.data.fields?.filter(
                  (items, index) =>
                    items.hasOwnProperty("percentage") &&
                    setPercentageSliderValue(items["percentage"])
                );
                loader.apiComplete();
                loader.apiComplete();
              }
            });
          } else {
            getFields();

            async function getFields() {
              for (let field of fields) {
                if (field.control_id === 2 && field.multi_select) {
                  setFormData((formData) => ({
                    ...formData,
                    [field.name]: {
                      value: [],
                      mandatory: false,
                      userCount: 0,
                    },
                  }));
                }

                if (field.control_id === 1) {
                  setFormData((formData) => ({
                    ...formData,
                    [field.name]: {
                      value: { min: "", max: "" },
                      mandatory: false,
                      userCount: 0,
                    },
                  }));
                }

                if (
                  (field.control_id === 2 || field.control_id === 3) &&
                  !field.multi_select
                ) {
                  setFormData((formData) => ({
                    ...formData,
                    [field.name]: {
                      value: "",
                      mandatory: false,
                      userCount: 0,
                    },
                  }));
                }

                if (field.control_id === 4) {
                  setFormData((formData) => ({
                    ...formData,
                    [field.name]: {
                      value: null,
                      mandatory: false,
                      userCount: 0,
                    },
                  }));
                }
              }
              loader.apiComplete(2);
            }
          }
        }
      })
      .catch((err) => console.error(err));
  }, [axios, loader]);

  // useEffect(() => {
  //   loader.start(2);

  //   // axios({
  //   //   url: "/brand/getAudienceFieldsInfo/",
  //   //   disableRedirect: true,
  //   // }).then((response) => {
  //   //   if (response.success) {
  //   //     for (let field of response.data) {
  //   //       setFormData((prev) => ({
  //   //         ...prev,
  //   //         [field.field_name]: setFieldValue(prev[field.field_name]),
  //   //       }));

  //   //       function setFieldValue(fields) {
  //   //         if (fields !== undefined) {
  //   //           fields.dpCount = field.dp_count;
  //   //           return fields;
  //   //         }
  //   //       }
  //   //     }

  //   //     loader.apiComplete(1);
  //   //   } else {
  //   //     loader.apiComplete(1);
  //   //   }
  //   // });

  //   if (!!audienceID) {
  //     setEditState(false);

  //     axios({
  //       url: "/brand/getDetailsOfAudience/" + audienceID,
  //     }).then((response) => {
  //       if (response.success) {
  //         setFormData(objectFromResponse(response.data));

  //         response.data.fields.filter((items, index) => {
  //           if (items.hasOwnProperty("percentage")) {
  //             setPercentageSliderValue(items["percentage"]);
  //           }
  //         });
  //       }
  //       loader.apiComplete(1);
  //     });
  //   } else {
  //     setFormData(objectFormedForFormik());
  //     loader.stop();
  //   }
  // }, [axios, loader, audienceID]);

  return (
    <Loader>
      <Form
        fields={fields}
        formData={formData}
        editState={editState}
        setEditState={setEditState}
        categories={categories}
        location={location}
        languages={languages}
        percentageSliderValue={percentageSliderValue}
        setPercentageSliderValue={setPercentageSliderValue}
      />
    </Loader>
  );
}
