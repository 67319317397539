import styled from "styled-components";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, MenuItem, Typography, Avatar } from "@mui/material";

import pages from "../../constants/pages";
import { TextField, Autocomplete } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { signInMember } from "../../redux/slices/memberSlice";
import { CancelButton, PrimaryLoadingButton } from "../css components/Button";
import { colors } from "../css components/Style";

const WelcomeTypography = styled(Typography)`
  font-weight: 500;
  font-size: 2vw;
  color: rgba(0, 0, 0, 1);
`;

const BrandTypography = styled(Typography)`
  font-weight: 500;
  font-size: 2vw;
  color: rgba(189, 189, 189, 1);
`;

const Image = styled(Avatar)`
  width: 120px;
  height: 120px;
  cursor: pointer;
  border: 1px solid;
`;

export default function AdminForm({ brands, authToken, userID }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dashboardState, activeBrand } = useSelector(
    (state) => state.memberDetails
  );

  const [currentBrand, setCurrentBrand] = useState({
    ID: activeBrand.ID,
    name: activeBrand.brandName,
    type: activeBrand.brandType,
    businessEntityID: activeBrand.bEntityID,
    admin: !activeBrand.ID && true,
  });
  const [userDashboardState, setUserDashboardState] = useState(
    dashboardState || "monetization"
  );
  const formikProps = {
    name: "brandID",
    values: {
      brandID: currentBrand.ID,
    },
    errors: {},
    touched: {},
  };

  const sortedBrands = brands.slice().sort((a, b) => {
    if (a.brandName.toLowerCase() === b.brandName.toLowerCase()) {
      return a.brandName > b.brandName ? 1 : -1;
    }
    return a.brandName > b.brandName ? 1 : -1;
  });

  return (
    <Fragment>
      <Grid item xs={8} mt="10px">
        <WelcomeTypography>You’re Logged In.</WelcomeTypography>

        <BrandTypography>Select Your Channel</BrandTypography>
      </Grid>
      <Grid item xs={12} textAlign={"center"}>
        <Image
          onClick={() => {
            setCurrentBrand({
              ID: "",
              name: "",
              type: "",
              admin: !currentBrand.admin,
            });
            setUserDashboardState("");
          }}
          style={{
            borderColor: currentBrand.admin ? colors.info : "#cbcbcb",
          }}
        >
          Admin
        </Image>
      </Grid>

      <Grid item xs={12}>
        <WelcomeTypography>OR login as</WelcomeTypography>
      </Grid>

      <Grid item xs={6} textAlign="left" marginTop={"5px"}>
        <Autocomplete
          name="brandID"
          placeholder="Select Brand Name"
          options={sortedBrands}
          defaults={{
            primaryKey: "ID",
            displayLabel: "brandName",
          }}
          disabled={currentBrand.admin}
          formikProps={formikProps}
          onChange={(_, value, type) => handleChangeBrand(value, type)}
        />
      </Grid>

      <Grid item xs={6} textAlign="left" marginTop={"5px"}>
        <TextField
          select
          placeholder="Select Role"
          inputProps={{ "aria-label": "Without label" }}
          name="role"
          value={userDashboardState}
          onChange={setDashboardUser}
          disabled={currentBrand.type !== 3}
        >
          <MenuItem value={`promotion`}>I am a Promotion Partner</MenuItem>
          <MenuItem value={`monetization`}>
            I am a Monetization Partner
          </MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} />

      <Grid item xs />

      <Grid item style={{ paddingTop: "0px" }}>
        <CancelButton
          onClick={() => {
            navigate(pages.signIn.route);
          }}
          variant="outlined"
        >
          Cancel
        </CancelButton>
      </Grid>

      <Grid item style={{ paddingTop: "0px" }}>
        <PrimaryLoadingButton
          type="submit"
          variant="contained"
          disabled={!currentBrand.ID && !currentBrand.admin}
          onClick={async () => {
            sessionStorage.setItem("ps", "SA");
            if (currentBrand.admin) {
              sessionStorage.removeItem("");
              sessionStorage.setItem("role", "SUPER_ADMIN");
              await dispatch(
                signInMember({
                  authToken: authToken,
                  userID: userID,
                  businessEntityId: currentBrand.businessEntityID,
                })
              );
              navigate(pages.accountVerification.route);
            } else {
              sessionStorage.setItem("role", "NORMAL_USER");
              localStorage.setItem(
                "dashboardState" + currentBrand.ID,
                userDashboardState
              );

              await dispatch(
                signInMember({
                  authToken: authToken,
                  userID: userID,
                  businessEntityId: currentBrand.businessEntityID,
                })
              );
              navigate(pages.brandDetails.route);
            }
          }}
        >
          Let's Go
        </PrimaryLoadingButton>
      </Grid>
    </Fragment>
  );

  function handleChangeBrand(value, type) {
    if (type === "selectOption") {
      setCurrentBrand({
        ID: value.ID,
        name: value.brandName,
        type: value.brandType,
        businessEntityID: value.bEntityID,
      });
      if (value.brand_type_id !== 3) {
        setUserDashboardState("monetization");
      }
      sessionStorage.setItem("activeBrand", value.ID);
      sessionStorage.setItem("brandType", value.bEntityID);
    } else {
      value = {
        brand_id: "",
        brand_name: "",
        brand_type: "",
        businessEntityID: "",
      };
      setCurrentBrand({
        ID: value.brand_id,
        name: value.brand_name,
        type: value.brand_type,
        businessEntityID: value.businessEntityID,
      });
      setUserDashboardState("");
    }
  }

  function setDashboardUser(e) {
    setUserDashboardState(e.target.value);
  }
}
