import React from "react";
import { Grid } from "@mui/material";
import Select from "react-select";

export default function SortBy({ sortBy, setSortBy, categorize }) {
  let options = [
    { value: "ALL", label: "All" },
    { value: "ACTIVE", label: "Active", color: "#5AC35F" },
    { value: "OFFER_ACCEPTED", label: "Accepted", color: "#5AC35F" },
    { value: "CREATED", label: "Created", color: "#FFBB0B" },
    { value: "REQUESTED", label: "Requested", color: "#FFBB0B" },
  ];

  if (categorize === "Campaign Offers") {
    options = [
      { value: "ALL", label: "All" },
      { value: "REQUESTED", label: "Requested", color: "#FFBB0B" },
      { value: "OFFER_ACCEPTED", label: "Accepted", color: "#5AC35F" },
      { value: "ACTIVE", label: "Active", color: "#5AC35F" },
      { value: "CREATED", label: "Created", color: "#FFBB0B" },
    ];
  }

  if (categorize === "Active Campaigns") {
    options = [
      { value: "ACTIVE", label: "Active", color: "#5AC35F" },
      { value: "DEACTIVATED", label: "Deactivated", color: "#FFBB0B" },
      { value: "EXPIRED", label: "Expired", color: "#FF3A29" },
    ];
  }

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      height: "50px",
      p: "15px 18px",
      color: "#000000",
      fontWeight: 400,
      display: "flex",
      width: "inherit",
      fontsize: "14px",
      lineHeight: "20px",
      boxShadow: "none",
      alignItems: "center",
      borderRadius: "10px",
      justifyContent: "space-between",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: " #000000",
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      fontWeight: 600,
      fontSize: "18px",
      color: data.color,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: data.color,
        fontWeight: isSelected ? 600 : 400,
        backgroundColor: "white",
        fontSize: "18px",
        lineHeight: "20px",
        mixBlendMode: "normal",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  const handleTypeSelect = (e) => {
    setSortBy(e.value);
  };

  return (
    <Grid item xs={3}>
      <Select
        styles={style}
        options={options}
        onChange={handleTypeSelect}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        value={options.filter(function (option) {
          return option.value === sortBy;
        })}
      />
    </Grid>
  );
}
