import styled from "styled-components/macro";
import { People, Sync } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import { toInternationalFormat } from "../../../utils";
import { Fragment, useEffect, useState } from "react";
import { useAxios } from "../../../hooks";

const MainHeading = styled(Typography)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #ffffff;
`;

const OuterDiv = styled.div`
  background: #34b53a;
  border-radius: 10px;
  padding: 20px 20px 0;
`;

const UserTypography = styled(Typography)`
  color: #fff;
  margin-top: 10px;
  font-size: 60px;
`;

const Snap = styled(Typography)`
  color: #fff;
  margin-top: 10px;
  font-size: 20px;
`;

export default function CampaignLiveUser({
  liveUsers,
  loadingLiveUsers,
  userData,
  setLiveUsers,
  setLoadingLiveUsers,
}) {
  const axios = useAxios();

  useEffect(() => {
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    if (!!liveUsers.liveMessage) {
      const timeoutId = setInterval(() => {
        axios({
          url: "/user/getUsersCount?v=1",
          method: "POST",
          disableRedirect: true,
          disableTimeOut: true,
          data: userData,
        }).then((response) => {
          if (response.success) {
            setLiveUsers({
              state: true,
              liveMessage: response.message,
              liveUser: response?.usersCount,
            });
            setLoadingLiveUsers(false);
          }
        });
      }, 10000);

      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [liveUsers.liveMessage, axios, userData]);

  return (
    <Grid container spacing={2} mt="20px">
      <Grid item xs={12}>
        <OuterDiv>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MainHeading>
                Users Count{" "}
                {/* {!!liveUsers.liveMessage && showRefresh && (
                  <Tooltip title="Click to refresh users.">
                    <IconButton
                      disableRipple
                      onClick={handleRefresh}
                      style={{ padding: "8px 0px", color: "#fff" }}
                    >
                      <Sync />
                    </IconButton>
                  </Tooltip>
                )} */}
              </MainHeading>

              {!loadingLiveUsers ? (
                <Fragment>
                  <UserTypography>
                    {liveUsers?.liveUser !== null || liveUsers?.liveUser !== ""
                      ? toInternationalFormat(liveUsers?.liveUser)
                      : "N.A"}{" "}
                  </UserTypography>
                  {!!liveUsers.liveMessage && (
                    <Snap>
                      <LinearProgressWithLabel value={liveUsers.liveMessage} />
                    </Snap>
                  )}
                </Fragment>
              ) : (
                <Snap>
                  <LinearProgressWithLabel value={"Fething Users"} />
                </Snap>
              )}
            </Grid>

            <Grid item xs={12} textAlign="end" pr="50px">
              <People style={{ fontSize: "60px", color: "#fff" }} />
            </Grid>
          </Grid>
        </OuterDiv>
      </Grid>
    </Grid>
  );

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ minWidth: 35, marginRight: "10px" }}>
          <Typography variant="body2" color="#fff">
            {props.value}
          </Typography>
        </Box>

        <Box sx={{ width: "30%", mr: 1 }}>
          <LinearProgress style={{ backgroundColor: "#fff" }} {...props} />
        </Box>
      </Box>
    );
  }
}
