import { Grid } from "@mui/material";

import { Label, Paper, AutocompleteMultiSelect } from "../../../components";

export default function FormTwo({
  formikProps,
  categories,
  location,
  languages,
}) {
  return (
    <Paper>
      <Grid item xs={12}>
        <Label
          title="Business Category"
          formikProps={formikProps}
          required={true}
          name={"siteCategory"}
        />
      </Grid>

      <Grid item xs={10}>
        <AutocompleteMultiSelect
          name="siteCategory"
          formikProps={formikProps}
          defaults={{
            primaryKey: "name",
            displayLabel: "name",
          }}
          options={categories}
          placeholder="Select Category"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "siteCategory", "name")
          }
        />
      </Grid>

      <Grid item xs={5}>
        <Label
          title={"Location"}
          formikProps={formikProps}
          required={true}
          name={"audienceLocation"}
        />
      </Grid>

      <Grid item xs={10}>
        <AutocompleteMultiSelect
          name="audienceLocation"
          formikProps={formikProps}
          defaults={{
            primaryKey: "country_code",
            displayLabel: "country_name",
          }}
          options={location}
          placeholder="Select Location"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "audienceLocation", "country_code")
          }
        />
      </Grid>

      <Grid item xs={6}>
        <Label
          title={"Language"}
          formikProps={formikProps}
          required={true}
          name={"audienceLanguages"}
        />
      </Grid>

      <Grid item xs={10}>
        <AutocompleteMultiSelect
          name="audienceLanguages"
          formikProps={formikProps}
          defaults={{
            primaryKey: "code",
            displayLabel: "language",
          }}
          options={languages}
          placeholder="Select Language"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "audienceLanguages", "code")
          }
        />
      </Grid>
    </Paper>
  );

  function handleChange(arr, { option }, name, displayName) {
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [name]: setValuesOfDropDown(prevVal[name]),
    }));

    function setValuesOfDropDown(val) {
      let values = [...val];
      const index = values.indexOf(option[displayName]);
      if (index > -1) {
        values.splice(index, 1);
      } else {
        values.push(option[displayName]);
      }

      return values;
    }
  }
}
