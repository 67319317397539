import styled from "styled-components/macro";
import { Grid, MenuItem, Typography, Checkbox } from "@mui/material";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";

import { Label, Paper, TextField } from "../../../components";
import AutocompleteMultiSelect from "../../../components/MultiselectAutoComplete";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const Heading = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  align-self: center;
`;

const Menu = styled(MenuItem)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
`;

export default function FormTwo({
  formikProps,
  editState,
  location,
  languages,
}) {
  return (
    <Paper>
      <Grid item xs={12}>
        <MainHeading>Audience Preferences</MainHeading>
      </Grid>

      <Grid item xs={6}>
        <Label title={"Location"} />
      </Grid>

      <Grid
        item
        xs={4}
        textAlign="end"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <Heading
          style={{
            color: formikProps.values.audience_location.mandatory
              ? "red"
              : "gray",
          }}
        >
          Mandatory
        </Heading>

        <Checkbox
          disabled={!editState}
          name="audience_location.mandatory"
          onChange={handleCheckChange}
          checked={formikProps.values.audience_location.mandatory}
          icon={<ToggleOffOutlinedIcon fontSize="large" />}
          checkedIcon={
            <ToggleOnOutlinedIcon fontSize="large" style={{ color: "red" }} />
          }
        />
      </Grid>

      <Grid item xs={10}>
        <AutocompleteMultiSelect
          name="audience_location.value"
          formikProps={formikProps}
          forcePopupIcon={!editState ? false : true}
          disabled={!editState}
          defaults={{
            primaryKey: "country_code",
            displayLabel: "country_name",
          }}
          options={location}
          placeholder="Select Location"
          onChange={(_, arr, r, option) =>
            handleChange(arr, option, "audience_location.value", "country_code")
          }
        />
      </Grid>

      <Grid item xs={6}>
        <Label title={"Language"} />
      </Grid>

      <Grid
        item
        xs={4}
        textAlign="end"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <Heading
          style={{
            color: formikProps.values.languages.mandatory ? "red" : "gray",
          }}
        >
          Mandatory
        </Heading>

        <Checkbox
          disabled={!editState}
          name="languages.mandatory"
          onChange={handleCheckChange}
          checked={formikProps.values.languages.mandatory}
          icon={<ToggleOffOutlinedIcon fontSize="large" />}
          checkedIcon={
            <ToggleOnOutlinedIcon fontSize="large" style={{ color: "red" }} />
          }
        />
      </Grid>

      <Grid item xs={10}>
        <AutocompleteMultiSelect
          name="languages.value"
          forcePopupIcon={!editState ? false : true}
          formikProps={formikProps}
          disabled={!editState}
          defaults={{
            primaryKey: "code",
            displayLabel: "language",
          }}
          options={languages}
          placeholder="Select Language"
          onChange={(_, arr, option) =>
            handleChange(arr, option, "languages.value", "code")
          }
        />
      </Grid>

      <Grid item xs={10}>
        <Label title={"Non mandatory match percentage"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          select
          formikProps={formikProps}
          name="percentage.value"
          disabled={!editState}
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            style: {
              color: formikProps.values.percentage.value === "" && "#bababacf",
            },
          }}
        >
          <Menu value="" disabled>
            Select Match Percentage
          </Menu>
          <Menu value="25">25%</Menu>
          <Menu value="50">50%</Menu>
          <Menu value="75">75%</Menu>
          <Menu value="100">100%</Menu>
        </TextField>
      </Grid>
    </Paper>
  );

  function handleChange(arr, { option }, name, displayName) {
    let objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValuesOfDropDown(prevVal[objectName]),
    }));

    function setValuesOfDropDown(val) {
      let values = [...val.value];

      const index = values.indexOf(option[displayName]);
      if (index > -1) {
        values.splice(index, 1);
      } else {
        values.push(option[displayName]);
      }

      return {
        ...val,
        value: values,
      };
    }
  }

  function handleCheckChange(e) {
    let { name } = e.target;
    let objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValues(prevVal[objectName]),
    }));

    function setValues(val) {
      return {
        ...val,
        mandatory: e.target.checked,
      };
    }
  }
}
