import { Fragment, useState } from "react";
import { Grid } from "@mui/material";

import Status from "./Status";
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import ActionButtons from "./ActionButtons";
import { PageHeader } from "../../../components";

export default function FormDisplay({
  editState,
  formikProps,
  brands,
  setEditState,
}) {
  const [action, setAction] = useState({ delete: true, suspend: true });

  const [accountStatus, setAccountStatus] = useState(
    formikProps.values.accountStatus === 4 ||
      formikProps.values.accountStatus === 5
      ? true
      : false
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader
          subHeading={"Users"}
          twoText={!formikProps.values.userID && true}
          firstText={"Add New User."}
          secondText={"Play Your Part."}
          pageName={
            !!formikProps.values.userID &&
            formikProps.values.givenName + " " + formikProps.values.familyName
          }
          textAvatar={
            formikProps.values.givenName.substring(0, 1) +
            formikProps.values.familyName.substring(0, 1)
          }
          buttons={[
            <Fragment>
              <ActionButtons
                action={action}
                setAction={setAction}
                formikProps={formikProps}
                accountStatus={accountStatus}
                setAccountStatus={setAccountStatus}
              />
            </Fragment>,
          ]}
        />
      </Grid>

      <Grid item xs={12}>
        <Status
          action={action}
          accountStatus={accountStatus}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={6}>
        <FormOne
          brands={brands}
          formikProps={formikProps}
          editState={editState}
          setEditState={setEditState}
        />
      </Grid>

      <Grid item xs={6}>
        <FormTwo
          formikProps={formikProps}
          editState={editState}
          setEditState={setEditState}
        />
      </Grid>
    </Grid>
  );
}
