import { Grid, Checkbox, FormControlLabel } from "@mui/material";

import { colors } from "../../css components/Style";
import { Label, Paper, Autocomplete, TextField } from "../../../components";
import { Fragment } from "react";

export default function FormOne({ formikProps, brands, editState }) {
  return (
    <Paper>
      <Grid item xs={10}>
        <Label
          title={"Brand Name"}
          formikProps={formikProps}
          required={true}
          name={"brand_id"}
        />
      </Grid>

      <Grid item xs={10}>
        <Autocomplete
          name="brandID"
          forcePopupIcon={!editState ? false : true}
          placeholder="Select Brand Name"
          defaults={{
            primaryKey: "brand_id",
            displayLabel: "brand_name",
          }}
          options={brands}
          disabled={!!formikProps.values.connectionID}
          onChange={(_, value, type) => handleChangeBrand(value, type)}
          formikProps={formikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={!editState}
              onChange={handleCheckNoDocker}
              checked={formikProps.values.noDocker}
              sx={{
                color: colors.info,
                "&.Mui-checked": {
                  color: colors.info,
                },
              }}
            />
          }
          label="No Docker"
        />
      </Grid>

      {!formikProps.values.noDocker && (
        <Fragment>
          <Grid item xs={10}>
            <Label title={"Connection URL"} />
          </Grid>

          <Grid item xs={10}>
            <TextField
              name="connectionUrl"
              placeholder="Connection URL"
              disabled={!editState}
              formikProps={formikProps}
              inputProps={{ "aria-label": "Without label" }}
            />
          </Grid>

          <Grid item xs={10}>
            <Label title={"Token"} />
          </Grid>

          <Grid item xs={10}>
            <TextField
              multiline
              name="token"
              placeholder="Token"
              disabled={!editState}
              formikProps={formikProps}
              inputProps={{ "aria-label": "Without label" }}
            />
          </Grid>
        </Fragment>
      )}
    </Paper>
  );

  function handleCheckNoDocker(event) {
    formikProps.setValues((prev) => ({
      ...prev,
      noDocker: event.target.checked,
    }));
  }

  function handleChangeBrand(value, type) {
    if (type === "selectOption") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        brandID: value.brand_id,
      }));
    } else {
      value = {
        brand_id: "",
        brand_name: "",
      };

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        brandID: value.brandID,
      }));
    }
  }
}
