import * as Yup from "yup";

export const changePasswordObject = {
  email: "",
};

export function objectFromFormData(formData) {
  return {
    email: formData.email,
  };
}

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});
