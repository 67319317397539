import { useState } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Grid, IconButton, InputAdornment, Typography } from "@mui/material";

import pages from "../../../constants/pages";
import { Paper, TextField, Label } from "../../../components";
import {
  CancelButton,
  PrimaryLoadingButton,
} from "../../css components/Button";

const LoginText = styled(Typography)`
  font-size: 40px;
  font-weight: 500;
  line-height: 60px;
  margin-bottom: 20px;
`;

export default function FormDisplay({ formikProps }) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Paper className="light-mode typo" style={{ width: "600px" }}>
      <Grid item xs={12}>
        <LoginText variant="h3" color="#BDBDBD" mt="5px">
          Login Now.
        </LoginText>
      </Grid>

      <Grid item xs={12}>
        <Label title={"Email Address"} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          formikProps={formikProps}
          name="email"
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter email address"
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Password"} />
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          type={showPassword ? "text" : "password"}
          name="password"
          formikProps={formikProps}
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {!showPassword && <VisibilityOffIcon />}
                  {showPassword && <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} alignSelf="center">
        <Typography variant="button">
          Don't have account.{" "}
          <Typography
            variant="button"
            color="#00BEFF"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/sign-up")}
          >
            Sign up now!
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs={12} alignSelf="center">
        <Typography variant="button">
          <Typography
            variant="button"
            color="#00BEFF"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(pages.resetEmailPasswordPage.route)}
          >
            Forgot Password?
          </Typography>
        </Typography>
      </Grid>

      <Grid item xs />

      <Grid item>
        <CancelButton variant="outlined">Cancel</CancelButton>
      </Grid>

      <Grid item>
        <PrimaryLoadingButton
          type="submit"
          variant="contained"
          onClick={formikProps.handleSubmit}
          disabled={!formikProps.dirty || !formikProps.isValid}
          loading={formikProps.isSubmitting}
        >
          Log In
        </PrimaryLoadingButton>
      </Grid>
    </Paper>
  );
}
