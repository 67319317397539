import ENV from "../config/config";

const URL_PREFIX = ENV === "main" ? "" : ENV + ".";

const URL =
  ENV === "main"
    ? "https://backend.moxplatform.com"
    : "https://" + URL_PREFIX + "backend-mox.metawing.ai";

const BASE_URL = {
  mox_api: {
    url: URL,
    version: "/api/v1/mox",
  },
};

export default BASE_URL;
