import styled from "styled-components";
import { Grid, Typography } from "@mui/material";

import Form from "./Form";
import { Paper } from "../../components";
import AdminForm from "./AdminWelcomeForm";

const OuterGrid = styled(Grid)`
  padding-left: 13.19%;
  padding-right: 13.19%;
  margin-top: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const WelcomeTypography = styled(Typography)`
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: rgba(0, 0, 0, 1);
`;

const BrandTypography = styled(Typography)`
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: rgba(189, 189, 189, 1);
`;

const PaperGrid = styled(Paper)`
  width: 731px;
  height: 540px;
`;

export default function SelectRole({
  businessEntityID,
  brands,
  authToken,
  userID,
  component = "",
}) {
  return (
    <OuterGrid container spacing={2}>
      <Grid item xs={3}>
        <WelcomeTypography>Welcome to</WelcomeTypography>

        <BrandTypography>MOX Platform</BrandTypography>
      </Grid>

      <Grid item xs={9}>
        <PaperGrid style={{ padding: "20px 50px", margin: "auto" }}>
          {component === "admin" ? (
            <AdminForm
              businessEntityID={businessEntityID}
              brands={brands}
              authToken={authToken}
              userID={userID}
            />
          ) : (
            <Form
              businessEntityID={businessEntityID}
              brands={brands}
              authToken={authToken}
              userID={userID}
            />
          )}
        </PaperGrid>
      </Grid>
    </OuterGrid>
  );
}
