import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import pages from "../constants/pages";
import { closeAlert } from "../redux/slices/alertSlice";

export default function RoleBaseNavigation({ role, roles = [], ...props }) {
  const location = useLocation();

  const dispatch = useDispatch();

  const alert = useSelector((state) => state.alertDetails);

  useEffect(() => {
    if (alert.state) {
      dispatch(closeAlert());
    }
  }, [location]);

  return roles.includes(role) ? (
    <Outlet />
  ) : (
    <Navigate to={pages.permissionDenied.route} />
  );
}
