import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { Replay } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { Info } from "@mui/icons-material";

import { Paper } from "../../../components";
import brandIcon from "../../../assets/brandIcon.png";
import { HeadingEighteen, colors } from "../../css components/Style";
import { useAxios, useComponent } from "../../../hooks";
import {
  brandDetailsObject,
  objectFromBrandResponse,
} from "../brand details components/brandDetailsObject";
import BrandDetailsDialog from "../brand details components/BrandDetailsDialog";
import ConnectionDetailsDilog from "./connection list components/ConnectionDetailsDialog";
import StatusDialog from "../../connection components/connection list components/dialog components/VerifyDialog";

const Subtitle = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.4;
`;

const Link = styled(Typography)`
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

const ActiveHeading = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
`;

export default function Connections({
  retry = {},
  setRetry,
  connections,
  component = "",
  formikProps,
  setConnections,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();

  const { alert } = useComponent();

  const [brandDetails, setBrandDetails] = useState({
    data: brandDetailsObject,
    state: false,
  });

  const [connectionDetails, setConnectionDetails] = useState({
    state: false,
    data: {},
  });

  const [details, setDetails] = useState({ state: false });

  function handleClick(connection) {
    setDetails({
      state: true,
      connection: connection,
    });
  }

  return (
    <Fragment>
      <Grid container spacing={3}>
        {connections?.map((connection, index) => (
          <Grid item xs={12} key={connection?.brand_id}>
            <Paper>
              <Grid item xs={6}>
                <Link>Connection</Link>
              </Grid>

              <Grid
                item
                xs={6}
                display="flex"
                alignSelf={"center"}
                justifyContent="end"
                style={{ cursor: "pointer" }}
              >
                {(connection.requestStatus === "ACCEPTED" ||
                  connection.requestStatus === "PID FETCHED FAILED") &&
                  (connection.pidStatus === "FAILED" ||
                    connection.pidStatus === "PENDING" ||
                    connection.dbStatus === "FAILED" ||
                    connection.pidStatus === "") &&
                  (!retry[index] ? (
                    <HeadingEighteen
                      onClick={() => handleRetry(connection, index)}
                      style={{ padding: "0", color: colors.info }}
                    >
                      <Replay
                        style={{ top: "2px", position: "relative" }}
                        color={colors.info}
                        fontSize="35px"
                      />{" "}
                      Retry
                    </HeadingEighteen>
                  ) : (
                    <HeadingEighteen
                      style={{ padding: "0", color: colors.info }}
                    >
                      Retrying.....
                    </HeadingEighteen>
                  ))}
                &nbsp; &nbsp;
                <svg
                  style={{ position: "relative", margin: "9px 9px" }}
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    fill={
                      connection.connRequestStatus === "Ready" ||
                      connection.connRequestStatus === "Sync_In_Progress"
                        ? colors.warning
                        : connection.connRequestStatus === "Active"
                        ? colors.success
                        : colors.error
                    }
                  />
                </svg>
                <ActiveHeading
                  color={
                    connection.connRequestStatus === "Ready" ||
                    connection.connRequestStatus === "Sync_In_Progress"
                      ? colors.warning
                      : connection.connRequestStatus === "Active"
                      ? colors.success
                      : colors.error
                  }
                  variant="button"
                >
                  {connection.connRequestStatus?.replace(/_/g, " ") || "N.A"}

                  {component === "admin" && (
                    <img
                      alt="!"
                      src={brandIcon}
                      onClick={() =>
                        setConnectionDetails({
                          state: true,
                          data: {
                            ...connection,
                            brandID: formikProps.values?.brandID,
                            campaignID: formikProps.values?.campaignID,
                            campaignName:
                              formikProps.values?.campaign_name?.value,
                          },
                        })
                      }
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                    />
                  )}
                </ActiveHeading>
                {!!connection.note && (
                  <Info
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleClick(connection)}
                  />
                )}
              </Grid>

              <Grid item xs={2}>
                <div style={{ display: "flex" }}>
                  <Subtitle>Brand</Subtitle>

                  <img
                    alt="!"
                    src={brandIcon}
                    onClick={() =>
                      handleBrandDetails(connection?.receiverBrandId)
                    }
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  />
                </div>
                <HeadingEighteen>{connection?.name || "N.A"}</HeadingEighteen>
              </Grid>

              <Grid item xs={2} style={{ paddingLeft: "0px" }}>
                <Subtitle>MAU</Subtitle>
                <HeadingEighteen>{connection?.mau || "N.A"}</HeadingEighteen>
              </Grid>

              <Grid item xs={2} style={{ paddingLeft: "0px" }}>
                <Subtitle> Location</Subtitle>
                <HeadingEighteen sx={{ textTransform: "uppercase" }}>
                  {connection?.locations?.length > 1
                    ? connection?.locations
                        .map((loc) => loc?.substr(0, 2))
                        .join(", ")
                    : connection?.locations[0]?.substr(0, 2)}
                </HeadingEighteen>
              </Grid>

              <Grid item xs={2} style={{ paddingLeft: "0px" }}>
                <Subtitle> Languages</Subtitle>
                <HeadingEighteen sx={{ textTransform: "uppercase" }}>
                  {connection.languages?.length > 1
                    ? connection?.languages
                        ?.map((lan) => lan?.substr(0, 2))
                        .join(", ")
                    : connection?.languages[0]?.substr(0, 2)}
                </HeadingEighteen>
              </Grid>

              <Grid item xs={3} style={{ paddingLeft: "0px" }}>
                <Subtitle> Category</Subtitle>
                <HeadingEighteen sx={{ textTransform: "uppercase" }}>
                  {connection?.category?.length > 1
                    ? connection?.category?.map((lan) => lan).join(", ")
                    : connection?.category[0]}
                </HeadingEighteen>
              </Grid>

              <Grid item xs={1} style={{ paddingLeft: "0px" }}>
                <Subtitle> User Count</Subtitle>
                <HeadingEighteen
                  sx={{ textTransform: "uppercase" }}
                  style={{ color: "#4339F2" }}
                >
                  {connection?.expectedCount || "N.A"}
                </HeadingEighteen>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <BrandDetailsDialog details={brandDetails} setDetails={setBrandDetails} />
      <ConnectionDetailsDilog
        setConnections={setConnections}
        details={connectionDetails}
        setRetry={setRetry}
        setDetails={setConnectionDetails}
      />
      <StatusDialog detail={details} setDetail={setDetails} />
    </Fragment>
  );

  function handleRetry(connection, index) {
    setRetry((prev) =>
      prev.map((items, indx) => (index === indx ? !items : items))
    );

    if (connection.pidStatus === "FAILED" || connection.pidStatus === "") {
      axios({
        url: "/user/takeUpOffer?v=1&requestStatus=ACCEPTED",
        method: "POST",
        data: {
          sender_brandId: parseInt(connection.senderBrandId),
          sender_campaignId: parseInt(connection.senderCampaignId),
          receiver_userId: parseInt(connection.receiverUserId),
          receiver_brandId: parseInt(connection.receiverBrandId),
        },
      }).then((response) => {
        if (response.success) {
          setRetry((prev) =>
            prev.map((items, indx) => (index === indx ? !items : items))
          );

          dispatch(alert.success(response.message));
        }
      });
    }

    if (connection.dbStatus === "FAILED") {
      axios({
        url: "/adserver/retryUserDbUpload",
        method: "POST",
        data: {
          sender_brand_id: parseInt(connection.senderBrandId),
          sender_campaign_id: parseInt(connection.senderCampaignId),
          receiver_brand_id: parseInt(connection.receiverBrandId),
        },
      }).then((response) => {
        if (response.success) {
          setRetry((prev) =>
            prev.map((items, indx) => (index === indx ? !items : items))
          );

          dispatch(alert.success(response.message));
        }
      });
    }
  }

  function handleBrandDetails(brandID) {
    axios({
      url: "/getBrand/" + brandID,
      disableRedirect: true,
    }).then((response) => {
      if (response.success) {
        setBrandDetails({
          data: objectFromBrandResponse(response.data),
          state: true,
          component: "connection",
        });
      }
    });
  }
}
