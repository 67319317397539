import { Grid, Avatar, Skeleton } from "@mui/material";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";
import { Fragment, useEffect, useState } from "react";

import BASE_URL from "../../../constants/baseURL";
import List from "../campaign list components/List";
import { useAxios, useComponent } from "../../../hooks";
import { Loader, PageHeader } from "../../../components";
import { NoListTypography } from "../../css components/Style";
import Search from "../campaign list components/superadmin list components/Search";
import SortBy from "../campaign list components/superadmin list components/SortBy";
import Categorize from "../campaign list components/superadmin list components/Categorize";
import BrandList from "../../superAdmin brand components/brand list components/BrandList";
import { toInternationalFormat } from "../../../utils";

const LogoAvatar = styled(Avatar)`
  height: 70px;
  width: 70px;
  border-radius: 50%;
`;

export default function SuperAdminBrandCampaignList() {
  const axios = useAxios();
  const params = useParams();
  const { loader } = useComponent();

  const [brand, setBrand] = useState({});
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState("ALL");
  const [campaigns, setCampaigns] = useState([]);
  const [brandList, setBrandList] = useState([]);

  const [categorize, setCategorize] = useState("Campaign Offers");

  const brandID = params.brandID || "";

  useEffect(() => {
    loader.start();

    let url = "/user/brand/getCampaignsByBrand/?brandId=" + brandID;

    if (sortBy !== "ALL") {
      url =
        "/user/brand/getCampaignsByBrand/?brandId=" +
        brandID +
        "&requestStatus=" +
        sortBy;
    }

    axios({
      url: url,
    }).then((response) => {
      if (response.success) {
        axios({
          url: "/getBrand/" + brandID,
          method: "GET",
        }).then((brands) => {
          if (brands.success) {
            setBrand(brands.data);
            setLoading(false);
            let arr = [];

            for (let key of response.data) {
              let data = {};
              for (let fields of key.fields) {
                if (
                  Object.keys(fields)[0] === "budget_min" ||
                  Object.keys(fields)[0] === "budget_max" ||
                  Object.keys(fields)[0] === "mau" ||
                  Object.keys(fields)[0] === "volume"
                ) {
                  let val =
                    Object.values(fields)[0] &&
                    Object.values(fields)[0].replace(/,/g, "");
                  data[Object.keys(fields)[0]] = toInternationalFormat(val);
                } else {
                  data[Object.keys(fields)[0]] = Object.values(fields)[0];
                }
              }
              arr.push({
                ...data,
                brand: {
                  name: brands.data.brand_name,
                  logo: brands.data.logo,
                },
                previewImage: !!key.preview_image
                  ? key.preview_image
                  : "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png",
                status: key.status,
                audience_id: key.audience_id,
                brand_id: key.brand_id,
                campaign_id: key.campaign_id,
                userCount: key.total_users_count,
                adminCampaignStatus: key.admin_campaign_status,
              });
            }

            if (categorize === "Campaign Offers") {
              setCampaigns(
                arr.filter((items) => items.adminCampaignStatus === 1)
              );
            } else if (categorize === "Unverified Campaigns") {
              setCampaigns(
                arr.filter((items) => items.adminCampaignStatus === 0)
              );
            } else if (categorize === "Rejected Campaigns") {
              setCampaigns(
                arr.filter((items) => items.adminCampaignStatus === 2)
              );
            }

            loader.stop();
          }
        });
      } else {
        loader.stop();
      }
    });
  }, [axios, brandID, loader, categorize, sortBy]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} display="flex">
          {loading ? (
            <div style={{ display: "flex", marginBottom: "20px" }}>
              <div>
                <Skeleton
                  animation="wave"
                  variant="circular"
                  height={70}
                  width={70}
                />
              </div>

              <div>
                <Skeleton
                  animation="wave"
                  style={{ marginLeft: "20px" }}
                  variant="rectangular"
                  height={23}
                  width={50}
                />

                <Skeleton
                  style={{ marginLeft: "20px", marginTop: "20px" }}
                  animation="wave"
                  variant="rectangular"
                  height={23}
                  width={80}
                />
              </div>
            </div>
          ) : (
            <Fragment>
              <Grid item alignSelf="center" paddingRight="20px">
                <LogoAvatar src={brand.logo} alt="!" />
              </Grid>

              <PageHeader
                heading={"Campaigns"}
                pageName={
                  categorize === "Brands" ? "All Brands" : brand.brand_name
                }
              />
            </Fragment>
          )}
        </Grid>

        <Categorize
          categorize={categorize}
          setCategorize={setCategorize}
          brand={"brand"}
        />

        {/* <SortBy sortBy={sortBy} setSortBy={setSortBy} categorize={categorize} /> */}

        {/* <Search /> */}

        <Grid item xs={12} />
      </Grid>

      <Loader height="75%">
        {categorize === "Campaign Offers" ? (
          campaigns.length > 0 ? (
            <List campaigns={campaigns} component="superadmin" />
          ) : (
            <NoListTypography>No campaign to show!</NoListTypography>
          )
        ) : categorize === "Brands" ? (
          brandList.length > 0 ? (
            <BrandList brandList={brandList} component="campaign" />
          ) : (
            <NoListTypography>No campaign to show!</NoListTypography>
          )
        ) : categorize === "Rejected Campaigns" ? (
          campaigns.length > 0 ? (
            <List
              categorize={categorize}
              campaigns={campaigns}
              component="superadmin"
            />
          ) : (
            <NoListTypography>No campaign to show!</NoListTypography>
          )
        ) : (
          categorize === "Unverified Campaigns" &&
          (campaigns.length > 0 ? (
            <List campaigns={campaigns} component="superadmin" />
          ) : (
            <NoListTypography>No campaign to show!</NoListTypography>
          ))
        )}
      </Loader>
    </Fragment>
  );
}
