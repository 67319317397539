import * as Yup from "yup";
import { toInternationalFormat } from "../../../utils";
import convertDateTimeUTC from "../../../utils/convertUTC";

export const createCampaignObject = {
  brandID: "",
  audienceID: "",
  campaignID: "",
  previewImage: "",
  isEditable: true,
  campaignStatus: "",
  brandDetails: {
    brandName: "",
    brandLogo: "",
  },

  file_name: {
    value: "",
  },

  campaign_name: {
    value: "",
  },

  about: {
    value: "",
  },

  destination_url: {
    value: "",
  },

  start_duration: {
    value: null,
  },

  end_duration: {
    value: null,
  },

  volume: {
    value: "0",
  },

  budget_min: {
    value: "",
  },

  budget_max: {
    value: "",
  },

  mau: {
    value: "",
  },

  conversion_type: {
    value: "",
  },

  payout_percentage: {
    value: "",
  },

  payout_terms: {
    value: "",
  },

  trail_period: {
    value: "",
  },

  category: {
    value: [],
  },

  creatives: [],
};

export function objectFromFormData(formData, activeBrand) {
  let sDate = new Date(formData.start_duration.value);
  let eDate = new Date(formData.end_duration.value);
  var newURL = formData.destination_url.value;
  if (!/(http(s?)):\/\//i.test(formData.destination_url.value)) {
    newURL = "https://" + formData.destination_url.value;
  }

  const url = new URL(newURL);
  if (!newURL.includes("%conversionid%")) {
    if (
      !url.searchParams.has("conv") &&
      !url.searchParams.has("user") &&
      !url.searchParams.has("site")
    ) {
      if (url.searchParams.size > 0) {
        formData.destination_url.value +=
          "&conv=%conversionid%&user={{user.key}}&site={{site.id}}";
      } else {
        formData.destination_url.value +=
          "?conv=%conversionid%&user={{user.key}}&site={{site.id}}";
      }
    }
  }

  let fields = [
    { file_name: formData.file_name },

    {
      campaign_name: formData.campaign_name,
    },

    {
      about: formData.about,
    },

    {
      destination_url: formData.destination_url,
    },

    {
      start_duration: {
        value: sDate.toISOString(),
      },
    },

    {
      end_duration: { value: eDate.toISOString() },
    },

    {
      volume: {
        value: formData.volume.value.replace(/[,]/g, ""),
      },
    },

    {
      budget_min: {
        value: formData.budget_min.value
          ? parseInt(formData.budget_min.value.replace(/[,]/g, ""))
          : 0,
      },
    },

    {
      budget_max: {
        value: formData.budget_min.value
          ? parseInt(formData.budget_max.value.replace(/[,]/g, ""))
          : 0,
      },
    },

    {
      mau: {
        value: formData.mau.value ? formData.mau.value.replace(/[,]/g, "") : 0,
      },
    },

    {
      category: formData.category,
    },

    {
      conversion_type: formData.conversion_type,
    },

    {
      payout_percentage: formData.payout_percentage,
    },

    {
      payout_terms: formData.payout_terms,
    },

    {
      trail_period: formData.trail_period,
    },
  ];

  return {
    brand_id: parseInt(activeBrand.ID),
    audience_id: !!formData.audienceID ? formData.audienceID : null,
    preview_image: formData.previewImage,
    fields: fields,
  };
}

export function creative(formData, campaignID, creativeData) {
  let data = {};
  let resp = {};

  var newURL = formData.destination_url;
  if (!/(http(s?)):\/\//i.test(formData.destination_url)) {
    newURL = "https://" + formData.destination_url;
  }

  const url = new URL(newURL);
  if (!newURL.includes("%conversionid%")) {
    if (
      !url.searchParams.has("conv") &&
      !url.searchParams.has("user") &&
      !url.searchParams.has("site")
    ) {
      if (url.searchParams.size > 0) {
        formData.destination_url +=
          "&conv=%conversionid%&user={{user.key}}&site={{site.id}}";
      } else {
        formData.destination_url +=
          "?conv=%conversionid%&user={{user.key}}&site={{site.id}}";
      }
    }
  }

  if (creativeData.creativeID) {
    resp = {
      campaign_id: campaignID,
      size: String(formData.adSize),
      adtype_id: formData.adSize,
      format: formData.format,
      creative_name: formData.friendlyName,
      ad_tag: formData.adTag,
      destination_url: formData.destination_url,
      same_as_campaign_url: formData.destinationURLCheckbox,
      link: formData.fileUrl,
      banner_type: formData.upload,
    };

    return resp;
  } else if (!creativeData.creativeID) {
    if (formData.fileUrl && formData.upload === "link") {
      data = { ...data, link: formData.fileUrl, banner_type: formData.upload };
    } else {
      if (formData.creative) {
        data = {
          ...data,
          creative: formData.creative,
          banner_type: formData.upload,
        };
      }
    }

    data = {
      ...data,
      campaign_id: campaignID,
      size: formData.adSize,
      adtype_id: formData.adSize,
      format: formData.format,
      fileName: formData.fileName,
      creative_name: formData.friendlyName,
      ad_tag: formData.adTag,
      destination_url: formData.destination_url,
      same_as_campaign_url: formData.destinationURLCheckbox,
    };

    return data;
  }
}

export function objectFromResponse(response, adSizeData) {
  let data = {};
  let creativesData = [];
  for (let key of response?.campaignData) {
    if (Object.keys(key)[0] === "category") {
      data[Object.keys(key)[0]] = {
        value: Object.values(key)[0] || [],
      };
    }
    if (
      Object.keys(key)[0] === "budget_min" ||
      Object.keys(key)[0] === "budget_max" ||
      Object.keys(key)[0] === "mau" ||
      Object.keys(key)[0] === "volume"
    ) {
      data[Object.keys(key)[0]] = {
        value:
          (Object.values(key)[0] &&
            toInternationalFormat(Object.values(key)[0])) ||
          "",
      };
    } else {
      data[Object.keys(key)[0]] = {
        value: Object.values(key)[0] || "",
      };
    }
  }

  for (let create of response.creatives) {
    creativesData.push({
      creativeID: create.creative_id,
      format: create.banner_format,
      creative: create.file_path,
      fileUrl: create.url,
      friendlyName: create.creative_name,
      fileName: create.file_path,
      adSize: create.banner_size,
      adTag: create.ad_tag,
      destination_url: create.destination_url,
      destinationURLCheckbox: create.same_as_campaign_url,
      isDeleted: create.is_deleted,
      upload: create.banner_type,
    });
  }

  return {
    ...data,
    creatives: creativesData,
    brandID: response.brand_id,
    fileName: response.fileName,
    endTime: data.end_duration?.value,
    startTime: data.start_duration?.value || "",
    adminCampaignStatus: response?.admin_campaign_status,
    campaignStatus: response?.campaignStatus || "",
    campaignOverAllStatus: response?.status || "",
    campaignID: response.campaign_id,
    isEditable: response.is_editable,
    previewImage: response.preview_image || "",
    audienceID:
      response.audience.audience_id === -1
        ? false
        : response.audience.audience_id,
    campaignAdminStatus: response.admin_campaign_status,
    adSizeData: adSizeData,
    connections: response.connections,
    createdByRole: response.created_by_username
      ? response.created_by_username
      : response.updated_by_username,
    createdByName: response.created_by_username,
    updatedByName: response.updated_by_username,
    createdAt: convertDateTimeUTC(response.created_at),
    updatedAt: convertDateTimeUTC(response.updated_at),
  };
}

export function cloneCampaignData(response) {
  let data = {};
  for (let key of response.campaignData) {
    if (Object.keys(key)[0] === "category") {
      data[Object.keys(key)[0]] = {
        value: Object.values(key)[0] || [],
      };
    }
    if (
      Object.keys(key)[0] === "budget_min" ||
      Object.keys(key)[0] === "budget_max" ||
      Object.keys(key)[0] === "mau" ||
      Object.keys(key)[0] === "volume"
    ) {
      data[Object.keys(key)[0]] = {
        value:
          (Object.values(key)[0] &&
            toInternationalFormat(Object.values(key)[0])) ||
          "",
      };
    } else {
      data[Object.keys(key)[0]] = {
        value: Object.values(key)[0] || "",
      };
    }
  }
  return {
    ...data,
    brandID: response.brand_id,
    fileName: response.fileName,
    campaignID: response.campaign_id,
    isEditable: true,
    previewImage: response.preview_image,
    audienceID:
      response.audience.audience_id === -1
        ? false
        : response.audience.audience_id,
    campaignAdminStatus: response.admin_campaign_status,
  };
}

export const validationSchema = Yup.object().shape({
  campaign_name: Yup.object().shape({
    value: Yup.string().required("Campaign Name is required").min(3),
  }),

  previewImage: Yup.mixed().required("Preview Image is required"),

  endTime: Yup.date(),
  startTime: Yup.date(),

  start_duration: Yup.object().shape({
    value: Yup.date().required("Start Date is required"),
  }),

  // end_duration: Yup.object().shape({
  //   value: Yup.date().required("End Date is required"),
  // }),

  // end_duration: Yup.object().when(
  //   ["startTime", "endTime"],
  //   (startTime, endTime, schema) => {
  //     console.log(
  //       new Date(startTime).getTime() > new Date(endTime).getTime(),
  //       schema
  //     );
  //     if (new Date(startTime).getTime() > new Date(endTime).getTime()) {
  //       console.log("inside this", validationSchema);
  //       return Yup.object({
  //         end_duration: Yup.object({
  //           value: Yup.date().required("End Date is not required"),
  //         }),
  //       });
  //     } else {
  //       return Yup.object({
  //         value: Yup.date().required("End Date is required"),
  //       });
  //     }
  //   }
  // ),

  // end_duration: Yup.object().when(["startTime", "endTime"], {
  //   is: (start, end) => new Date(start).getTime() > new Date(end).getTime(),

  //   then: (schema) => console.log(schema),
  //   // otherwise: Yup.object().shape({
  //   //   value: Yup.date().required("End Date should be less than start date"),
  //   // }),
  // }),

  // mau: Yup.object().shape({
  //   value: Yup.string()
  //     .matches(/[0-9]/g, "Must be number")
  //     .required("Monthly active users is required"),
  // }),

  // budget_min: Yup.object().shape({
  //   value: Yup.string()
  //     .matches(/[0-9]/g, "Must be number")
  //     .required("Minimum budget is required"),
  // }),

  // budget_max: Yup.object().shape({
  //   value: Yup.string()
  //     .matches(/[0-9]/g, "Must be number")
  //     .required("Maximum budget is required"),
  // }),

  destination_url: Yup.object().shape({
    value: Yup.string()
      .matches(
        /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)[a-zA-Z0-9:@%._?=/-]{2,}(\.[a-zA-Z0-9:@%._?=/-]{2,})(\.[a-zA-Z0-9:@%._?=/-]{2,})?/,
        "Enter correct url!"
      )
      .required("Destination url is required"),
  }),

  conversion_type: Yup.object().shape({
    value: Yup.string().required("Conversion Type is required"),
  }),

  payout_percentage: Yup.object().shape({
    value: Yup.string().required("Payout Percentage is required"),
  }),
});
