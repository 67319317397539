import React, { Fragment } from "react";
import styled from "styled-components/macro";
import { Grid, Typography, Button, Paper } from "@mui/material";

import "./style.css";
import { useAxios } from "../../hooks";
import pages from "../../constants/pages";
import { useNavigate } from "react-router-dom";
import LOGO from "../../assets/Mox-3.svg";
import LOGO1 from "../../assets/verifyEmail/moxEmail.svg";

const ImageDiv = styled.div``;
const ImageDivOne = styled.div``;

export default function FormCard({ code }) {
  const axios = useAxios();
  const navigate = useNavigate();

  return (
    <Fragment>
      <Grid container spacing={2} p="10px" style={{ justifyContent: "center" }}>
        <Grid item xs={12}>
          <Grid item xs={12} sx={{ display: "flex" }}>
            <Grid item display={"flex"}>
              <ImageDiv>
                <img className="img1" src={LOGO} alt="!" />
              </ImageDiv>
            </Grid>
            <Grid item sx={{ flex: 1 }}>
              <Typography className="inviteTitle" alignSelf={"center"}>
                We're excited to see you!
              </Typography>
            </Grid>
          </Grid>

          <Paper>
            <Grid container spacing={2} p="15px">
              <Grid item xs={12} style={{ display: "flex" }}>
                <ImageDivOne style={{ marginTop: "10px" }}>
                  <img className="img2" src={LOGO1} alt="!" />
                </ImageDivOne>

                <Typography className="Explore">
                  Explore Engage Expand
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    size: "16px",
                    fontWeight: "700",
                    marginTop: "15px",
                  }}
                >
                  Congrats! Welcome to MOX Platform -
                </Typography>

                <Typography
                  style={{
                    size: "16px",
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  The Cross Promotion Platform that delivers results.
                </Typography>

                <Typography
                  style={{
                    size: "16px",
                    fontWeight: "400",
                    marginTop: "10px",
                  }}
                >
                  To verify your registration, pls click on the button below.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  p="20px"
                  style={{
                    cursor: "pointer",
                    marginTop: "20px",
                    justifyContent: "right",
                  }}
                  onClick={handleVerify}
                >
                  Verify Account
                </Button>
                <Typography
                  style={{
                    size: "16px",
                    fontWeight: "400",
                    marginTop: "20px",
                  }}
                >
                  If the button doesn't work, click this link below or copy and
                  paste it to your browser.
                </Typography>
                <Typography
                  style={{
                    size: "16px",
                    fontWeight: "600",
                    marginTop: "10px",
                  }}
                >
                  Have a great day ahead!
                </Typography>
                <Typography
                  style={{
                    size: "16px",
                    fontWeight: "600",
                    marginTop: "10px",
                  }}
                >
                  MOX Platform by Orbit Startups
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );

  function handleVerify() {
    axios({
      url: "/user/verifyEmail",
      method: "POST",
      data: {
        code: code,
      },
    }).then((res) => {
      if (res.success) {
        navigate(pages.signIn.route);
      }
    });
  }
}
