import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAxios } from "../../../hooks";
import CampaignForm from "../create campaign components/CreateCampaignForm";

export default function Form({
  audiences,
  formData,
  takeUpOffer,
  setTakeUpOffer,
  brandDetails,
  setBrandDetails,
  audienceData,
  component,
  icon,
  categories,
  location,
  languages,
  rejectOffer,
  setRejectOffer,
  brandID,
  campaignID,
  userID,
  activeBrand,
  connectionRealStats,
  setConnectionRealStats,
}) {
  const axios = useAxios();

  const { parentState } = useSelector((state) => state.memberDetails);

  const [liveUsers, setLiveUsers] = useState({
    liveUser: "",
    liveMessage: "",
    state: false,
  });
  const [loadingLiveUsers, setLoadingLiveUsers] = useState(false);

  useEffect(() => {
    if (formData.audienceID !== -1 && Boolean(formData.audienceID)) {
      setLoadingLiveUsers(true);
      axios({
        url: "/user/getUsersCount?v=1",
        method: "POST",
        disableRedirect: true,
        disableTimeOut: true,
        data: {
          sender_brandId: parseInt(brandID),
          sender_campaignId: parseInt(campaignID),
          receiver_userId: parseInt(userID),
          receiver_brandId: parseInt(activeBrand.ID),
          ...(Boolean(parentState === "SA") && { byAdmin: true }),
        },
      }).then((response) => {
        if (response.success) {
          setLiveUsers({
            state: true,
            liveMessage: response.message,
            liveUser: response?.usersCount,
          });
          setLoadingLiveUsers(false);
        }
      });
    }
  }, [
    axios,
    brandID,
    campaignID,
    userID,
    activeBrand.ID,
    parentState,
    formData.audienceID,
  ]);

  return (
    <Formik enableReinitialize initialValues={formData} validateOnMount>
      {(formikProps) => (
        <CampaignForm
          icon={icon}
          userData={{
            sender_brandId: parseInt(brandID),
            sender_campaignId: parseInt(campaignID),
            receiver_userId: parseInt(userID),
            receiver_brandId: parseInt(activeBrand.ID),
          }}
          editState={false}
          location={location}
          languages={languages}
          liveUsers={liveUsers}
          audiences={audiences}
          component={component}
          categories={categories}
          rejectOffer={rejectOffer}
          formikProps={formikProps}
          takeUpOffer={takeUpOffer}
          brandDetails={brandDetails}
          setLiveUsers={setLiveUsers}
          audienceData={audienceData}
          setTakeUpOffer={setTakeUpOffer}
          setRejectOffer={setRejectOffer}
          setBrandDetails={setBrandDetails}
          loadingLiveUsers={loadingLiveUsers}
          connectionRealStats={connectionRealStats}
          setLoadingLiveUsers={setLoadingLiveUsers}
          setConnectionRealStats={setConnectionRealStats}
        />
      )}
    </Formik>
  );
}
