import { Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  Checkbox,
} from "@mui/material";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import AddIcon from "@mui/icons-material/Add";
import { HighlightOff } from "@mui/icons-material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CloseIcon from "@mui/icons-material/Close";

import { useAlert } from "../../../hooks";
import AutocompleteMultiSelect from "./MultiAutoComplete";

const AdTagTable = styled(Typography)`
  border: 1px solid black;
  padding: 5px 10px;
`;

export default function Form({
  adTagData,
  formikProps,
  adSize,
  setShowDialog,
}) {
  const disabled = useRef(true);
  const { alert } = useAlert();

  const dispatch = useDispatch();

  const [check, setCheck] = useState(true);

  const [divId, setDivId] = useState([Math.floor(Math.random() * 9000 + 1000)]);

  let adTagScript = check
    ? `<script type="text/javascript" src="https://static.moxplatform.com/mox.js" >
      </script>
       <script type = "text/javascript" >
       function digestMessage(message) {
     if (!message) return Promise.resolve();
     var msgUint8 = new TextEncoder().encode(message);
     return crypto.subtle.digest("SHA-256", msgUint8).then(function(hashBuffer) {
         return Array.from(new Uint8Array(hashBuffer))
             .map(function(b) {
                 return b.toString(16).padStart(2, "0")
             }).join("");
     })
 }
var ados = ados || {};
digestMessage(emailOrPhone).then(function(hashedUserId) {
    ados.run = ados.run || [];
    ados.run.push(function() {
      ${adTagFunction()}    
      if (hashedUserId) ados_setUser(hashedUserId);
        ados_setDomain('promos.moxplatform.com')
        ados_load();
    });
}); </script>`
    : `<script type="text/javascript" src="https://static.moxplatform.com/mox.js" >
       </script> 
       <script type = "text/javascript" >
        var ados = ados || {};
        ados.run = ados.run || [];
        ados.run.push(function() {
         ${adTagFunction()}
    if ('userEmailHash') ados_setUser('userEmailHash');
    ados_setDomain('promos.moxplatform.com')
    ados_load();
}); </script>`;

  useEffect(() => {
    for (let div in formikProps.values) {
      if (div !== "zoneId") {
        if (formikProps.values[div].value.length > 0) {
          disabled.current = false;
        } else {
          disabled.current = true;
          return;
        }
      }
    }
  }, [formikProps.values]);

  function adTagFunction() {
    return divId
      .map((item, index) => {
        return `ados_add_placement(${
          adTagData?.values?.publisher?.networkId
        }, ${adTagData?.values?.publisher.siteId}, "mox${item}", [${
          formikProps?.values[`div${index}`]?.value
        }] )
      .setZone(${adTagData.values.publisher.zoneId}).setProperties({}); `;
      })
      .join("\n     ");
  }

  function handleClose(index) {
    let name = "div" + index;
    if (divId.length > 1) {
      const items = { ...formikProps.values };
      delete items[name];
      formikProps.setValues(items);

      setDivId((items) => {
        return items.filter((items, indx) => {
          return index !== indx;
        });
      });
    }
  }

  return (
    <DialogContent>
      <Grid container paddingBottom={4}>
        <Grid item xs={1} alignSelf={"center"}>
          <Typography variant="h4">AD-Tag</Typography>
        </Grid>

        <Grid item display={"flex"} lg={9} xs={8} alignSelf={"center"}>
          <AdTagTable variant="p">
            <span style={{ fontWeight: "bold" }}>Site ID</span> :{" "}
            {adTagData.values.publisher.siteId}
          </AdTagTable>

          <AdTagTable variant="p">
            <span style={{ fontWeight: "bold" }}>Zone ID</span> :{" "}
            {adTagData.values.publisher.zoneId}
          </AdTagTable>

          <AdTagTable variant="p">
            <span style={{ fontWeight: "bold" }}>Network ID</span> :{" "}
            {adTagData.values.publisher.networkId}
          </AdTagTable>
        </Grid>

        <Grid item lg={1} xs={2}>
          {/* <PrimaryButton
            variant="contained"
            onClick={() => formikProps.handleSubmit()}
          >
            Save
          </PrimaryButton> */}
        </Grid>

        <Grid item textAlign={"end"} xs={1}>
          <IconButton padding="0px" onClick={() => setShowDialog(false)}>
            <HighlightOff />
          </IconButton>
        </Grid>
      </Grid>

      <Grid item container xs={12} pb={3} display={"flex"}>
        {divId.map((items, index) => {
          return (
            <Grid
              item
              xs={5}
              key={index}
              display={"flex"}
              mt={2}
              sx={{
                border: "1px solid black",
                padding: "10px",
                borderRadius: "10px",
                marginRight: "15px",
              }}
            >
              {index > 0 && (
                <CloseIcon
                  sx={{
                    cursor: "pointer",
                    color: "red",
                    background: "pink",
                    borderRadius: "10px",
                    position: "relative",
                    top: "-20px",
                    left: "100%",
                  }}
                  onClick={() => handleClose(index)}
                ></CloseIcon>
              )}
              <Grid item xs={2} alignSelf={"center"}>
                <Typography variant="h4" sx={{ fontSize: "15px" }}>{`Div ID ${
                  index + 1
                } :`}</Typography>
              </Grid>
              <Grid item xs={3} alignSelf={"center"}>
                <Typography variant="h4" name={`div${index}.divID`}>
                  Mox{items}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <AutocompleteMultiSelect
                  size="small"
                  name={`div${index}.value`}
                  formikProps={formikProps}
                  defaults={{
                    primaryKey: "id",
                    displayFirstLabel: "name",
                    displaySecLabel: "width",
                    displayThirdLabel: "height",
                  }}
                  options={adSize}
                  placeholder="Select ad size"
                  onChange={(_, arr, r, option) =>
                    handleChange(arr, option, `div${index}.value`, "id", index)
                  }
                />
              </Grid>
            </Grid>
          );
        })}

        {divId.length < 3 && (
          <Grid item alignSelf={"center"}>
            <AddIcon
              sx={{
                cursor: "pointer",
                fontSize: "30px",
                color: "white",
                background: "red",
                borderRadius: "20px",
                marginLeft: "10px",
                marginTop: "10px",
              }}
              disabled={disabled.current}
              onClick={() => {
                let round = Math.floor(Math.random() * 9000 + 1000);
                if (divId.length <= 3) {
                  let name = "div" + divId.length;

                  formikProps.setValues((prev) => ({
                    ...prev,
                    [name]: { divID: "", value: [] },
                  }));
                }

                if (divId.length < 3) {
                  setDivId([...divId, round]);
                }
              }}
            ></AddIcon>
          </Grid>
        )}
      </Grid>

      <Grid container pb={4}>
        <Typography>
          <span style={{ fontWeight: "bold" }}>NOTE :</span>
          {` ${
            check
              ? "Replace emailOrPhone with the user's unique identifier, e.g. phone number or email address"
              : "Replace userEmailHash with the user's unique identifier, e.g. email address or phone number hashed using sha256, hex encoding."
          }`}
        </Typography>
        <br />
        <Typography>
          If possible, please download the ados library file found here&nbsp;
          <span>
            <a
              href="https://static.moxplatform.com/mox.js"
              target="_blank"
              rel="noreferrer"
            >
              https://static.moxplatform.com/mox.js
            </a>
          </span>
          &nbsp; and place on your webserver and adjust the path in the include.
          This helps in avoiding ad-blockers.
        </Typography>
      </Grid>

      <Grid
        p={5}
        border={"1px solid #00000066"}
        sx={{ height: "295px", overflow: "auto" }}
      >
        <Grid item display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h4">Add to the html head :</Typography>

          <Button
            variant="outlined"
            size="small"
            disabled={disabled.current}
            onClick={() => {
              navigator.clipboard.writeText(adTagScript);
              dispatch(
                alert({
                  message: "Copied AD-Tag Successfully!",
                  type: "success",
                })
              );
            }}
          >
            <FileCopyIcon fontSize="small" sx={{ mr: 2 }}></FileCopyIcon>
            Copy AD-Tag
          </Button>
        </Grid>

        <Typography style={{ whiteSpace: "pre" }}>{adTagScript}</Typography>
      </Grid>

      <Grid p={3} border={"1px solid #00000066"} mt={2}>
        <Grid item display={"flex"} justifyContent={"space-between"} mb={3}>
          <Typography variant="h5">Add to the HTML body :</Typography>

          <Button
            variant="outlined"
            size="small"
            disabled={disabled.current}
            onClick={() => {
              navigator.clipboard.writeText(
                (function () {
                  return divId.map((item) => `<div id="mox${item}"></div>`);
                })()
              );
              dispatch(
                alert({
                  message: "Copied HTML-Tag Successfully!",
                  type: "success",
                })
              );
            }}
          >
            <FileCopyIcon fontSize="small" sx={{ mr: 2 }}></FileCopyIcon>
            Copy HTML-Tag
          </Button>
        </Grid>

        {divId.map((item, index) => {
          return (
            <Typography key={index} style={{ whiteSpace: "pre-line" }}>
              {`<div id="mox${item}"></div>`}
            </Typography>
          );
        })}
      </Grid>

      <Grid item display={"flex"} alignItems={"center"}>
        <Grid item>
          <Checkbox checked={check} onChange={() => setCheck(!check)} />
        </Grid>

        <Grid item>
          <Typography variant="h4">Hash Mail</Typography>
        </Grid>
      </Grid>
    </DialogContent>
  );

  function handleChange(arr, { option }, name, displayName, index) {
    let objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => {
      return {
        ...prevVal,
        [objectName]: setValuesOfDropDown(prevVal[objectName], divId[index]),
      };
    });

    function setValuesOfDropDown(val, divid) {
      let values = [...val.value];

      const ind = values.indexOf(option[displayName]);
      if (ind > -1) {
        values.splice(ind, 1);
      } else {
        values.push(option[displayName]);
      }

      return {
        ...val,
        value: values,
        divID: divid,
      };
    }
  }
}
