import * as React from "react";
import Select from "react-select";
import { Grid } from "@mui/material";

export default function SortBy({ sortBy, setSortBy }) {
  let options = [{ value: "All", label: "All", color: "#000000" }];

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      height: "50px",
      p: "15px 18px",
      color: "#000000",
      fontWeight: 400,
      display: "flex",
      width: "inherit",
      fontsize: "18px",
      lineHeight: "20px",
      boxShadow: "none",
      alignItems: "center",
      borderRadius: "10px",
      justifyContent: "space-between",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: " #000000",
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      fontWeight: 600,
      color: data.color,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: data.color,
        fontWeight: isSelected ? 600 : 400,
        backgroundColor: "white",
        fontSize: "18px",
        lineHeight: "20px",
        mixBlendMode: "normal",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  const handleTypeSelect = (e) => {
    setSortBy(e.value);
  };

  return (
    <Grid item xs={3}>
      <Select
        styles={style}
        options={options}
        onChange={handleTypeSelect}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
        value={options.filter(function (option) {
          return option.value === sortBy;
        })}
      />
    </Grid>
  );
}
