import { Grid } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";

import SortBy from "./brand list components/Sort";
import Search from "./brand list components/Search";
import { Loader, PageHeader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import BrandList from "./brand list components/BrandList";
import Categorize from "./brand list components/Categorize";
import { NoListTypography } from "../css components/Style";

export default function SuperAdminBrand() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [sortBy, setSortBy] = useState("ALL");
  const [brands, setBrands] = useState("Brands");
  const [brandList, setBrandList] = useState([]);

  useEffect(() => {
    loader.start();
    axios({
      url: "/getAllBrands",
    }).then((response) => {
      if (response.success) {
        setBrandList(response.data);
        loader.apiComplete();
      }
    });
  }, [loader, axios]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader pageName={"All Brands"} heading={"Users"} />
        </Grid>

        <Categorize brands={brands} setBrands={setBrands} />

        <SortBy sortBy={sortBy} setSortBy={setSortBy} />

        {/* <Search /> */}

        <Grid item xs={12} />
      </Grid>

      <Loader height="75%">
        {brandList.length > 0 ? (
          <BrandList brandList={brandList} component={"users"} />
        ) : (
          <NoListTypography>No new brands!</NoListTypography>
        )}
      </Loader>
    </Fragment>
  );
}
