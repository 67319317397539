import { Grid } from "@mui/material";

import { Paper } from "../../../components";
import { ActiveTextEighteen, colors } from "../../css components/Style";

export default function AdminCampaignStatus({ formikProps }) {
  return (
    <Paper>
      {formikProps.values.campaignAdminStatus === 2 && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.error}>
            Campaign has been rejected by admin.
          </ActiveTextEighteen>
        </Grid>
      )}

      {formikProps.values.campaignAdminStatus === 0 && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.warning}>
            Campaign is in pending state
          </ActiveTextEighteen>
        </Grid>
      )}

      {formikProps.values.campaignAdminStatus === 1 && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.success}>
            Campaign has been accepted by admin.
          </ActiveTextEighteen>
        </Grid>
      )}
    </Paper>
  );
}
