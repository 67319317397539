import { useDispatch } from "react-redux";
import { Button, Grid, IconButton } from "@mui/material";
import { PhotoCamera, Upload } from "@mui/icons-material";

import { FileUpload, Label, Paper, TextField } from "../../../components";
import { useComponent } from "../../../hooks";

export default function FormOne({ formikProps }) {
  const { alert } = useComponent();

  const dispatch = useDispatch();

  function handleFileChange(event) {
    const file = event.target.files[0];

    const fileSize = Math.round(file.size / 1024);

    if (fileSize > 500) {
      return dispatch(
        alert.warning("File size too large! Make smaller (under 500 KB) ")
      );
    }

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      fileName: file.name,
      brandLogo: file,
    }));
  }

  return (
    <Paper>
      <Grid item xs={12}>
        <Label
          title={"Brand Logo"}
          formikProps={formikProps}
          required={true}
          name={"fileName"}
        />
      </Grid>

      <Grid item xs={10}>
        {/* <TextField
          required
          name="fileName"
          formikProps={formikProps}
          placeholder="Select brand Logo"
          inputProps={{ "aria-label": "Without label" }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton
                variant="contained"
                aria-label="upload picture"
                component="label"
              >
                <input
                  onChange={handleFileChange}
                  hidden
                  accept=".jpeg, .jpg, .png"
                  type="file"
                />
                <Upload />
              </IconButton>
            ),
          }}
        /> */}

        <FileUpload
          name="brandLogo"
          formikProps={formikProps}
          placeholder="Select brand Logo"
          allowedExtension=".jpeg,.jpg,.png"
          value={formikProps.values.brandLogo
            ?.split("/")
            .pop()
            .slice(
              formikProps.values?.brandLogo?.split("/").pop().indexOf("_") + 1
            )}
          inputProps={{ "aria-label": "Without label" }}
          folderPath={"mox/public/brand_logos/" + new Date().getTime() + "_"}
        />
      </Grid>

      <Grid item xs={12}>
        <Label
          title={"Brand Name"}
          formikProps={formikProps}
          required={true}
          name={"brandName"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="brandName"
          placeholder="Brand Name"
          formikProps={formikProps}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label
          title="About"
          formikProps={formikProps}
          required={true}
          name={"about"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          placeholder="About"
          name="about"
          rows={5}
          multiline
          formikProps={formikProps}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={6}>
        <Label
          title={"Website"}
          formikProps={formikProps}
          required={true}
          name={"website"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="website"
          placeholder="Website"
          formikProps={formikProps}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Online Store"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="onlineStore"
          placeholder="Online Store"
          formikProps={formikProps}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title={"Monthly Active Users"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="monthlyActiveUsers"
          placeholder="Monthly Active Users"
          formikProps={formikProps}
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={12}>
        <Label title="Social Media Handles" />
      </Grid>

      <Grid item xs={10}>
        <Label title={"Facebook"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          placeholder="Enter facebook username"
          formikProps={formikProps}
          name="socialMedia.facebook"
          inputProps={{ "aria-label": "Without label" }}
        />

        <Grid item xs={10}>
          <Label title={"Instagram"} />
        </Grid>
      </Grid>

      <Grid item xs={10}>
        <TextField
          placeholder="Enter instagram username"
          formikProps={formikProps}
          name="socialMedia.instagram"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={10}>
        <Label title={"Twitter"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          placeholder="Enter twitter username"
          formikProps={formikProps}
          name="socialMedia.twitter"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>

      <Grid item xs={10}>
        <Label title={"Whatsapp"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          placeholder="Enter whatsapp number with country code"
          formikProps={formikProps}
          name="socialMedia.whatsapp"
          inputProps={{ "aria-label": "Without label" }}
        />
      </Grid>
    </Paper>
  );
}
