/* Routes for sidebar menu
  For a single entry: {
    name: Entry Display Name,
    icon: Entry Icon,
    ID: dashboardRoutes.[route name].ID,
    path: dashboardRoutes.[route name].path,
  }

  For a folder: {
    name: Folder Display Name,
    icon: Folder Icon,
    ID: random ID for key for component in the loop,
    children: [{
      name: Sub entry name,
      path: dashboardRoutes.[route name].path,
    }],
  }
*/
import { BiBriefcase } from "react-icons/bi";
import { VerifiedOutlined } from "@mui/icons-material";

import Eye from "../../../assets/icons/Eyes.js";
import Grid from "../../../assets/icons/Grid.js";
import Profile from "../../../assets/icons/Profile.js";

import dashboardLayoutRoutes from "../../../router/router components/dashboardLayoutRoutes";

const routes = [
  {
    name: "Profile",
    icon: Profile,
    ID: dashboardLayoutRoutes.brandDetailsRoute.ID,
    path: dashboardLayoutRoutes.brandDetailsRoute.path,
  },

  {
    name: "Accounts",
    icon: VerifiedOutlined,
    ID: dashboardLayoutRoutes.accountVerificationRoute.ID,
    path: dashboardLayoutRoutes.accountVerificationRoute.path,
  },

  {
    name: "Campaigns",
    icon: Grid,
    ID: dashboardLayoutRoutes.superadminAllCampaignsRoute.ID,
    path: dashboardLayoutRoutes.superadminAllCampaignsRoute.path,
  },

  {
    name: "Campaigns",
    icon: Grid,
    ID: dashboardLayoutRoutes.myCampaignRoute.ID,
    path: dashboardLayoutRoutes.myCampaignRoute.path,
  },

  {
    name: "Campaigns",
    icon: Grid,
    ID: dashboardLayoutRoutes.campaignRequestRoute.ID,
    path: dashboardLayoutRoutes.campaignRequestRoute.path,
  },

  {
    name: "Audience",
    icon: Eye,
    ID: dashboardLayoutRoutes.audienceListRoute.ID,
    path: dashboardLayoutRoutes.audienceListRoute.path,
  },

  // {
  //   name: "Billing",
  //   icon: Eye,
  //   ID: dashboardLayoutRoutes.myBillingRoute.ID,
  //   path: dashboardLayoutRoutes.myBillingRoute.path,
  // },

  {
    name: "Users",
    icon: BiBriefcase,
    ID: dashboardLayoutRoutes.brandListRoute.ID,
    path: dashboardLayoutRoutes.brandListRoute.path,
  },

  {
    name: "On-Boarding",
    icon: BiBriefcase,
    ID: dashboardLayoutRoutes.superadminDPOnBoardingListRoute.ID,
    path: dashboardLayoutRoutes.superadminDPOnBoardingListRoute.path,
  },
];

export default routes;
