import { Fragment } from "react";
import { get, set } from "lodash";
import styled from "styled-components/macro";
import { Grid, MenuItem, Typography, Checkbox } from "@mui/material";
import { ToggleOnOutlined, ToggleOffOutlined } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import {
  Autocomplete,
  AutocompleteMultiSelect,
  Label,
  Paper,
  TextField,
} from "../../../components";

const MainHeading = styled(Typography)`
  color: #02a0fc;
  font-size: 20px;
  font-weight: 600;
`;

const Heading = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  align-self: center;
`;

const Menu = styled(MenuItem)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
`;

const Error = styled(Typography)`
  color: rgb(211, 47, 47);
  font-weight: 400;
  font-size: 13px;
  line-height: 27px;
`;

const DpCount = styled(Typography)`
  color: #333;
  font-weight: 400;
  font-size: 13px;
`;

export default function FormOne({
  fields,
  formikProps,
  editState,
  edit,
  mandatoryFalseCount,
  percentageSliderValue,
  count,
  oldMandatoryFalseCount,
  setOldMandatoryFalseCount,
  setPercentageSliderValue,
}) {
  function handleCheckChange(e) {
    let { name } = e.target;
    let objectName = name.substring(0, name.indexOf("."));

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValues(prevVal[objectName]),
      percentage: setPercentage(prevVal.percentage),
    }));

    function setPercentage(percentage) {
      let p = percentage.value;

      if (
        mandatoryFalseCount <= percentageSliderValue &&
        Boolean(formikProps.values.ID)
      ) {
        if (p === percentageSliderValue && p === mandatoryFalseCount) {
          count.current = false;
        }

        if (!e.target.checked) {
          setPercentageSliderValue(mandatoryFalseCount);
          p = mandatoryFalseCount;
        } else {
          p = p - 1;
        }
      }

      if (
        mandatoryFalseCount < oldMandatoryFalseCount &&
        oldMandatoryFalseCount === percentageSliderValue &&
        !Boolean(formikProps.values.ID)
      ) {
        setPercentageSliderValue(mandatoryFalseCount);
        p = mandatoryFalseCount;
      }

      setOldMandatoryFalseCount(mandatoryFalseCount);

      return {
        ...percentage,
        value: p,
      };
    }

    function setValues(val) {
      return {
        ...val,
        mandatory: e.target.checked,
      };
    }
  }

  function handleTextChange(event) {
    const {
      target: { value, name },
    } = event;
    let objectName = name.substring(0, name.indexOf("."));
    const name1 = name.split(".");

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValues(prevVal[objectName]),
      dirty: true,
      // percentage: setPercentage(prevVal.percentage),
    }));

    function setValues(val) {
      if (typeof name[name1] === "string") {
        val.mandatory = true;
        val.value = value;
      } else {
        set(val, name.substring(name.indexOf(".") + 1), value);
        if (!val.value.min) {
          val.mandatory = false;
        }
      }

      return val;
    }
  }
  return (
    <Paper>
      <Grid item xs={12}>
        <MainHeading>Audience Group Details </MainHeading>
      </Grid>

      {!edit && (
        <Grid item xs={12}>
          <Error>
            Fill any one of the audience preferences and make at least one
            filled value as mandatory
          </Error>
        </Grid>
      )}

      <Grid item xs={10}>
        <Label
          title={"Audience Group Name"}
          formikProps={formikProps}
          required={true}
          name={"audience_group.value"}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          formikProps={formikProps}
          name="audience_group.value"
          placeholder="Audience Group Name"
          inputProps={{ "aria-label": "Without label" }}
          disabled={!editState}
        />
      </Grid>

      {fields.map(
        (field, index) =>
          index % 2 === 0 && (
            <Fragment>
              <Grid item xs={6}>
                <Label
                  title={field.label}
                  formikProps={formikProps}
                  name={field.name}
                />
              </Grid>

              <Grid
                item
                xs={4}
                textAlign="end"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <Heading
                  style={{
                    color: formikProps.values[field.name]?.mandatory
                      ? "red"
                      : "gray",
                  }}
                >
                  Mandatory
                </Heading>

                <Checkbox
                  disabled={
                    field.control_id === 1
                      ? !formikProps.values[field.name]?.value.min ||
                        !formikProps.values[field.name]?.value.max ||
                        !editState
                      : !editState ||
                        !formikProps.values[field.name]?.value ||
                        formikProps.values[field.name]?.value.length === 0
                  }
                  name={`${field.name}.mandatory`}
                  onChange={handleCheckChange}
                  checked={formikProps.values[field.name]?.mandatory}
                  icon={<ToggleOffOutlined fontSize="large" />}
                  checkedIcon={
                    <ToggleOnOutlined
                      fontSize="large"
                      style={{ color: "red" }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <DpCount>
                  {formikProps.values[field.name]?.userCount > 0 &&
                    `${formikProps.values[field.name]?.userCount.toFixed(
                      2
                    )} Million Users`}
                </DpCount>
              </Grid>

              {/* textfield */}

              {field.control_id === 3 && (
                <Grid item xs={10}>
                  <TextField
                    formikProps={formikProps}
                    name={`${field.name}.value`}
                    placeholder={field.label}
                    inputProps={{ "aria-label": "Without label" }}
                    disabled={!editState}
                  />
                </Grid>
              )}

              {field.control_id === 1 && (
                <Fragment>
                  <Grid item xs={5}>
                    <TextField
                      formikProps={formikProps}
                      onChange={handleTextChange}
                      name={`${field.name}.value.${field.options[0]["value"]}`}
                      placeholder={field.options[0].label}
                      inputProps={{ "aria-label": "Without label" }}
                      disabled={!editState}
                      // value={
                      //   formikProps.values[field?.name]?.value[
                      //     `${field?.options[0]?.value}`
                      //   ]
                      // }
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <TextField
                      formikProps={formikProps}
                      onChange={handleTextChange}
                      name={`${field.name}.value.${field.options[1]["value"]}`}
                      placeholder={field.options[1].label}
                      inputProps={{ "aria-label": "Without label" }}
                      disabled={!editState}
                      // value={
                      //   formikProps.values[field.name]?.value[
                      //     `${field?.options[1]?.value}`
                      //   ]
                      // }
                    />
                  </Grid>
                </Fragment>
              )}

              {field.control_id === 2 && field.multi_select && (
                <Grid item xs={10}>
                  <AutocompleteMultiSelect
                    forcePopupIcon={!editState ? false : true}
                    name={`${field.name}.value`}
                    formikProps={formikProps}
                    disabled={!editState}
                    defaults={{
                      primaryKey: "value",
                      displayLabel: "label",
                    }}
                    options={field.options}
                    placeholder={field.placeholder}
                    onChange={(_, arr, reason, detail) =>
                      handleChange(
                        arr,
                        detail,
                        reason,
                        `${field.name}.value`,
                        "value",
                        field.options
                      )
                    }
                  />
                </Grid>
              )}

              {field.control_id === 2 && !field.multi_select && (
                <Grid item xs={10}>
                  <Autocomplete
                    forcePopupIcon={!editState ? false : true}
                    name={`${field.name}.value`}
                    placeholder={field.placeholder}
                    defaults={{
                      primaryKey: "value",
                      displayLabel: "label",
                    }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        display: !editState && "none",
                      },
                    }}
                    options={field.options}
                    disabled={!editState}
                    onChange={(_, value, type) =>
                      handleChangeSingleSelect(value, type, `${field.name}`)
                    }
                    formikProps={formikProps}
                  />
                </Grid>
              )}

              {field.control_id === 4 && (
                <Grid item xs={10}>
                  <DesktopDatePicker
                    name={`${field.name}.value`}
                    formikProps={formikProps}
                    disabled={!editState}
                    inputProps={{ "aria-label": "Without label" }}
                    value={formikProps.values[field.name]?.value}
                    onChange={(newValue) => {
                      let date = new Date(newValue);

                      if ((date.getFullYear() + "").length < 3) {
                        return formikProps.setValues((prevVal) => ({
                          ...prevVal,
                          [field.name]: {
                            mandatory: false,
                            userCount: 0,
                            value: null,
                          },
                        }));
                      }

                      let d =
                        date.getFullYear() +
                        "-" +
                        "0" +
                        (date.getMonth() + 1) +
                        "-" +
                        date.getDate();

                      formikProps.setValues((prevVal) => ({
                        ...prevVal,
                        [field.name]: {
                          ...prevVal[field.name],
                          value: d,
                        },
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        disabled={!editState}
                        name={`${field.name}.value`}
                        formikProps={formikProps}
                        {...params}
                      />
                    )}
                  />
                </Grid>
              )}
            </Fragment>
          )
      )}
    </Paper>
  );

  function handleChangeSingleSelect(value, type, name) {
    function setValue(val) {
      val = {
        ...val,
        value: value.value,
      };

      return val;
    }
    if (type === "selectOption") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        [name]: setValue(prevVal[name]),
      }));
    } else {
      value = {
        value: "",
        label: "",
      };

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        [name]: setValue(prevVal[name]),
      }));
    }
  }

  function handleChange(arr, { option }, reason, name, displayName, options) {
    let objectName = name.substring(0, name.indexOf("."));
    formikProps.setValues((prevVal) => ({
      ...prevVal,
      [objectName]: setValuesOfDropDown(prevVal[objectName]),
    }));

    function setValuesOfDropDown(val) {
      let values = [...val.value];
      let count = val.userCount;
      let mandatory = val.mandatory;

      if (reason === "removeOption") {
        if (option.value) {
          values = [...values.filter((items) => items !== option?.value)];
        } else {
          values?.pop();
          option = options.filter((item) => item?.label === option)[0];
        }

        count = count - option?.count;
      }
      if (reason === "selectOption") {
        values.push(option[displayName]);
        count = count + option.count;
      }

      if (values.length === 0) {
        mandatory = false;
      }
      return {
        ...val,
        value: values,
        userCount: count,
        mandatory: mandatory,
      };
    }
  }
}
