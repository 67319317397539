import { Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FormDisplay from "./FormDisplay";
import pages from "../../../constants/pages";
import { useAxios, useAlert } from "../../../hooks";
import {
  createBrandObject,
  objectFromFormData,
  validationSchema,
} from "./createBrandObject";
import { setActiveBrand, setBrands } from "../../../redux/slices/memberSlice";

export default function Form({ categories, location, languages }) {
  const axios = useAxios();

  const { alert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const memberDetails = useSelector((state) => state.memberDetails);

  return (
    <Formik
      enableReinitialize
      initialValues={createBrandObject}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={(formData, { setSubmitting }) => {
        setLoading(true);

        axios({
          url: "/user/brand",
          method: "POST",
          data: objectFromFormData(formData, memberDetails),
          disableRedirect: true,
        })
          .then((res) => {
            if (res.success) {
              dispatch(
                alert({
                  message: "Brand created successfully!",
                  type: "success",
                })
              );
              sessionStorage.setItem("activeBrand", res.data.brand_id);
              localStorage.setItem(
                "dashboardState" + res.data.brand_id,
                "monetization"
              );

              dispatch(setBrands(res.data));
              dispatch(setActiveBrand(res.data));
              navigate(pages.brandDetails.route);

              setLoading(false);
            } else {
              setLoading(false);
            }
            setSubmitting(false);
          })
          .catch((err) => {
            setLoading(false);

            console.error(err);
          });
      }}
    >
      {(formikProps) => (
        <form style={{ padding: "20px" }} onSubmit={formikProps.handleSubmit}>
          <FormDisplay
            formikProps={formikProps}
            categories={categories}
            location={location}
            languages={languages}
            loading={loading}
          />
        </form>
      )}
    </Formik>
  );
}
