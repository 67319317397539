export default function Logout() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66663 20.5055C9.98413 21.2661 11.4786 21.6666 13 21.6666C14.5213 21.6666 16.0158 21.2661 17.3333 20.5055C18.6508 19.7448 19.7449 18.6508 20.5055 17.3333C21.2662 16.0158 21.6666 14.5212 21.6666 12.9999C21.6666 11.4786 21.2662 9.98409 20.5055 8.66659C19.7449 7.34909 18.6508 6.25502 17.3333 5.49437C16.0158 4.73371 14.5213 4.33325 13 4.33325C11.4786 4.33325 9.98413 4.73371 8.66663 5.49436"
        stroke="black"
        stroke-width="2.16667"
      />
      <path
        d="M2.16663 12.9999L1.32068 12.3232L0.779282 12.9999L1.32068 13.6767L2.16663 12.9999ZM11.9166 14.0833C12.5149 14.0833 13 13.5982 13 12.9999C13 12.4016 12.5149 11.9166 11.9166 11.9166V14.0833ZM5.65402 6.9065L1.32068 12.3232L3.01257 13.6767L7.3459 8.26L5.65402 6.9065ZM1.32068 13.6767L5.65402 19.0933L7.3459 17.7398L3.01257 12.3232L1.32068 13.6767ZM2.16663 14.0833H11.9166V11.9166H2.16663V14.0833Z"
        fill="black"
      />
    </svg>
  );
}
