import React, { Fragment } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  Button,
} from "@mui/material";
import { HighlightOff, FileCopy } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import { useComponent } from "../../../hooks";
import pages from "../../../constants/pages";
import ShowADTag from "../show adtech/showAdTag";
import campaign from "../../../assets/campaign.svg";
import audience from "../../../assets/audience.svg";
import { Label, Paper, TextField } from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;s
  line-height: 33px;
  color: #02a0fc;
`;

export default function FormOne({ formikProps, showDialog, setShowDialog }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { alert } = useComponent();

  const { dashboardState } = useSelector((state) => state.memberDetails);

  const javascriptCode = `
<script type="text/javascript" src="https://static.adzerk.net/ados.js"></script>
<script type="text/javascript">
  var ados = ados || {};
  ados.run = ados.run || [];
  ados.run.push(function() {
    ados_add_placement(11396, ${formikProps.values?.publisher?.siteId}, "mox<divId>", ${formikProps.values?.advertiserId} )
      .setZone(${formikProps.values?.publisher?.zoneId});
    ados_setUser('<emailHash>');
    ados_setDomain('promos.moxplatform.com')
    ados_load();
  });
</script>
`;

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <MainHeading>Your Personal Profile</MainHeading>
        </Grid>

        {/* <Grid item xs={12} textAlign="center" display="flex">
          <Image src={formikProps.values.profile} />
        </Grid> */}

        <Grid item xs={12}>
          <Label title={"Display Name"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            name="displayName"
            placeholder="Display Name"
            disabled
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Given Name"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled
            name="givenName"
            placeholder="Given Name"
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Family Name"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled
            name="familyName"
            placeholder="Family Name"
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Email"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled
            name="email"
            placeholder="Email"
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        {/* <Grid item xs={12}>
          <Label title={"Password"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            type="password"
            disabled
            name="password"
            placeholder="Password"
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid> */}

        <Grid item xs={12}>
          <Label title={"Role"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            placeholder="Role"
            disabled
            name="role"
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            color="#02a0fc"
            variant="caption1"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(pages.changePassword.route)}
          >
            Change Password
          </Typography>
        </Grid>
      </Paper>

      {dashboardState === "monetization" && (
        <Fragment>
          <Grid item xs={12} margin="30px 0">
            <img
              src={audience}
              style={{ width: "100%", cursor: "pointer" }}
              alt="!"
              onClick={() => navigate(pages.createAudience.route)}
            />
          </Grid>

          <Grid item xs={12} margin="30px 0">
            <img
              src={campaign}
              style={{ width: "100%", cursor: "pointer" }}
              alt="!"
              onClick={() => navigate(pages.createCampaign.route)}
            />
          </Grid>
        </Fragment>
      )}

      <ShowADTag
        adTagData={formikProps}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
    </React.Fragment>
  );
}
