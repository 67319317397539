import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, MenuItem } from "@mui/material";

import pages from "../../../constants/pages";
import { Label, Paper, TextField } from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const Menu = styled(MenuItem)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
`;

export default function FormOne({ formikProps, editState, brands }) {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Paper>
        <Grid item xs={12}>
          <MainHeading>Brand Affiliation</MainHeading>
        </Grid>

        <Grid item xs={12}>
          <Label title={"Brand"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            select
            formikProps={formikProps}
            name="brandID"
            disabled={!editState}
            sx={{
              "& .MuiSvgIcon-root": {
                display: !editState && "none",
              },
            }}
            SelectProps={{
              displayEmpty: true,
            }}
          >
            <Menu value="" disabled>
              Select Brand
            </Menu>
            {brands.map((brand) => (
              <Menu key={brand.brand_id} value={brand.brand_id}>
                {brand.brand_name}
              </Menu>
            ))}
          </TextField>
        </Grid>
      </Paper>

      <Paper>
        <Grid item xs={12}>
          <MainHeading>Add New User</MainHeading>
        </Grid>

        <Grid item xs={12}>
          <Label title={"Given Name"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled={!editState}
            name="givenName"
            placeholder="Given Name"
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Family Name"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled={!editState}
            name="familyName"
            placeholder="Family Name"
            formikProps={formikProps}
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Email"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            disabled={!editState}
            name="email"
            placeholder="Email"
            formikProps={formikProps}
          />
        </Grid>

        <Grid item xs={12}>
          <Label title={"Password"} />
        </Grid>

        <Grid item xs={10}>
          <TextField
            type="password"
            disabled={!editState}
            name="password"
            placeholder="********"
            inputProps={{ "aria-label": "Without label" }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            color="#02a0fc"
            variant="caption1"
            cursor="pointer"
            onClick={() => navigate(pages.changePassword.route)}
          >
            Change Password
          </Typography>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
