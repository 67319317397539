/*
Contains all the routes pertaining to Dashboard Layout
Use the following structure: 
export const {name}Route = {
  ID: "",
  path: "/",
  element: "",
  index: true, (if the path is default for some folder)
}
*/

//Misc Pages
import pages from "../../constants/pages";
import Page404 from "../../pages/misc/Page404";
import CreateBrand from "../../pages/brand components/CreateBrand";
import Welcome from "../../pages/welcome page components/Welcome";
import PermissionError from "../../pages/misc/PermissionError";
import AdminLandingPage from "../../pages/welcome page components/AdminWelcome";

//Test Page

const emptyLayoutRoutes = {
  //Error 404
  error404Route: {
    ID: "error404",
    path: "*",
    element: Page404,
  },

  //permission

  permissionRoute: {
    ID: pages.permissionDenied.ID,
    path: pages.permissionDenied.route,
    element: PermissionError,
  },
  //welcome page

  welcomePageRoute: {
    ID: pages.welcomePage.ID,
    path: pages.welcomePage.route,
    element: Welcome,
    role: ["monetization", "promotion"],
  },

  superadminLandingPageRoute: {
    ID: pages.superadminLandingPage.ID,
    path: pages.superadminLandingPage.route,
    element: AdminLandingPage,
  },

  createBrandRoute: {
    ID: pages.brandCreate.ID,
    path: pages.brandCreate.route,
    element: CreateBrand,
    role: ["monetization", "promotion"],
  },
};

export default emptyLayoutRoutes;
