import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { Paper } from "../../../components";
import { ActiveTextEighteen, colors } from "../../css components/Style";

export default function CampaignStatus({ formikProps, setTakeUpOffer }) {
  const { activeBrand } = useSelector((state) => state.memberDetails);

  return (
    <Paper>
      {Boolean(
        formikProps.values.campaignStatus === "REJECTED" &&
          formikProps.values.campaignOverAllStatus !== "ADMIN_REJECTED"
      ) && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.error}>
            Campaign Offer was rejected.
          </ActiveTextEighteen>

          <div style={{ display: "flex" }}>
            <ActiveTextEighteen>Change of mind? &nbsp; </ActiveTextEighteen>
            <ActiveTextEighteen
              color={colors.success}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setTakeUpOffer((prevVal) => ({
                  ...prevVal,
                  action: "ACCEPTED",
                  state: true,
                }))
              }
            >
              Accept Now.
            </ActiveTextEighteen>
          </div>
        </Grid>
      )}
      {Boolean(
        formikProps.values.campaignStatus === "PENDING" &&
          formikProps.values.campaignOverAllStatus !== "ADMIN_REJECTED"
      ) && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.warning}>
            Campaign is in pending state, Take action.
          </ActiveTextEighteen>
        </Grid>
      )}
      {Boolean(
        formikProps.values.campaignStatus === "ACCEPTED" ||
          formikProps.values.campaignStatus === "OFFER_ACCEPTED"
      ) &&
        formikProps.values.campaignOverAllStatus !== "ADMIN_REJECTED" && (
          <Grid item xs={12}>
            <ActiveTextEighteen color={colors.success}>
              Campaign Offer was accepted.
            </ActiveTextEighteen>

            {Boolean(
              !formikProps.values.connections.find(
                (item) =>
                  item.receiver_brand_info.brand_name == activeBrand.brandName
              ).is_accepted_by_admin
            ) && (
              <div style={{ display: "flex" }}>
                <ActiveTextEighteen>Change of mind? &nbsp; </ActiveTextEighteen>
                <ActiveTextEighteen
                  color={colors.error}
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setTakeUpOffer((prevVal) => ({
                      ...prevVal,
                      action: "REJECTED",
                      state: true,
                    }))
                  }
                >
                  Reject Now.
                </ActiveTextEighteen>
              </div>
            )}
          </Grid>
        )}
      {formikProps.values.campaignOverAllStatus === "ADMIN_REJECTED" && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.error}>
            Campaign rejected by admin.
          </ActiveTextEighteen>
        </Grid>
      )}
      {Boolean(
        formikProps.values.campaignStatus === "PROOF_GENERATION_FAILED" &&
          formikProps.values.campaignOverAllStatus !== "ADMIN_REJECTED"
      ) && (
        <Grid item xs={12}>
          <ActiveTextEighteen color={colors.error}>
            Campaign proof generation failed.
          </ActiveTextEighteen>

          <div style={{ display: "flex" }}>
            <ActiveTextEighteen>Accept Offer again. &nbsp; </ActiveTextEighteen>
            <ActiveTextEighteen
              color={colors.success}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setTakeUpOffer((prevVal) => ({
                  ...prevVal,
                  action: "ACCEPTED",
                  state: true,
                }))
              }
            >
              Accept Now.
            </ActiveTextEighteen>
          </div>
        </Grid>
      )}
    </Paper>
  );
}
