import { Formik } from "formik";
import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";

import Form from "./Form";
import { useAxios } from "../../../hooks";

export default function ShowADTag({ adTagData, showDialog, setShowDialog }) {
  const axios = useAxios();

  const [adSize, setAdSize] = useState([]);

  const [formData, setformData] = useState({
    zoneId: adTagData.values.publisher.zoneId,
    div0: {
      divID: "",
      value: [],
    },
  });

  useEffect(() => {
    axios({
      url: "/adserver/listAdTypeForChannel",
    })
      .then((response) => {
        if (response.success) {
          setAdSize(response.adTypes.items);
        }
      })
      .catch((error) => {});
  }, [axios]);

  function handleClose() {
    setShowDialog(!showDialog);
  }

  return (
    <Dialog open={showDialog} onClose={handleClose} fullWidth maxWidth="lg">
      <Formik initialValues={formData} onSubmit={(formData) => {}}>
        {(formikProps) => (
          <Form
            formikProps={formikProps}
            adTagData={adTagData}
            adSize={adSize}
            setShowDialog={setShowDialog}
          ></Form>
        )}
      </Formik>
    </Dialog>
  );
}
