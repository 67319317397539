import axios from "axios";

import BASE_URL from "../../../constants/baseURL";

export default async function signIn({ authToken, userID, businessEntityId }) {
  const role = sessionStorage.getItem("role");
  const parentState = sessionStorage.getItem("ps");
  const activeBrand = sessionStorage.getItem("activeBrand");
  const dashboardState = localStorage.getItem("dashboardState" + activeBrand);
  sessionStorage.setItem("userID", parseInt(userID));
  sessionStorage.setItem("authToken", authToken);
  sessionStorage.setItem("businessEntityId", businessEntityId);

  let permissions = {
    promotion: [
      { ID: "dp-campaign", page: "Campaign DP" },
      { ID: "brand-details", page: "Brand Details" },
      { ID: "brand-create-new", page: "Create New Brand" },
    ],
    monetization: [
      { ID: "my-billings", page: "My Billing" },
      { ID: "my-campaign", page: "Campaign List" },
      { ID: "brand-details", page: "Brand Details" },
      { ID: "audience-list", page: "Audience List" },
      { ID: "brand-create-new", page: "Create New Brand" },
    ],
    SUPER_ADMIN: [
      { ID: "all-boarding", page: "All DP" },
      { ID: "dp-on-boarding", page: "DP Onboarding" },
      { ID: "all-campaign", page: "Campaign List" },
      { ID: "administrator-brands", page: "Brand-list" },
      { ID: "users-list", pages: "Users List" },
      { ID: "verify-account", page: "Verify Accounts" },
    ],
  };

  let userData = {};
  let brands = [];

  const axiosInstance = axios.create({
    baseURL: BASE_URL.mox_api.url + BASE_URL.mox_api.version,
    headers: {
      Authorization: authToken,
      "Content-Type": "application/json",
    },
  });

  if (role === "NORMAL_USER" && businessEntityId && parentState !== "SA") {
    const brandsData = await axiosInstance({
      url: "/brand?businessEntityId=" + businessEntityId,
      method: "GET",
    }).then((res) => res.data.data);

    brands = getBrands(brandsData);

    if (brands.length > 0) {
      userData = await axiosInstance({
        url: "/user/info?userId=" + userID,
        method: "GET",
      }).then((res) => res.data.data);

      if (!activeBrand) {
        sessionStorage.setItem("activeBrand", brands[0].ID);
      }
    }
  } else if (parentState === "SA") {
    const brandsData = await axiosInstance({
      url: "/getAllBrands",
      method: "GET",
    }).then((res) => res.data.data);

    brands = getBrands(brandsData);
  }

  const categories = await axiosInstance({
    url: "/brand/getAllBusinessCategories",
    method: "GET",
  }).then((res) => res.data.data);

  const locations = await axiosInstance({
    url: "/getAllCountries",
    method: "GET",
  }).then((res) => res.data.data);

  const languages = await axiosInstance({
    url: "/getAllLanguages",
    method: "GET",
  }).then((res) => res.data.data);

  return {
    brands: brands,
    location: locations,
    authToken: authToken,
    languages: languages,
    categories: categories,
    userID: parseInt(userID),
    email: userData.user?.email || "",
    role: role || "",
    parentState: parentState,
    givenName: userData.user?.given_name || "",
    familyName: userData.user?.family_name || "",
    displayName: userData.user?.display_name || "",
    dashboardState:
      role === "NORMAL_USER"
        ? brands.length > 0
          ? dashboardState
          : "monetization"
        : role,
    dashboardPermissions:
      role === "NORMAL_USER"
        ? brands.length > 0
          ? dashboardState
            ? permissions[dashboardState]
            : permissions.monetization
          : permissions.monetization
        : permissions[role],
    businessEntityID: userData.brand?.business_entity_id || businessEntityId,
    activeBrand:
      role !== "SUPER_ADMIN"
        ? !!brands.find((brand) => parseInt(brand.ID) === parseInt(activeBrand))
          ? brands.find((brand) => parseInt(brand.ID) === parseInt(activeBrand))
          : brands.length > 0
          ? brands[0]
          : {
              ID: "",
              about: "",
              brandName: "",
              logo: "",
              languages: [],
              locations: [],
              category: [],
              mau: "",
              onlineStore: "",
              website: "",
              socialMedia: {},
              brandType: "",
              bEntityID: "",
            }
        : {
            ID: "",
            about: "",
            brandName: "",
            logo: "",
            languages: [],
            locations: [],
            category: [],
            mau: "",
            onlineStore: "",
            website: "",
            socialMedia: {},
            brandType: "",
            bEntityID: "",
          },

    brandType: !!brands.find(
      (brand) => parseInt(brand.ID) === parseInt(activeBrand)
    )
      ? brands.find((brand) => parseInt(brand.ID) === parseInt(activeBrand))
          .brandType
      : brands.length > 0
      ? brands[0].brandType
      : "",
  };

  function getBrands(brands, permissions) {
    var brandList = [];

    for (const brand of brands) {
      brandList.push({
        ID: brand.brand_id,
        about: brand.about,
        brandName: brand.brand_name,
        logo: brand.logo,
        languages: brand.languages,
        locations: brand.audience_location,
        category: brand.site_category,
        mau: brand.mau,
        onlineStore: brand.online_store,
        website: brand.website,
        socialMedia: brand.social_media_handles,
        brandType: brand.brand_type_id,
        bEntityID: brand.business_entity_id,
      });
    }

    return brandList;
  }
}
