import * as Yup from "yup";

export const connectionObject = {
  ID: "",
  brandName: "",
  website: "",
  profile: "",
  audienceLocation: [],
  audienceLanguages: [],
  socialMedia: {
    facebook: "",
    instagram: "",
    twitter: "",
    whatsapp: "",
  },
  brandLogo: "",
  about: "",
  onlineStore: "",
  siteCategory: "",
  monthlyActiveUsers: "",
};

export function objectFromResponse(response) {
  let data = {};

  for (let key of response.fields) {
    data[Object.keys(key)[0]] = {
      value: Object.values(key)[0],
      mandatory: Object.values(key)[1],
    };
  }

  return {
    ...data,
    ID: response.audience_id,
    brandID: response.brand_id,
    isEditable: response.is_editable,
  };
}

export function objectFromFormData(formData, details) {
  return {
    brand_id: parseInt(details.activeBrand.ID),
    fields: [
      {
        audience_group: formData.audience_group,
      },
      {
        age_range_min: formData.age_range_min,
      },
      {
        age_range_max: formData.age_range_max,
      },
      {
        gender: formData.gender,
      },
      {
        arpu: formData.arpu,
      },
      {
        audience_location: formData.audience_location,
      },
      {
        languages: formData.languages,
      },
      {
        percentage: formData.percentage,
      },
    ],
  };
}

export const validationSchema = Yup.object()
  .shape({
    audience_group: Yup.object().shape({
      value: Yup.string().required("Audience Group is required"),
    }),

    age_range_min: Yup.object().shape({
      value: Yup.string(),
    }),

    age_range_max: Yup.object().shape({
      value: Yup.string(),
    }),

    gender: Yup.object().shape({
      value: Yup.array(),
    }),

    approx_mau: Yup.object().shape({
      value: Yup.string(),
    }),

    arpu: Yup.object().shape({
      value: Yup.string(),
    }),

    audience_location: Yup.object().shape({
      value: Yup.array(),
    }),

    languages: Yup.object().shape({
      value: Yup.array(),
    }),
  })
  .test("at-least-one-property", "you must provide at least one", (value) => {
    if (
      !value.age_range_max ||
      !value.age_range_min ||
      !value.approx_mau ||
      !value.arpu ||
      !value.audience_group ||
      !value.audience_location
    ) {
      return "atlaest one required";
    }
  });
