export default function Home() {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="black"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.3333 19.6535H17.5725L17.5833 10.5795L9.99783 2.78812L2.42317 10.535L2.41667 19.6535H5.66667V12.0701C5.66667 11.471 6.15092 10.9868 6.75 10.9868H13.25C13.848 10.9868 14.3333 11.471 14.3333 12.0701V19.6535ZM12.1667 19.6535H7.83333V13.1535H12.1667V19.6535ZM19.126 9.02056L10.7746 0.479561C10.3662 0.0624772 9.63383 0.0624772 9.22542 0.479561L0.872917 9.02164C0.4775 9.42789 0.25 9.99556 0.25 10.5795V19.6535C0.25 20.8484 1.16758 21.8201 2.29533 21.8201H6.75H13.25H17.7036C18.8313 21.8201 19.75 20.8484 19.75 19.6535V10.5795C19.75 9.99556 19.5225 9.42789 19.126 9.02056Z"
      />
    </svg>
  );
}
