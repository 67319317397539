import React, { useState } from "react";
import styled from "styled-components/macro";
import { HighlightOff, Sync } from "@mui/icons-material";
import {
  DialogContent,
  Dialog,
  Grid,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";

import { Label } from "../../../../components";
import { colors } from "../../../css components/Style";
import { useAxios } from "../../../../hooks";
import { toInternationalFormat } from "../../../../utils";
import { useDispatch } from "react-redux";
import { alert } from "../../../../redux/slices/alertSlice";

const Card = styled(Dialog)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

export default function ConnectionDetailsDilog({
  details,
  setDetails,
  setRetry,
  setConnections,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();

  const [apiStart, setApiStart] = useState(false);

  const handleClose = () => {
    if (details.component === "connection") {
      setDetails((prev) => ({
        state: false,
        data: {},
      }));
    } else {
      setDetails((prev) => ({ ...prev, state: false }));
    }
  };

  function handleRefresh() {
    setApiStart(true);
    axios({
      url:
        "/user/brand/getCampaign?brandId=" +
        parseInt(details.data.brandID) +
        "&campaignId=" +
        parseInt(details.data.campaignID),
      disableRedirect: true,
    }).then((response) => {
      if (response.success) {
        for (let con of response.data.connections) {
          if (
            parseInt(details.data.senderBrandId) ===
              parseInt(con.sender_brandId) &&
            parseInt(details.data.receiverBrandId) ===
              parseInt(con.receiver_brandId) &&
            parseInt(details.data.senderCampaignId) ===
              parseInt(con.sender_campaignId)
          ) {
            setDetails({
              ...details,
              data: {
                brandID: details.data.brandID,
                campaignID: details.data.campaignID,
                campaignName: details.data.campaignName,
                requestStatus: con.requestStatus?.replace(/_/g, " "),
                logo: con.receiver_brand_info?.logo,
                mau: toInternationalFormat(con.receiver_brand_info?.mau) || "",
                brandId: con.receiver_brand_info?.brandId,
                category: con.receiver_brand_info?.category || [],
                languages: con.receiver_brand_info?.languages || [],
                locations: con.receiver_brand_info?.location || [],
                name: con.receiver_brand_info?.brand_name || "",
                expectedCount:
                  con.total_users_count &&
                  toInternationalFormat(con.total_users_count),
                usersCount:
                  con.users_count && toInternationalFormat(con.users_count),
                totalUsersCount: con.total_users_count,
                dbStatus: con.userDbStatus || "",
                pidStatus: con.status || "",
                senderUserId: con.sender_userId,
                senderBrandId: con.sender_brandId,
                senderCampaignId: con.sender_campaignId,
                receiverUserId: con.receiver_userId,
                receiverBrandId: con.receiver_brandId,
                pidCount:
                  con.users_count && toInternationalFormat(con.users_count),
                userDbCount:
                  con.kevel_pid_upload_count &&
                  toInternationalFormat(con.kevel_pid_upload_count),
              },
            });
          }

          let connection = [];
          let connectionStatus = [];
          for (let con of response.data.connections) {
            if (
              con.userDbStatus === "FAILED" ||
              con.status === "FAILED" ||
              con.status === "" ||
              con.status === "PENDING" ||
              con.requestStatus === "PID FETCHED FAILED"
            ) {
              connectionStatus.push(false);
            } else {
              connectionStatus.push(true);
            }

            connection.push({
              requestStatus: con.requestStatus?.replace(/_/g, " "),
              logo: con.receiver_brand_info?.logo,
              mau: toInternationalFormat(con.receiver_brand_info?.mau) || "",
              brandId: con.receiver_brand_info?.brandId,
              category: con.receiver_brand_info?.category || [],
              languages: con.receiver_brand_info?.languages || [],
              locations: con.receiver_brand_info?.location || [],
              name: con.receiver_brand_info?.brand_name || "",
              expectedCount:
                con.total_users_count &&
                toInternationalFormat(con.total_users_count),
              usersCount:
                con.users_count && toInternationalFormat(con.users_count),
              totalUsersCount: con.total_users_count,
              dbStatus: con.userDbStatus || "",
              pidStatus: con.status || "",
              senderUserId: con.sender_userId,
              senderBrandId: con.sender_brandId,
              senderCampaignId: con.sender_campaignId,
              receiverUserId: con.receiver_userId,
              receiverBrandId: con.receiver_brandId,
              pidCount:
                con.users_count && toInternationalFormat(con.users_count),
              userDbCount:
                con.kevel_pid_upload_count &&
                toInternationalFormat(con.kevel_pid_upload_count),
            });
          }

          setRetry(connectionStatus);
          setConnections(connection);
        }
        dispatch(
          alert({
            type: "success",
            message: "Current data has been fetched successfully!",
          })
        );
      }
      setApiStart(false);
    });
  }

  return (
    <Card
      open={details?.state}
      onClose={handleClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "800px!important",
          padding: "20px 24px",
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={8} display="flex">
            <MainHeading>Connection Statistics</MainHeading>
            <Tooltip title="Fetch the current data">
              <IconButton
                disabled={apiStart}
                padding="5px"
                onClick={handleRefresh}
              >
                <Sync color={colors.info} />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item xs={4} textAlign="end">
            <IconButton padding="0px" onClick={handleClose}>
              <HighlightOff />
            </IconButton>
          </Grid>

          <Grid item xs={6}>
            <Label title={"Campaign Name"} />
          </Grid>

          <Grid item xs={6}>
            <Label
              title={details?.data?.campaignName || "N.A."}
              color={colors.info}
            />
          </Grid>

          <Grid item xs={6}>
            <Label title={"Brand Name"} />
          </Grid>

          <Grid item xs={6}>
            <Label title={details?.data?.name || "N.A."} color={colors.info} />
          </Grid>

          <Grid item xs={6}>
            <Label title={"Expected Count"} />
          </Grid>

          <Grid item xs={6}>
            <Label
              title={details?.data?.expectedCount || "0"}
              color={colors.info}
            />
          </Grid>

          <Grid item xs={6}>
            <Label title={"PID Count"} />
          </Grid>

          <Grid item xs={6}>
            <Label
              title={details?.data?.usersCount || "0"}
              color={colors.info}
            />
          </Grid>

          <Grid item xs={6}>
            <Label title={"PID Status"} />
          </Grid>

          <Grid item xs={6}>
            <Label title={details.data?.pidStatus || "0"} color={colors.info} />
          </Grid>

          <Grid item xs={6}>
            <Label title={"User DB Count"} />
          </Grid>

          <Grid item xs={6}>
            <Label
              title={details.data?.userDbCount || "0"}
              color={colors.info}
            />
          </Grid>

          <Grid item xs={6}>
            <Label title={"User DB Status"} />
          </Grid>

          <Grid item xs={6}>
            <Label title={details.data?.dbStatus || "0"} color={colors.info} />
          </Grid>
        </Grid>
      </DialogContent>
    </Card>
  );
}
