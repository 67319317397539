import React from "react";
import styled from "styled-components/macro";
import { DialogContent, Dialog, Grid } from "@mui/material";

import FormDisplay from "./FormDisplay";

const Card = styled(Dialog)`
  min-width: 360px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export default function RejectOfferDialog({ reject, setReject }) {
  const handleClose = () => {
    setReject({ state: false, data: reject.data });
  };

  return (
    <React.Fragment>
      <Card open={reject?.state} onClose={handleClose}>
        <DialogContent>
          <Grid container spacing={3} style={{ padding: "20px" }}>
            <FormDisplay
              reject={reject}
              setReject={setReject}
              handleClose={handleClose}
            />
          </Grid>
        </DialogContent>
      </Card>
    </React.Fragment>
  );
}
