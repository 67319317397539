import { Fragment, useState } from "react";
import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";
import { IoIosArrowForward } from "react-icons/io";

import { Paper, TextField } from "../../../components";
import CreativeDialog from "./creative components/CreativeDialog";
import { PrimaryButton } from "../../css components/Button";
import { HeadingEighteen, colors } from "../../css components/Style";

import BASE_URL from "../../../constants/baseURL";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const Link = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

const ArrowIcon = styled(IoIosArrowForward)`
  position: relative;
  top: 6px;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #02a0fc;
  cursor: pointer;
`;

export default function FormFifth({ formikProps, component }) {
  const [editState, setEditState] = useState(true);

  const [detail, setDetail] = useState({
    state: false,
    campaignDetails: formikProps.values,
    data: {
      format: "",
      creative: "",
      fileUrl: "",
      friendlyName: "",
      fileName: "",
      adSize: "",
      adTag: "",
      destination_url: "",
      destinationURLCheckbox: true,
    },
    id: "",
  });

  return (
    <Fragment>
      <Paper style={{ marginTop: "25px" }}>
        <Grid item xs={12}>
          <MainHeading>Creative</MainHeading>
        </Grid>

        {formikProps.values.creatives.filter(
          (items) => items.isDeleted === false
        ).length === 0 ? (
          <Grid item xs={12}>
            <HeadingEighteen color={colors.info}>
              No creative yet!
            </HeadingEighteen>
          </Grid>
        ) : (
          formikProps.values.creatives.map((creative) => {
            let adSizeDimension = formikProps.values.adSizeData.filter(
              (items) => {
                return items.id == creative.adSize;
              }
            )[0];

            return (
              <Fragment>
                {!creative.isDeleted && (
                  <Fragment>
                    {" "}
                    <Grid item xs={8}>
                      <TextField value={`${creative.friendlyName} `} disabled />
                      <Typography color={"#00000066"} m={"5px 10px"}>
                        {adSizeDimension
                          ? `Ad size: ${adSizeDimension?.width}X${adSizeDimension?.height} - ${adSizeDimension?.name} `
                          : ""}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      display="flex"
                      alignSelf="center"
                      justifyContent="end"
                      sx={{ mb: "16px", pb: "16px" }}
                    >
                      <Link onClick={() => viewCreative(creative)}>View</Link>{" "}
                      <ArrowIcon />
                    </Grid>{" "}
                  </Fragment>
                )}
              </Fragment>
            );
          })
        )}

        {component !== "superadmin" && component !== "request" && (
          <Grid item xs={12}>
            <PrimaryButton variant="contained" onClick={() => addCreative()}>
              Add Creative
            </PrimaryButton>
          </Grid>
        )}
      </Paper>

      <CreativeDialog
        formikProps={formikProps}
        detail={detail}
        setDetail={setDetail}
        editState={editState}
        setEditState={setEditState}
        component={component}
      />
    </Fragment>
  );

  function addCreative() {
    setEditState(true);
    setDetail((prev) => ({
      ...prev,
      data: {
        format: "image",
        creative: "",
        upload: "local",
        fileUrl: "",
        friendlyName: "",
        fileName: "",
        adSize: "",
        adTag: "",
        destination_url: formikProps.values.destination_url.value,
        destinationURLCheckbox: true,
      },
      state: true,
      campaignDetails: formikProps.values,
      id: "",
    }));
  }

  function viewCreative(creatives) {
    setEditState(false);
    setDetail((prev) => ({
      ...prev,
      state: true,
      campaignDetails: formikProps.values,
      id: creatives.creativeID.toString(),
      data: {
        creativeID: creatives.creativeID,
        format: creatives.format || "",
        creative: creatives.creative || "",
        fileUrl: creatives.fileUrl || "",
        friendlyName: creatives.friendlyName || "",
        fileName: creatives.fileName || "",
        adSize: creatives.adSize || "",
        adTag: creatives.adTag || "",
        previewImage: !!creatives.creative
          ? creatives.fileName
          : !!creatives.fileUrl
          ? creatives.fileUrl
          : "No image",
        upload: creatives.upload,
        destination_url: creatives.destination_url,
        destinationURLCheckbox: creatives.destinationURLCheckbox,
      },
    }));
  }
}
