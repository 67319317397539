import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";
import { Typography, Grid, Avatar } from "@mui/material";

const PageName = styled(Typography)`
  font-weight: 500;
  font-size: 40px;
  color: #333;
`;

const Heading = styled(Typography)`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
`;

const BrandName = styled(Typography)`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #828282;
`;

const Image = styled(Avatar)`
  border: 1px solid #999999;
  height: 70px;
  width: 70px;
`;

const Span = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
`;

export default function CampaignHeader({
  title = "",
  pageName,
  firstText,
  secondText,
  heading,
  brandName,
  iconImage,
  buttons = [],
  campaignOverAllStatus = "",
  formikProps,
}) {
  return (
    <React.Fragment>
      <Helmet title={title || pageName || firstText + secondText} />

      <Grid p="20px 0" mb="10px" container alignItems="center">
        <Grid item>
          {!iconImage ? (
            <Image>{brandName.split(" ")[0]}</Image>
          ) : (
            <Image src={iconImage} />
          )}
        </Grid>

        <Grid item xs ml="30px">
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Heading>{heading}</Heading>
              <PageName variant="h3">{pageName}</PageName>
              <BrandName>by {brandName}</BrandName>
            </Grid>
            <Grid item xs={6}>
              {Boolean(campaignOverAllStatus) && (
                <Grid item display={"flex"} justifyContent={"end"}>
                  <Span variant="span">
                    Overall Campaign Status :&nbsp;&nbsp;&nbsp;&nbsp;
                  </Span>

                  <div
                    style={{
                      background: `${
                        campaignOverAllStatus === "REQUESTED" ||
                        campaignOverAllStatus === "CREATED" ||
                        campaignOverAllStatus === "ARCHIVED"
                          ? "rgb(255, 187, 11,20%)"
                          : campaignOverAllStatus === "OFFER_ACCEPTED" ||
                            campaignOverAllStatus === "ACTIVE" ||
                            campaignOverAllStatus === "ACCEPTED"
                          ? "rgb(2, 179, 2,20%)"
                          : "rgb(255, 58, 41,20%)"
                      }`,
                    }}
                  >
                    <Span
                      variant="span"
                      sx={{
                        height: "30px",
                        padding: `0px 15px`,
                        borderRadius: "5px",
                        color: `${
                          campaignOverAllStatus === "REQUESTED" ||
                          campaignOverAllStatus === "CREATED" ||
                          campaignOverAllStatus === "ARCHIVED"
                            ? "rgb(255, 187, 11)"
                            : campaignOverAllStatus === "OFFER_ACCEPTED" ||
                              campaignOverAllStatus === "ACTIVE" ||
                              campaignOverAllStatus === "ACCEPTED"
                            ? "rgb(2, 179, 2)"
                            : "rgb(255, 58, 41)"
                        }`,
                      }}
                    >
                      {campaignOverAllStatus.replace(/_/, " ")}
                    </Span>
                  </div>
                </Grid>
              )}

              {!!formikProps.values.createdByRole &&
              !!formikProps.values.createdByName ? (
                <Grid item display={"flex"} justifyContent={"end"} mt={3}>
                  <Span variant="span">
                    Campaign Created By :&nbsp;&nbsp;&nbsp;&nbsp;
                  </Span>

                  <Span
                    variant="span"
                    sx={{ background: "#8080803d", padding: "0px 10px" }}
                  >
                    {formikProps.values.createdByName.replace(/_/, " ")}
                  </Span>
                </Grid>
              ) : (
                !!formikProps.values.updatedByName && (
                  <Grid item display={"flex"} justifyContent={"end"} mt={3}>
                    <Span variant="span">
                      Campaign Updated By :&nbsp;&nbsp;&nbsp;&nbsp;
                    </Span>

                    <Span
                      variant="span"
                      sx={{ background: "#8080803d", padding: "0px 10px" }}
                    >
                      {formikProps.values.updatedByName.replace(/_/, " ")}
                    </Span>
                  </Grid>
                )
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          style={{ gap: "5px" }}
        >
          {buttons.length > 0 &&
            buttons.map((icon, index) => (
              <Grid item key={index}>
                {icon}
              </Grid>
            ))}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
