import { Fragment, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate, useMatch, useLocation } from "react-router-dom";
import {
  Box,
  List as MuiList,
  ListItemButton as MuiListItemButton,
  ListItemIcon as MuiListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { makeStyles, useTheme } from "@mui/styles";

import sidebarRoutes from "./sidebarRoutes";

const Wrapper = styled(Box)`
  flex: 1;
  max-height: calc(100% - 55px);
  padding: 0 10px;
`;

const List = styled(MuiList)`
  padding: 0;
`;

const ListItemButton = styled(MuiListItemButton)`
  height: 80px;
  border-radius: 4px;
  margin: 10px 0;
`;

const ListItemIcon = styled(MuiListItemIcon)`
  min-width: 30px;
`;

const useStyles = makeStyles({
  root: {
    "&$selected": {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white",
      },
    },
  },
  selected: {},
});

export default function Menu({ sidebarState }) {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedMenu, setSelectedMenu] = useState(location.pathname);
  const { dashboardPermissions, parentState } = useSelector(
    (state) => state.memberDetails
  );

  const permittedRoutes = [];

  for (let route of sidebarRoutes) {
    let childrenRoutes = [];

    if (route.children) {
      for (let childRoute of route.children) {
        let child = dashboardPermissions.find(
          (page) => page.ID === childRoute.ID
        );
        if (child) {
          childrenRoutes.push(child);
        }
      }

      if (childrenRoutes.length > 0) {
        route = { ...route, children: childrenRoutes };
      } else {
        continue;
      }
    } else {
      let parent = dashboardPermissions.find((page) => page.ID === route.ID);

      if (parent) {
        permittedRoutes.push(route);
      }
    }
  }

  return (
    <Wrapper
      style={{
        color: theme.sidebar.color,
        marginTop: Boolean(parentState === "SA") ? "0px" : "55px",
      }}
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <List>
          {permittedRoutes.map(({ name, icon, ID, path, children }) =>
            children ? (
              <ListFolder
                key={ID}
                name={name}
                icon={icon}
                children={children}
              />
            ) : (
              <ListItem key={ID} name={name} icon={icon} path={path} />
            )
          )}
        </List>
      </PerfectScrollbar>
    </Wrapper>
  );

  function ListFolder({ name, icon: Icon, children }) {
    const [folderOpen, setFolderOpen] = useState(false);

    return (
      <Fragment>
        <ListItemButton
          onClick={() => setFolderOpen(!folderOpen)}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <ListItemIcon style={{ color: theme.sidebar.color }}>
            <Icon style={{ width: "26px", height: "26px" }} />
          </ListItemIcon>
          <ListItemText primary={name} />

          {folderOpen ? (
            <KeyboardArrowUpOutlined />
          ) : (
            <KeyboardArrowDownOutlined />
          )}
        </ListItemButton>

        <Collapse
          in={folderOpen}
          timeout="auto"
          unmountOnExit
          style={{ marginInline: "10px" }}
        >
          <List>
            {children.map(({ name, ID, path }) => (
              <ListItem
                key={ID}
                name={name}
                path={path}
                icon={KeyboardArrowRight}
              />
            ))}
          </List>
        </Collapse>
      </Fragment>
    );
  }

  function ListItem({ name, icon: Icon, path }) {
    const match = useMatch(path);

    return (
      <ListItemButton
        classes={{ root: classes.root, selected: classes.selected }}
        style={{ display: "flex", flexDirection: "column" }}
        onClick={() => {
          navigate(path);
          setSelectedMenu(path);
        }}
        selected={!!match}
      >
        <ListItemIcon color="black">
          <Icon
            fill={selectedMenu === path ? "#333" : "#C5C5C5"}
            style={{
              width: "26px",
              height: "26px",
            }}
          />
        </ListItemIcon>
        <ListItemText
          style={{
            fontSize: "12px",
            fontWeight: "400",
            textAlign: "center",
            color: selectedMenu === path ? "#333" : "#C5C5C5",
          }}
          primary={name}
        />
      </ListItemButton>
    );
  }
}
