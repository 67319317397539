import React from "react";
import { Grid } from "@mui/material";

import FormCard from "./FormCard";
import { useParams } from "react-router-dom";

export default function Invitation() {
  const params = useParams();

  const code = params.code || "";

  return (
    <Grid
      container
      spacing={2}
      p="10px"
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <Grid item xs={12} md={8}>
        <FormCard code={code} />
      </Grid>
    </Grid>
  );
}
