import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { Fragment, useState } from "react";
import { Grid, Typography, Checkbox, FormControlLabel } from "@mui/material";

import { PrimaryButton } from "../../css components/Button";
import { Label, Paper, Autocomplete } from "../../../components";
import AudienceForm from "./audience details components/AudienceForm";
import AudienceDetailsDialog from "./audience details components/AudienceDetailsDailog";
import { colors } from "../../css components/Style";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

export default function FormTwo({
  audiences,
  brandIdentity,
  formikProps,
  editState,
  component,
  audienceData,
  setAudienceData,
  setAudienceCreated,
}) {
  const { categories, location, languages } = useSelector(
    (state) => state.memberDetails
  );
  const [details, setDetails] = useState({
    id: formikProps.values.audience,
    state: false,
  });
  const [noAudience, setNoAudience] = useState(
    !formikProps.values.audienceID
      ? !!formikProps.values.campaignID
        ? true
        : false
      : false
  );

  return (
    <Fragment>
      <Paper style={{ marginTop: "25px" }}>
        <Grid item xs={12}>
          <MainHeading>Audience Group Details </MainHeading>
        </Grid>

        {!noAudience && (
          <Fragment>
            {!formikProps.values?.campaignID || editState ? (
              <Fragment>
                <Grid item xs={12}>
                  <Label
                    title={"Preset Audience"}
                    formikProps={formikProps}
                    required={true}
                    name={"audienceID"}
                    select={true}
                  />
                </Grid>

                <Grid item xs={10}>
                  <Autocomplete
                    forcePopupIcon={!editState ? false : true}
                    name="audienceID"
                    placeholder="Select Audience"
                    defaults={{
                      primaryKey: "audience_id",
                      displayLabel: "audience_group",
                    }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        display: !editState && "none",
                      },
                    }}
                    options={audiences}
                    disabled={!editState || !formikProps.values.isEditable}
                    onChange={(_, value, type) =>
                      handleChangeAudience(value, type)
                    }
                    formikProps={formikProps}
                  />
                </Grid>

                {formikProps.values.audienceID && (
                  <Grid item xs={12}>
                    <Typography
                      onClick={() =>
                        setDetails({
                          id: formikProps.values.audienceID,
                          state: true,
                          open: "detail",
                        })
                      }
                      style={{ color: "#02a0fc", cursor: "pointer" }}
                    >
                      View Audience Group Details
                    </Typography>
                  </Grid>
                )}

                {!brandIdentity && editState && (
                  <Grid item xs={12}>
                    <Typography style={{ marginBottom: "8px" }}>
                      Can’t find your preset audience group, let’s create one.
                    </Typography>

                    <PrimaryButton
                      variant="contained"
                      disabled={!editState || !formikProps.values.isEditable}
                      onClick={() =>
                        setDetails({
                          state: true,
                          open: "create",
                        })
                      }
                    >
                      Create Audience
                    </PrimaryButton>
                  </Grid>
                )}
              </Fragment>
            ) : (
              <AudienceForm
                audienceData={audienceData}
                categories={categories}
                location={location}
                languages={languages}
                formikProps={formikProps}
                component={component}
                setAudienceData={setAudienceData}
              />
            )}
          </Fragment>
        )}

        {(!formikProps.values.campaignID || editState || noAudience) && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={!editState || !formikProps.values.isEditable}
                  onChange={handleCheckAudience}
                  checked={noAudience}
                  sx={{
                    color: colors.info,
                    "&.Mui-checked": {
                      color: colors.info,
                    },
                  }}
                />
              }
              label="Run of network"
            />
          </Grid>
        )}
      </Paper>

      <AudienceDetailsDialog
        categories={categories}
        location={location}
        languages={languages}
        details={details}
        setDetails={setDetails}
        component="dialog"
        setAudienceCreated={setAudienceCreated}
        formikProps={formikProps}
        campaignID={formikProps?.values?.campaignID}
      />
    </Fragment>
  );

  function handleCheckAudience(event) {
    setNoAudience(event.target.checked);
    formikProps.setValues((prev) => ({
      ...prev,
      audienceID: "",
    }));
  }

  function handleChangeAudience(value, type) {
    if (type === "selectOption") {
      formikProps.setValues((prevVal) => ({
        ...prevVal,
        audienceID: value.audience_id,
      }));

      setDetails({
        id: value.audience_id,
        state: false,
        open: "detail",
      });
    } else {
      value = {
        audience_id: "",
        audience_group: "",
      };

      formikProps.setValues((prevVal) => ({
        ...prevVal,
        audienceID: value._id,
      }));
    }
  }
}
