import styled from "styled-components/macro";
import { Grid, MenuItem, Typography } from "@mui/material";

import { Label, Paper, TextField } from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const Menu = styled(MenuItem)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
`;

export default function FormTwo({ formikProps, editState }) {
  return (
    <Paper>
      <Grid item xs={12}>
        <MainHeading>Access Privileges</MainHeading>
      </Grid>

      <Grid item xs={10}>
        <Label title={"Role"} />
      </Grid>

      <Grid item xs={10}>
        <TextField
          select
          formikProps={formikProps}
          name="role"
          disabled={!editState}
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <Menu value="" disabled>
            Select Role
          </Menu>
          <Menu value="Administrator">Administrator</Menu>
          <Menu value="NORMAL_USER">NORMAL USER</Menu>
        </TextField>
      </Grid>
    </Paper>
  );
}
