import React from "react";
import { CgAsterisk } from "react-icons/cg";
import { get } from "lodash";

import { Grid, Typography } from "@mui/material";

export default function Label({
  title,
  formikProps,
  required,
  name,
  select = false,
  buttons = [],
  color = "#333",
}) {
  return (
    <Grid
      container
      style={{ minHeight: "35px", marginTop: "7px" }}
      alignItems="center"
    >
      <Grid item xs={9}>
        <Typography
          variant="subtitle1"
          style={{ fontSize: "18px", fontWeight: "600", color: color }}
        >
          {title}

          {required && (
            <CgAsterisk
              style={{
                position: "relative",
                top: "3px",
                color: (
                  (typeof get(formikProps.values, name) === "string"
                    ? get(formikProps.values, name)
                    : get(formikProps.values, name)?.length === 0
                    ? undefined
                    : true) && get(formikProps.errors, name) === undefined
                    ? false
                    : select
                    ? !get(formikProps.values, name)
                    : true
                )
                  ? "#d32f2f"
                  : "#808080",
              }}
            />
          )}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Grid
          container
          spacing={2}
          style={{ gap: "4px", justifyContent: "flex-end" }}
        >
          {buttons.length > 0 &&
            buttons.map((button, index) => (
              <Grid item style={{ alignSelf: "center" }} key={index}>
                {button}
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
