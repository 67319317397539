import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import {
  CancelButton,
  PrimaryButton,
  PrimaryLoadingButton,
  Reject,
  Accept,
  Pending,
} from "../../css components/Button";
import { useSelector } from "react-redux";

export default function CampaignButtons({
  ID = "",
  editState,
  setEditState,
  formikProps,
  component,
  brandIdentity,
  setTakeUpOffer,
  liveUsers,
}) {
  const navigate = useNavigate();
  const { activeBrand } = useSelector((state) => state.memberDetails);

  function handleCancel() {
    if (editState && !!ID) {
      setEditState(!editState);
      formikProps.handleReset();
    } else {
      navigate(-1);
    }
  }

  return (
    <Fragment>
      <CancelButton onClick={handleCancel} variant="outlined">
        {!editState ? "Back" : "Cancel"}
      </CancelButton>

      {component === "" &&
        (editState ? (
          <PrimaryLoadingButton
            type="submit"
            size="large"
            variant="contained"
            onClick={formikProps.handleSubmit}
            loading={formikProps.isSubmitting}
            disabled={!formikProps.dirty || !formikProps.isValid}
          >
            Save
          </PrimaryLoadingButton>
        ) : (
          <PrimaryButton
            size="large"
            variant="contained"
            onClick={() => setEditState(!editState)}
            disabled={formikProps.values.campaignStatus === "ACTIVE"}
          >
            Edit
          </PrimaryButton>
        ))}

      {component === "superadmin" && (
        <Accept
          size="large"
          variant="contained"
          style={{ marginRight: "15px" }}
        >
          Approve Campaign
        </Accept>
      )}

      {brandIdentity &&
        component !== "accepted" &&
        Boolean(
          !formikProps?.values?.connections?.find(
            (item) =>
              item.receiver_brand_info?.brand_name == activeBrand.brandName
          ).is_accepted_by_admin
        ) &&
        formikProps.values.campaignOverAllStatus !== "ADMIN_REJECTED" && (
          <Fragment>
            {formikProps.values.campaignStatus !== "REJECTED" && (
              <Reject
                size="large"
                variant="contained"
                style={{ marginRight: "15px" }}
                onClick={() =>
                  setTakeUpOffer((prevVal) => ({
                    ...prevVal,
                    action: "REJECTED",
                    state: true,
                  }))
                }
              >
                Reject
              </Reject>
            )}

            {formikProps.values.campaignStatus === "REQUESTED" && (
              <Pending
                size="large"
                variant="contained"
                style={{ marginRight: "15px" }}
                onClick={() =>
                  setTakeUpOffer((prevVal) => ({
                    ...prevVal,
                    action: "PENDING",
                    state: true,
                  }))
                }
              >
                Pending
              </Pending>
            )}

            {formikProps.values.campaignStatus !== "ACCEPTED" &&
              formikProps.values.campaignStatus !== "OFFER_ACCEPTED" && (
                <Accept
                  size="large"
                  variant="contained"
                  disabled={
                    Boolean(liveUsers?.liveMessage) &&
                    formikProps.values.audienceID
                  }
                  onClick={() =>
                    setTakeUpOffer((prevVal) => ({
                      ...prevVal,
                      action: "ACCEPTED",
                      state: true,
                    }))
                  }
                >
                  Accept
                </Accept>
              )}
          </Fragment>
        )}
    </Fragment>
  );
}
