import { Fragment } from "react";
import { Grid } from "@mui/material";

import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import { PageHeader } from "../../../components";
import Buttons from "../../../components/Buttons";

export default function FormDisplay({ editState, setEditState, formikProps }) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader
          heading={"Flicktree"}
          pageName="my connections"
          buttons={[
            <Buttons
              ID={formikProps.values.ID}
              formikProps={formikProps}
              editState={editState}
              setEditState={setEditState}
            />,
          ]}
        />
      </Grid>

      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormOne
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
          />
        </Grid>

        <Grid item xs={6}>
          <FormTwo
            formikProps={formikProps}
            editState={editState}
            setEditState={setEditState}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
