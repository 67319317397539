import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import { Loader } from "../../components";
import { useAxios, useComponent } from "../../hooks";
import {
  brandDetailsObject,
  objectFromResponse,
} from "./brand details components/brandDetailObject";
import Form from "./brand details components/Form";

export default function BrandDetails() {
  const axios = useAxios();
  const { loader } = useComponent();

  const [editState, setEditState] = useState(false);
  const [formData, setFormData] = useState(brandDetailsObject);

  const { activeBrand, userID, categories, location, languages } = useSelector(
    (state) => state.memberDetails
  );

  useEffect(() => {
    if (!activeBrand.ID) {
      setEditState(true);
    }
  }, [activeBrand.ID]);

  useEffect(() => {
    loader.start(2);

    axios({
      url: "/getBrand/" + activeBrand.ID,
      method: "GET",
    }).then((brand) => {
      if (brand.success) {
        axios({
          url: "/user/info?userId=" + userID,
        }).then((response) => {
          if (response.success) {
            let data = { ...brand.data, ...response.data.user };
            setFormData(objectFromResponse(data));
            loader.apiComplete(2);
          }
        });
      }
    });
  }, [axios, activeBrand.ID, loader, userID, formData.fileName]);

  return (
    <Loader>
      <Form
        formData={formData}
        editState={editState}
        setEditState={setEditState}
        categories={categories}
        location={location}
        languages={languages}
        setFormData={setFormData}
      />
    </Loader>
  );
}
