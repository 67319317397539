import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { Grid, Typography } from "@mui/material";

import { useComponent } from "../../../hooks";
import { Paper, Label, FileUpload } from "../../../components";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

export default function FormSixth({ formikProps, editState }) {
  const { alert } = useComponent();

  const dispatch = useDispatch();

  function handleFileChange(event) {
    const file = event.target.files[0];

    const fileSize = Math.round(file.size / 1024);

    if (fileSize > 2 * 1024) {
      return dispatch(
        alert.warning("File size too large! Make smaller (under 2 MB) ")
      );
    }

    formikProps.setValues((prevVal) => ({
      ...prevVal,
      file_name: setFileName(prevVal.file_name),
      previewImage: file,
    }));

    function setFileName(val) {
      val.value = file.name;
      return val;
    }
  }

  return (
    <Paper style={{ marginTop: "25px" }}>
      <Grid item xs={12}>
        <MainHeading>Preview Image</MainHeading>
      </Grid>

      <Grid item xs={12}>
        <Label
          title="Preview Image"
          formikProps={formikProps}
          required={true}
          name={"previewImage"}
        />
      </Grid>

      <Grid item xs={10}>
        {/* <TextField
          required
          disabled={!editState || !formikProps.values.isEditable}
          name="file_name.value"
          formikProps={formikProps}
          placeholder="Select Preview Image"
          inputProps={{
            "aria-label": "Without label",
            IconComponent: () => editState && null,
          }}
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton
                disabled={!editState}
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  disabled={!editState}
                  onChange={handleFileChange}
                  hidden
                  accept="image/*"
                  type="file"
                />
                <Upload />
              </IconButton>
            ),
          }}
        /> */}
        <FileUpload
          disabled={!editState || !formikProps.values.isEditable}
          name="previewImage"
          formikProps={formikProps}
          value={formikProps.values.previewImage
            ?.split("/")
            .pop()
            .slice(
              formikProps.values?.previewImage?.split("/").pop().indexOf("_") +
                1
            )}
          placeholder="Select Preview Image"
          inputProps={{
            "aria-label": "Without label",
          }}
          allowedExtension=".jpeg,.jpg,.png"
          folderPath={
            "mox/public/campaign_previews/" + new Date().getTime() + "_"
          }
        />
      </Grid>
    </Paper>
  );
}
