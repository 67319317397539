export default function Eye({ fill }) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 14.6249C12.1041 14.6249 11.375 13.8958 11.375 12.9999C11.375 12.104 12.1041 11.3749 13 11.3749C13.8959 11.3749 14.625 12.104 14.625 12.9999C14.625 13.8958 13.8959 14.6249 13 14.6249ZM13 9.20821C10.9092 9.20821 9.20833 10.909 9.20833 12.9999C9.20833 15.0907 10.9092 16.7915 13 16.7915C15.0908 16.7915 16.7917 15.0907 16.7917 12.9999C16.7917 10.909 15.0908 9.20821 13 9.20821ZM13.2382 18.414C8.57334 18.5223 5.53026 14.5324 4.52059 12.9951C5.63209 11.2564 8.43142 7.69654 12.7626 7.58604C17.409 7.46795 20.4694 11.4676 21.4791 13.0049C20.3687 14.7436 17.5683 18.3035 13.2382 18.414ZM23.6899 12.4611C22.9987 11.2564 19.1811 5.21792 12.7071 5.42051C6.7184 5.57217 3.23548 10.848 2.31032 12.4611C2.11857 12.7948 2.11857 13.2053 2.31032 13.539C2.99173 14.7274 6.67507 20.5828 13.0266 20.5828C13.1155 20.5828 13.2043 20.5818 13.2931 20.5796C19.2807 20.4268 22.7647 15.1521 23.6899 13.539C23.8806 13.2053 23.8806 12.7948 23.6899 12.4611Z"
        fill={fill}
      />
    </svg>
  );
}
