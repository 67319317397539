import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import {
  Grid,
  MenuItem,
  Typography,
  Button,
  IconButton,
  Checkbox,
} from "@mui/material";
import { HighlightOff, Upload } from "@mui/icons-material";

import ConfirmDialog from "../confirm";
import { useAxios, useComponent } from "../../../../hooks";
import { TextField, Label } from "../../../../components";
import { uploadToS3 } from "../../../../utils";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: #02a0fc;
`;

const Menu = styled(MenuItem)`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
`;

const Creative = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  color: rgba(189, 189, 189, 1);
`;

const ImageDiv = styled.div`
  width: 200px;
  height: 80px;
  border: 1px solid #d6cbcb;
`;

export default function CreativeForm({
  dialogFormikProps,
  editState,
  handleClose,
  formikProps,
  component,
  adSize,
  confirm,
  setConfirm,
  setIsValidated,
}) {
  const axios = useAxios();
  const dispatch = useDispatch();
  const { alert } = useComponent();

  const [destinationURLCheckbox, setDestinationURLCheckbox] = useState(
    dialogFormikProps.values.destinationURLCheckbox
  );

  return (
    <Fragment>
      <Grid item xs={12} textAlign="end" pt={"0px"}>
        <IconButton
          padding="0px"
          onClick={() => handleClose()}
          sx={{ verticalAlign: "super", marginLeft: "50px" }}
        >
          <HighlightOff />
        </IconButton>
      </Grid>

      <Grid item xs={6}>
        <MainHeading>Ad Creative</MainHeading>
        <Creative>Creative Details</Creative>
      </Grid>

      <Grid item xs={6} textAlign="end">
        {/* {component !== "superadmin" &&
          component !== "request" &&
          dialogFormikProps.values.creativeID && (
            <CancelLoadingButton
              loading={loading}
              variant="contained"
              onClick={onDelete}
              style={{ color: "white", background: "red" }}
            >
              Deactivate
            </CancelLoadingButton>
          )} */}
      </Grid>
      <Grid item xs={6}>
        <Label
          title="Format"
          name="format"
          required={true}
          formikProps={dialogFormikProps}
        />
      </Grid>
      <Grid item xs={6}>
        {dialogFormikProps.values.format !== "ad-tag" && (
          <Label
            title="Source Type"
            name="upload"
            required={true}
            formikProps={dialogFormikProps}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          formikProps={dialogFormikProps}
          onChange={(e) => handleFormat(e)}
          disabled={
            !editState ||
            dialogFormikProps.values.creativeID ||
            component === "superadmin"
          }
          name="format"
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            style: {
              color: dialogFormikProps.values.format === "" && "#bababacf",
            },
          }}
        >
          <Menu value="" selected disabled>
            Select Format Type
          </Menu>
          <Menu value="video">Video</Menu>
          <Menu value="image">Image</Menu>
          <Menu value="ad-tag">Ad-Tag</Menu>
        </TextField>
      </Grid>

      <Grid item xs={6}>
        {dialogFormikProps.values.format !== "ad-tag" && (
          <TextField
            select
            formikProps={dialogFormikProps}
            disabled={!editState || component === "superadmin"}
            name="upload"
            sx={{
              "& .MuiSvgIcon-root": {
                display: !editState && "none",
              },
            }}
            SelectProps={{
              displayEmpty: true,
            }}
            InputProps={{
              style: {
                color: dialogFormikProps.values.upload === "" && "#bababacf",
              },
            }}
          >
            <Menu value="" selected disabled>
              Select Source Type
            </Menu>
            <Menu value="local">Upload From Device</Menu>

            <Menu value="link">Paste URL</Menu>
          </TextField>
        )}
      </Grid>

      <Grid item xs={12}>
        <Label
          title="Ad Size"
          name="adSize"
          required={dialogFormikProps.values.format !== "ad-tag" && true}
          formikProps={dialogFormikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          select
          disabled={
            Boolean(
              dialogFormikProps.values.creativeID &&
                dialogFormikProps.values.upload === "local"
            ) ||
            component === "superadmin" ||
            !editState
          }
          formikProps={dialogFormikProps}
          name="adSize"
          onChange={handleAdSizeChange}
          sx={{
            "& .MuiSvgIcon-root": {
              display: !editState && "none",
            },
          }}
          SelectProps={{
            displayEmpty: true,
          }}
          InputProps={{
            style: {
              color: dialogFormikProps.values.adSize === "" && "#bababacf",
            },
          }}
        >
          <Menu value="" selected disabled>
            Select Creative Size
          </Menu>
          {adSize.map((size) => (
            <Menu value={size.id}>
              {size.name}- {size.width}X{size.height}
            </Menu>
          ))}
        </TextField>
      </Grid>

      {dialogFormikProps.values.format !== "ad-tag" && (
        <Fragment>
          {dialogFormikProps.values.upload === "local" && (
            <Fragment>
              <Grid item xs={12}>
                <Label title="Upload" />
              </Grid>

              <Grid
                item
                xs={dialogFormikProps.values.format === "image" ? 6 : 12}
              >
                <input
                  accept={`${
                    dialogFormikProps.values.format === "image"
                      ? ".jpeg, .jpg, .png, .webp, image/webp, image/jpeg, image/jpg, image/png"
                      : ".mp4, .mkv, .avi, video/mp4"
                  }`}
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  disabled={
                    !editState ||
                    component === "superadmin" ||
                    dialogFormikProps.values.creativeID ||
                    !Boolean(dialogFormikProps.values.adSize)
                  }
                  onClick={(e) => {
                    e.target.value = "";
                  }}
                  onChange={(e) =>
                    handleFileChange(e, dialogFormikProps.values)
                  }
                />
                <label htmlFor="contained-button-file">
                  <Button
                    startIcon={<Upload />}
                    variant="contained"
                    disabled={
                      !editState ||
                      dialogFormikProps.values.creativeID ||
                      !Boolean(dialogFormikProps.values.adSize)
                    }
                    color="primary"
                    component="span"
                  >
                    Creative File
                  </Button>{" "}
                  {!!dialogFormikProps.values.fileName
                    ? dialogFormikProps.values.fileName.length > 30
                      ? dialogFormikProps.values.fileName.split("/").pop()
                      : dialogFormikProps.values.fileName
                    : "No file chosen"}
                </label>
              </Grid>

              <Grid item xs={6}>
                {!!dialogFormikProps.values.previewImage &&
                  dialogFormikProps.values.creativeID &&
                  dialogFormikProps.values.format === "image" && (
                    <ImageDiv>
                      <a
                        href={dialogFormikProps.values.previewImage}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "fill",
                            borderRadius: "6px",
                          }}
                          src={dialogFormikProps.values.previewImage}
                          alt="!"
                        />
                      </a>
                    </ImageDiv>
                  )}
              </Grid>
            </Fragment>
          )}

          <Fragment>
            {dialogFormikProps.values.upload === "link" && (
              <Fragment>
                <Grid item xs={12}>
                  <Label title="File URL" />
                </Grid>

                <Grid item xs={dialogFormikProps.values.creativeID ? 6 : 12}>
                  <TextField
                    name="fileUrl"
                    inputProps={{ "aria-label": "Without label" }}
                    placeholder="Enter Link"
                    disabled={!editState || component === "superadmin"}
                    formikProps={dialogFormikProps}
                    onChange={(e) => handleFileUrl(e, dialogFormikProps.values)}
                  />
                </Grid>

                {dialogFormikProps.values.creativeID && (
                  <Grid item xs={6}>
                    <ImageDiv>
                      <a
                        href={dialogFormikProps.values.fileUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "fill",
                            borderRadius: "6px",
                          }}
                          src={dialogFormikProps.values.fileUrl}
                          alt="!"
                        />
                      </a>
                    </ImageDiv>
                  </Grid>
                )}
              </Fragment>
            )}
          </Fragment>
        </Fragment>
      )}

      {dialogFormikProps.values.format === "ad-tag" && (
        <Fragment>
          <Grid item xs={12}>
            <Label
              title="AD Tag"
              name="adTag"
              required={dialogFormikProps.values.format === "ad-tag" && true}
              formikProps={dialogFormikProps}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              multiline
              required
              name="adTag"
              disabled={!editState || component === "superadmin"}
              formikProps={dialogFormikProps}
              placeholder="Enter ad tag"
              inputProps={{ "aria-label": "Without label" }}
            />
          </Grid>
        </Fragment>
      )}

      <Grid item xs={12}>
        <Label
          title="Friendly Name"
          name="friendlyName"
          required={true}
          formikProps={dialogFormikProps}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="friendlyName"
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter Friendly Name"
          disabled={!editState || component === "superadmin"}
          formikProps={dialogFormikProps}
        />
      </Grid>

      <Grid item container xs={12}>
        <Grid item xs={5}>
          <Label
            title="Destination URL"
            name="destination_url"
            required={true}
            formikProps={dialogFormikProps}
          />
        </Grid>

        <Grid item xs={7} display={"flex"}>
          <Checkbox
            checked={destinationURLCheckbox}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 38 } }}
            disabled={!editState || component === "superadmin"}
            onClick={() => {
              dialogFormikProps.setValues((prev) => {
                return {
                  ...prev,
                  destinationURLCheckbox: !destinationURLCheckbox,
                  destination_url: formikProps.values.destination_url.value,
                };
              });
              setDestinationURLCheckbox(!destinationURLCheckbox);
            }}
          />

          <Label
            title="Same as Campaign URL"
            name="destination_url"
            required={true}
            formikProps={dialogFormikProps}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="destination_url"
          inputProps={{ "aria-label": "Without label" }}
          placeholder="Enter Destination URL"
          disabled={
            component === "superadmin" ||
            (destinationURLCheckbox ? destinationURLCheckbox : !editState)
          }
          formikProps={dialogFormikProps}
        />
      </Grid>

      <Grid item xs={12} />

      <ConfirmDialog
        confirm={confirm}
        setConfirm={setConfirm}
        handleClose={handleClose}
        formikProps={formikProps}
        dialogFormikProps={dialogFormikProps}
      />
    </Fragment>
  );

  function handleAdSizeChange(e) {
    dialogFormikProps.setValues((prev) => ({
      ...prev,
      adSize: e.target.value,
    }));

    if (dialogFormikProps.values.fileUrl || dialogFormikProps.values.creative) {
      const extension =
        dialogFormikProps.values.fileUrl
          .split(/[#?]/)[0]
          .split(".")
          .pop()
          .trim() ||
        dialogFormikProps.values.creative
          .split(/[#?]/)[0]
          .split(".")
          .pop()
          .trim();

      axios({
        url: "/getImageSize",
        method: "POST",
        data: {
          link:
            dialogFormikProps.values.fileUrl ||
            dialogFormikProps.values.creative,
          extension: extension,
        },
      }).then((response) => {
        if (response.success) {
          if (response.data > 0 && response.data <= 2097152) {
            const image = new Image();

            image.src =
              dialogFormikProps.values.fileUrl ||
              dialogFormikProps.values.creative;

            image.onload = function () {
              const { width, height } = this;

              const adSizeWidth = adSize.filter(
                (item) => item.id == e.target.value
              );

              if (
                adSizeWidth[0].width !== width ||
                adSizeWidth[0].height !== height
              ) {
                dispatch(
                  alert.error("Image Dimension Mismatched with Ad size ")
                );
                // setIsValidated(false);
              } else {
                dispatch(
                  alert.success("Image Dimension Matched with Ad size ")
                );
                // setIsValidated(true);
              }
            };
          } else {
            dispatch(
              alert.error("File size too large! Make smaller (under 2 MB)")
            );
            return;
          }
        }
      });
    }
  }

  function handleFormat(e) {
    let value = e.target.value;

    dialogFormikProps.setValues((prev) => ({
      ...prev,
      format: value,
      ...(Boolean(value === "ad-tag") && { upload: " " }),
    }));
  }

  async function handleFileUrl(e, value) {
    const file = e.target.value;

    const extension = file.split(/[#?]/)[0].split(".").pop().trim();

    const isImageFormat = value.format === "image";
    const acceptedFormats = isImageFormat
      ? [
          ".jpeg",
          ".jpg",
          ".png",
          ".webp",
          ".gif",
          "image/webp",
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/gif",
        ]
      : [".mp4", ".mkv", ".avi", "video/mp4"];

    if (!!file && !acceptedFormats.includes(`.${extension}`)) {
      dispatch(
        alert.error("Unsupported file format. Please select a valid file.")
      );
      return;
    } else {
      dialogFormikProps.setValues((prev) => ({
        ...prev,
        fileUrl: e.target.value,
      }));

      if (file) {
        axios({
          url: "/getImageSize",
          method: "POST",
          data: {
            link: file,
            extension: extension,
          },
        }).then((response) => {
          if (response.success) {
            if (response.data > 0 && response.data <= 2097152) {
              const image = new Image();

              image.src = file;

              image.onload = function () {
                const { width, height } = this;

                const adSizeWidth = adSize.filter(
                  (item) => item.id == value.adSize
                );

                if (
                  adSizeWidth[0].width !== width ||
                  adSizeWidth[0].height !== height
                ) {
                  dispatch(
                    alert.error("Image Dimension Mismatched with Ad size ")
                  );
                  // setIsValidated(false);

                  dialogFormikProps.setValues((prev) => ({
                    ...prev,
                    fileUrl: e.target.value,
                  }));
                } else {
                  dialogFormikProps.setValues((prev) => ({
                    ...prev,
                    fileUrl: e.target.value,
                  }));
                  // setIsValidated(true);
                }
              };
            } else {
              dispatch(
                alert.error("File size too large! Make smaller (under 2 MB)")
              );
              return;
            }
          }
        });
      }
    }
  }

  function handleFileChange(event, value, adSizeValue) {
    const file = adSizeValue ? event : event.target.files[0];

    if (!file) {
      return;
    }

    const isImageFormat = value.format === "image";
    const acceptedFormats = isImageFormat
      ? [
          ".jpeg",
          ".jpg",
          ".png",
          ".webp",
          ".gif",
          "image/webp",
          "image/jpeg",
          "image/jpg",
          "image/png",
          "image/gif",
        ]
      : [".mp4", ".mkv", ".avi", "video/mp4"];

    const fileSize = Math.round(file.size / 1024);

    if (fileSize > 2 * 1024) {
      dispatch(alert.error("File size too large! Make smaller (under 2 MB)"));
      return;
    }

    const fileExtension = file.name.split(".").pop().toLowerCase();

    if (!acceptedFormats.includes(`.${fileExtension}`)) {
      dispatch(
        alert.error("Unsupported file format. Please select a valid file.")
      );
      return;
    }

    if (isImageFormat) {
      validateImageSize(file, value, adSizeValue);
    } else {
      updateFormValues(file);
    }
  }

  function validateImageSize(file, value, adSizeValue) {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = function (e) {
      const image = new Image();
      image.src = e.target.result;

      image.onload = function () {
        const { width, height } = this;
        const adSizeWidth = adSize.filter(
          (item) => item.id == (adSizeValue ? adSizeValue : value?.adSize)
        );

        if (
          adSizeWidth[0].width !== width ||
          adSizeWidth[0].height !== height
        ) {
          // setIsValidated(false);
          dispatch(alert.error("Image Dimension Mismatched with Ad size "));
          // updateFormValues(file);
        } else {
          // setIsValidated(true);
          dispatch(alert.success("Image Dimension matched with Ad size "));
          updateFormValues(file);
        }
      };
    };
  }

  function updateFormValues(file) {
    const fleSize = Math.round(file.size / 1024);
    let filePath =
      "mox/public/creative/" + new Date().getTime() + "_" + file.name;

    if (fleSize <= 8 * 1024) {
      uploadToS3({
        bucket: {
          name: "adn-media",
          region: "ap-south-1",
          identityPoolID: "ap-south-1:16fa1fcf-7cdd-4593-9ee0-6f621526defc",
        },
        filePath: filePath,
        file,
      }).then((response) => {
        if (response.status) {
          dialogFormikProps.setValues((prevVal) => ({
            ...prevVal,
            previewImage: "",
            fileName: file.name,
            creative:
              "https://adn-media.s3.ap-south-1.amazonaws.com/" + filePath,
          }));
        }
      });
    }
  }
}
