import * as React from "react";
import { makeStyles } from "@mui/styles";

import { Paper, InputBase, IconButton, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles({
  input: {
    "&::placeholder": {
      fontWeight: 400,
      fontsize: "14px",
      lineHeight: "20px",
      color: "#000000",
      mixBlendMode: "normal",
      opacity: "0.4",
    },
  },
});

export default function Search() {
  const classes = useStyles();

  return (
    <Grid item xs={3} style={{ paddingRight: "22px" }}>
      <Paper
        component="form"
        elevation={0}
        sx={{
          p: "15px 18px",
          display: "flex",
          alignItems: "center",
          width: "inherit",
          height: "50px",
          borderRadius: "10px",
          justifyContent: "space-between",
        }}
      >
        <InputBase
          fullWidth
          inputProps={{
            className: classes.input,
          }}
          placeholder="Search"
        />
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon color="rgba(128, 128, 128, 0.7)" />
        </IconButton>
      </Paper>
    </Grid>
  );
}
