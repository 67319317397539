import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CampaignStatus from "./Status";
import FormSixth from "./PreviewImageForm";
import pages from "../../../constants/pages";
import CampaignHeader from "./CampaignHeader";
import FormOne from "./CreateCampaignFirstForm";
import FormTwo from "./CreateCampaignSecondForm";
import CampaignLiveUser from "./CampaignLiveUser";
import BASE_URL from "../../../constants/baseURL";
import FormFifth from "./CreateCampaignFifthForm";
import FormFourth from "./CreateCampaignFouthForm";
import { useAlert, useAxios } from "../../../hooks";
import ExploreConnection from "./ExploreConnection";
import CampaignButtons from "./CreateCampaignButtons";
import AdminCampaignStatus from "./AdminCampaignStatus";
import QuickStats from "../quick stats components/QuickStats";
import TakeUpDialog from "../take up offer components/TakeUpDailog";
import { Accept, PrimaryButton } from "../../css components/Button";
import RejectOfferDialog from "../reject offer components/RejectOfferDialog";
import BrandDetailsDialog from "../brand details components/BrandDetailsDialog";
import { CreatedAndUpdatedInfo, PageHeader, Paper } from "../../../components";
import Connections from "../superadmin campaign details component/ConnectionList";

export default function CampaignForm({
  cloneID,
  categories,
  audiences,
  formikProps,
  editState,
  setEditState,
  component = "",
  takeUpOffer,
  setTakeUpOffer,
  liveUsers,
  brandDetails,
  setBrandDetails,
  audienceData,
  setAudienceCreated,
  icon,
  rejectOffer,
  setRejectOffer,
  connectionList,
  setAudienceData,
  connectionRealStats,
  setConnectionRealStats,
  loadingLiveUsers,
  userData,
  setLiveUsers,
  setLoadingLiveUsers,
}) {
  const navigate = useNavigate();
  const axios = useAxios();
  const { alert } = useAlert();
  const campaignID = useParams();
  const dispatch = useDispatch();
  const [brandIdentity, setBrandIdentity] = useState(false);
  const { activeBrand, parentState } = useSelector(
    (state) => state.memberDetails
  );

  useEffect(() => {
    if (formikProps.values.brandID) {
      if (parseInt(formikProps.values.brandID) !== parseInt(activeBrand.ID)) {
        setBrandIdentity(true);
      }
    }
  }, [formikProps.values.brandID, activeBrand.ID]);

  function handleDuplicateCampaign() {
    axios({
      url: "/user/brand/campaign/copy/" + campaignID.campaignID,
      method: "GET",
      disableRedirect: true,
    }).then((response) => {
      if (response.success) {
        navigate(pages.campaignDetails.route + response.data.campaign_id);
      }
    });
  }

  function handlePublishCampaign() {
    if (
      Boolean(
        new Date(formikProps.values.start_duration?.value).getTime() <
          new Date().getTime()
      )
    ) {
      dispatch(
        alert({
          message: "Please update the campaign start date.",
          type: "error",
        })
      );
    } else {
      axios({
        url:
          "/user/brand/campaign/updateCampaignStatus/" + campaignID.campaignID,
        method: "PUT",
        disableRedirect: true,
        data: {
          existingStatus: "DRAFTED",
          updatedStatus: "CREATED",
        },
      }).then((response) => {
        if (response.success) {
          window.location.reload(true);
          navigate(pages.campaignDetails.route + response.data.campaign_id);
        }
      });
    }
  }

  return (
    <Fragment>
      {!formikProps.values.campaignID ? (
        <Grid item xs={12}>
          <PageHeader
            heading={
              component !== "request"
                ? "My Campaigns"
                : "Request Campaign Details"
            }
            twoText={!formikProps.values.campaignID && true}
            firstText={"Create Campaign. "}
            secondText={"Start Converting."}
            buttons={[
              <CampaignButtons
                ID={formikProps.values.campaignID}
                formikProps={formikProps}
                editState={editState}
                setEditState={setEditState}
                component={component}
                brandIdentity={brandIdentity}
                setTakeUpOffer={setTakeUpOffer}
                setRejectOffer={setRejectOffer}
              />,
            ]}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <CampaignHeader
            heading={
              component !== "request"
                ? component === "accepted"
                  ? "Accepted Campaign"
                  : "My Campaigns"
                : "Request Campaign Details"
            }
            campaignOverAllStatus={formikProps.values.campaignOverAllStatus}
            formikProps={formikProps}
            brandName={
              component === ""
                ? activeBrand.brandName
                : brandDetails?.data?.brandName
            }
            iconImage={component === "" ? activeBrand.logo : icon}
            pageName={
              formikProps.values?.campaignStatus === "DRAFTED"
                ? formikProps.values.campaign_name?.value + "   [DRAFTED]"
                : formikProps.values.campaign_name?.value
            }
            buttons={[
              <CampaignButtons
                ID={formikProps.values.campaignID}
                formikProps={formikProps}
                editState={editState}
                setEditState={setEditState}
                component={component}
                liveUsers={liveUsers}
                brandIdentity={brandIdentity}
                setTakeUpOffer={setTakeUpOffer}
                setRejectOffer={setRejectOffer}
              />,
              component !== "request" &&
                formikProps.values.campaignStatus === "DRAFTED" && (
                  <Accept
                    variant="contained"
                    onClick={handlePublishCampaign}
                    disabled={editState}
                  >
                    Publish Campaign
                  </Accept>
                ),
              parentState !== "SA" &&
                component !== "request" &&
                formikProps.values.campaignStatus !== "DRAFTED" && (
                  <PrimaryButton
                    variant="contained"
                    onClick={handleDuplicateCampaign}
                    style={{ minWidth: "160px", maxWidth: "255px" }}
                  >
                    Duplicate
                  </PrimaryButton>
                ),
            ]}
          />
        </Grid>
      )}

      {Boolean(connectionRealStats?.length) &&
        formikProps.values.campaignStatus !== "DRAFTED" && (
          <QuickStats
            connectionRealStats={connectionRealStats}
            setConnectionRealStats={setConnectionRealStats}
            connectionList={connectionList}
            component={component}
          />
        )}

      {component !== "" &&
        (formikProps.values.campaignStatus !== "REQUESTED" ||
          formikProps.values.campaignOverAllStatus === "ADMIN_REJECTED") && (
          <Grid item xs={12}>
            <CampaignStatus
              formikProps={formikProps}
              setTakeUpOffer={setTakeUpOffer}
            />
          </Grid>
        )}

      {component === "" && !!formikProps.values.campaignID && !cloneID && (
        <AdminCampaignStatus formikProps={formikProps} />
      )}

      {component === "" && !cloneID && (
        <Connections connections={connectionList} />
      )}

      <Grid container spacing={8}>
        <Grid item xs={6}>
          <FormOne
            categories={categories}
            brandDetails={brandDetails}
            setBrandDetails={setBrandDetails}
            formikProps={formikProps}
            editState={editState}
            component={component}
            brandIdentity={brandIdentity}
          />

          <FormTwo
            setAudienceData={setAudienceData}
            formikProps={formikProps}
            audiences={audiences}
            editState={editState}
            component={component}
            brandIdentity={brandIdentity}
            audienceData={audienceData}
            setAudienceCreated={setAudienceCreated}
          />

          {formikProps.values.campaignID &&
            !cloneID &&
            component !== "request" &&
            component !== "accepted" &&
            !(formikProps.values.campaignStatus === "DRAFTED") && (
              <ExploreConnection formikProps={formikProps} />
            )}
        </Grid>

        <Grid item xs={6}>
          {/* <FormThird
            brandIdentity={brandIdentity}
            formikProps={formikProps}
            editState={editState}
            component={component}
          /> */}

          <FormFourth
            formikProps={formikProps}
            editState={editState}
            component={component}
            brandIdentity={brandIdentity}
          />

          {!brandIdentity && (
            <FormSixth
              formikProps={formikProps}
              editState={editState}
              component={component}
              brandIdentity={brandIdentity}
            />
          )}

          {formikProps.values.campaignID && !cloneID && (
            <FormFifth
              formikProps={formikProps}
              editState={editState}
              component={component}
              brandIdentity={brandIdentity}
            />
          )}

          {(formikProps.values.createdAt || formikProps.values.updatedAt) && (
            <Paper>
              <CreatedAndUpdatedInfo formikProps={formikProps} />
            </Paper>
          )}

          {brandIdentity && !!formikProps.values.audienceID && (
            <CampaignLiveUser
              userData={userData}
              liveUsers={liveUsers}
              component={component}
              takeUpOffer={takeUpOffer}
              setLiveUsers={setLiveUsers}
              loadingLiveUsers={loadingLiveUsers}
              setLoadingLiveUsers={setLoadingLiveUsers}
            />
          )}
        </Grid>
      </Grid>

      <RejectOfferDialog reject={rejectOffer} setReject={setRejectOffer} />
      <TakeUpDialog
        takeUp={takeUpOffer}
        formikProps={formikProps}
        setTakeUp={setTakeUpOffer}
      />
      <BrandDetailsDialog details={brandDetails} setDetails={setBrandDetails} />
    </Fragment>
  );
}
