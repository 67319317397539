import React from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";

import Form from "./password change component/Form";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function ResetChangePassword() {
  const params = useParams();
  const code = params.code || "";

  return (
    <Wrapper>
      <Helmet title="Change Password" />
      <Form code={code} />
    </Wrapper>
  );
}
