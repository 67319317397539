import { Navigation } from "swiper";
import styled from "styled-components";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, MenuItem, Typography, Avatar } from "@mui/material";

import "./style.css";
import "swiper/css";
import "swiper/css/navigation";
import pages from "../../constants/pages";
import { TextField } from "../../components";
import BASE_URL from "../../constants/baseURL";
import { useDispatch, useSelector } from "react-redux";
import {
  setDashboardState,
  signInMember,
} from "../../redux/slices/memberSlice";
import {
  CancelButton,
  PrimaryButton,
  PrimaryLoadingButton,
} from "../css components/Button";

const WelcomeTypography = styled(Typography)`
  font-weight: 500;
  font-size: 2vw;
  color: rgba(0, 0, 0, 1);
`;

const BrandTypography = styled(Typography)`
  font-weight: 500;
  font-size: 2vw;
  color: rgba(189, 189, 189, 1);
`;

const Image = styled(Avatar)`
  width: 120px;
  height: 120px;
  border: 1px solid;
`;

const SwiperDiv = styled(Grid)`
  height: 200px;
  margin: 20px 0;
`;

export default function Form({ businessEntityID, brands, authToken, userID }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dashboardState, brandType, activeBrand } = useSelector(
    (state) => state.memberDetails
  );

  const [currentBrand, setCurrentBrand] = useState({
    ID: activeBrand.ID || brands[0].ID,
    name: activeBrand.name || brands[0].brandName,
    type: activeBrand.brandType || brands[0].brandType || brandType,
  });
  const [userDashboardState, setUserDashboardState] = useState(
    dashboardState || "monetization"
  );

  return (
    <Fragment>
      <Grid item xs={8} mt="10px">
        <WelcomeTypography>You’re Logged In.</WelcomeTypography>

        <BrandTypography>Select Your Channel</BrandTypography>
      </Grid>

      <Grid
        item
        xs={4}
        mt="10px"
        style={{ alignSelf: "center", textAlign: "end" }}
      >
        <PrimaryButton variant="contained" onClick={addNewBrand}>
          Add Brand
        </PrimaryButton>
      </Grid>

      <Grid item xs={12} textAlign="left" marginTop={"5px"}>
        <TextField
          select
          placeholder="Select Role"
          inputProps={{ "aria-label": "Without label" }}
          name="role"
          value={userDashboardState}
          onChange={setDashboardUser}
          disabled={currentBrand.type !== 3}
        >
          <MenuItem value={`promotion`}>I am a Promotion Partner</MenuItem>
          <MenuItem value={`monetization`}>
            I am a Monetization Partner
          </MenuItem>
        </TextField>
      </Grid>

      <SwiperDiv item xs={12}>
        <Swiper
          slidesPerView={3}
          spaceBetween={1}
          navigation={true}
          modules={[Navigation]}
          className="mySwiper"
        >
          {brands.map((brand) => (
            <SwiperSlide key={brand.ID}>
              <Image
                style={{
                  cursor: "pointer",
                  border:
                    currentBrand.ID === brand.ID
                      ? "3px solid #02a0fc"
                      : "1px solid #999999",
                }}
                onClick={() =>
                  setBrand(brand.ID, brand.brandName, brand.brandType)
                }
                src={brand.logo}
              ></Image>
            </SwiperSlide>
          ))}

          {brands.ID && (
            <Fragment>
              {brands.length < 2 && (
                <Fragment>
                  <SwiperSlide>
                    <Image>.</Image>
                  </SwiperSlide>

                  {brands.length === 1 && (
                    <Fragment>
                      <SwiperSlide>
                        <Image>.</Image>
                      </SwiperSlide>
                      <SwiperSlide>
                        <Image>.</Image>
                      </SwiperSlide>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </Swiper>
      </SwiperDiv>

      <Grid item xs />

      <Grid item style={{ paddingTop: "0px" }}>
        <CancelButton
          onClick={() => {
            navigate(pages.signIn.route);
          }}
          variant="outlined"
        >
          Cancel
        </CancelButton>
      </Grid>

      <Grid item style={{ paddingTop: "0px" }}>
        <PrimaryLoadingButton
          type="submit"
          variant="contained"
          disabled={!currentBrand.ID}
          onClick={async () => {
            localStorage.setItem(
              "dashboardState" + currentBrand.ID,
              userDashboardState
            );
            await dispatch(
              signInMember({
                authToken: authToken,
                userID: userID,
                businessEntityId: businessEntityID,
                brandType: currentBrand.type,
              })
            );

            navigate(pages.brandDetails.route);
          }}
        >
          Let's Go
        </PrimaryLoadingButton>
      </Grid>
    </Fragment>
  );

  async function addNewBrand() {
    localStorage.setItem("dashboardState" + currentBrand.ID, "monetization");
    dispatch(setDashboardState("monetization"));
    navigate(pages.newBrandCreate.route);
  }

  function setBrand(ID, name, type) {
    if (type === 2) {
      setUserDashboardState("monetization");
    }
    setCurrentBrand({
      ID: ID,
      name: name,
      type: type,
    });
    sessionStorage.setItem("activeBrand", ID);
    sessionStorage.setItem("brandType", type);
  }

  function setDashboardUser(e) {
    setUserDashboardState(e.target.value);
  }
}
