import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";

import pages from "../../../constants/pages";
import BASE_URL from "../../../constants/baseURL";
import List from "../campaign list components/List";
import { useAxios, useComponent } from "../../../hooks";
import { Loader, PageHeader } from "../../../components";
import { PrimaryButton } from "../../css components/Button";
import { NoListTypography } from "../../css components/Style";
import SortBy from "../campaign list components/campaign dp list components/SortBy";
import Search from "../campaign list components/campaign consumer list components/Search";
import Categorize from "../campaign list components/campaign dp list components/Categorize";
import { toInternationalFormat } from "../../../utils";

export default function Campaign() {
  const axios = useAxios();
  const navigate = useNavigate();
  const { loader } = useComponent();

  const [campaigns, setCampaigns] = useState([]);
  const [emptyList, setEmptyList] = useState(true);
  const [categorize, setCategorize] = useState("Campaign Offers");
  const [sortBy, setSortBy] = useState(
    categorize === "Campaign Offers" ? "" : ""
  );

  const { activeBrand } = useSelector((state) => state.memberDetails);

  const options = [
    { value: "Campaign Offers", label: "Campaign Offers", color: "#02A0FC" },
    // { value: "ACTIVE", label: "Active Campaign", color: "#34B53A" },
  ];

  let sortList = [
    { value: "", label: "Sort By" },
    { value: "OFFER_ACCEPTED", label: "Accepted", color: "#5AC35F" },
    { value: "PENDING", label: "Pending", color: "#FFBB0B" },
    { value: "REJECTED", label: "Rejected", color: "#FF3A29" },
  ];

  if (categorize === "ACTIVE") {
    sortList = [
      { value: "", label: "All" },
      { value: "ACTIVE", label: "Active", color: "#5AC35F" },
      { value: "EXPIRED", label: "Expired", color: "#FF3A29" },
    ];
  }

  useEffect(() => {
    loader.start();
    let url = "/user/brand/getCampaignsByBrand/?brandId=" + activeBrand.ID;

    if (sortBy === "") {
      url = "/user/brand/getCampaignsByBrand/?brandId=" + activeBrand.ID;
    } else {
      url =
        "/user/brand/getCampaignsByBrand/?brandId=" +
        activeBrand.ID +
        "&requestStatus=" +
        sortBy;
    }
    axios({
      url: url,
    }).then((response) => {
      if (response.success) {
        let arr = [];

        for (let key of response.data) {
          let data = {};
          for (let fields of key.fields) {
            if (
              Object.keys(fields)[0] === "budget_min" ||
              Object.keys(fields)[0] === "budget_max" ||
              Object.keys(fields)[0] === "mau" ||
              Object.keys(fields)[0] === "volume"
            ) {
              let val =
                typeof Object.values(fields)[0] === "number"
                  ? Object.values(fields)[0]
                  : Object.values(fields)[0]?.replace(/,/g, "");
              data[Object.keys(fields)[0]] = toInternationalFormat(val);
            } else {
              data[Object.keys(fields)[0]] =
                Object.values(fields)[0] && Object.values(fields)[0];
            }
          }
          arr.push({
            ...data,
            brand: {
              name: activeBrand.brandName,
              logo: activeBrand.logo,
            },
            previewImage: !!key.preview_image
              ? key.preview_image
              : "https://dc-chat-media.s3.ap-south-1.amazonaws.com/NaN_placeholder.png",
            status: key.status,
            audience_id: key.audience_id,
            brand_id: key.brand_id,
            campaign_id: key.campaign_id,
            userCount:
              key.total_users_count &&
              toInternationalFormat(key.total_users_count),
          });
        }
        setCampaigns(arr);
        loader.stop();
      } else {
        setEmptyList(false);
        loader.stop();
      }
    });
  }, [
    axios,
    categorize,
    activeBrand.ID,
    loader,
    sortBy,
    activeBrand.logo,
    activeBrand.brandName,
  ]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PageHeader
            heading={"Campaigns"}
            pageName={
              categorize === "Campaign Offers"
                ? "Campaign Offers"
                : "Active Campaigns"
            }
          />
        </Grid>
        {/* 
        <Categorize
          options={options}
          setCategorize={setCategorize}
          categorize={categorize}
          setSortBy={setSortBy}
        />

        <SortBy sortBy={sortBy} setSortBy={setSortBy} sortList={sortList} />

        <Search /> */}

        <Grid item xs={12} justifyContent={"end"} textAlign={"end"}>
          <PrimaryButton
            variant="contained"
            size="large"
            onClick={() => navigate(pages.createCampaign.route)}
          >
            Create Campaign
          </PrimaryButton>
        </Grid>

        <Grid item xs={12} />
      </Grid>

      <Loader height="75%">
        {campaigns.length > 0 ? (
          <List campaigns={campaigns} component="" />
        ) : (
          <Grid item xs={12} textAlign="center">
            <NoListTypography>No campaign created Yet!</NoListTypography>
          </Grid>
        )}
      </Loader>
    </Fragment>
  );
}
