import { Fragment, useState } from "react";
import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ArrowDropDown } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Divider,
  Grid,
  CircularProgress,
  MenuItem,
  Typography,
} from "@mui/material";

import { useAxios } from "../../../hooks";
import { Paper, TextField } from "../../../components";
import { useSelector } from "react-redux";

const MainHeading = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
`;

const RowText = styled(Typography)`
  font-weight: 400;
  font-size: 18px;
  line-height: 33px;
`;

const TitleHeading = styled(Typography)`
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  color: #02a0fc;
`;

const TableHeading = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const Menu = styled(MenuItem)`
  font-weight: 600;
`;

export default function QuickStats({
  connectionRealStats,
  setConnectionRealStats,
  connectionList,
  component = "",
}) {
  const axios = useAxios();

  const params = useParams();

  const campaignID = params.campaignID || "";

  const [dropDownValue, setDropDownValue] = useState("0");

  const [loading, setLoading] = useState(false);

  const [dropDown, setDropDown] = useState(
    connectionRealStats.map(() => false)
  );

  const { activeBrand } = useSelector((state) => state.memberDetails);

  function handleChange(e) {
    setLoading(true);

    setDropDownValue(e.target.value);

    let url =
      component === ""
        ? `bulk/adserver/bulkcounts/${campaignID}${
            e.target.value !== "lifetime" ? "?days=" + e.target.value : ""
          }`
        : `/bulk/adserver/bulkcounts/${campaignID}${
            e.target.value !== "lifetime"
              ? "?days=" + e.target.value + "&"
              : "?"
          }receiverBrand=${activeBrand.ID}`;

    axios({
      url: url,
      method: "GET",
    }).then((response) => {
      if (response.success) {
        setConnectionRealStats(response.data);

        setLoading(false);
      }
    });
  }

  function handleRefresh() {
    setLoading(true);

    let url =
      component === ""
        ? `bulk/adserver/bulkcounts/${campaignID}${
            dropDownValue !== "lifetime" ? "?days=" + dropDownValue : ""
          }`
        : `/bulk/adserver/bulkcounts/${campaignID}${
            dropDownValue !== "lifetime" ? "?days=" + dropDownValue + "&" : "?"
          }receiverBrand=${activeBrand.ID}`;

    axios({
      url: url,
      method: "GET",
    }).then((response) => {
      if (response.success) {
        setConnectionRealStats(response.data);

        setLoading(false);
      }
    });
  }

  return (
    <Paper>
      <Grid item container>
        <Grid item xs={9}>
          <TitleHeading>
            Real-time Statistics
            <RefreshIcon
              sx={{
                color: "#34B53A",
                verticalAlign: "middle",
                marginLeft: "10px",
                fontSize: "35px",
              }}
              onClick={handleRefresh}
            ></RefreshIcon>
          </TitleHeading>
        </Grid>

        <Grid item xs={3}>
          <TextField
            select
            value={dropDownValue}
            onChange={(e) => handleChange(e)}
            sx={{
              "& .MuiOutlinedInput-root": {
                fontWeight: "600",
                color: "#02A0FC",
              },
            }}
            inputProps={{
              "aria-label": "Without label",
              IconComponent: () => (
                <ArrowDropDown
                  style={{ marginRight: "8px", cursor: "pointer" }}
                />
              ),
            }}
          >
            <Menu value="0">Today</Menu>
            <Menu value="7">Last 7 Days</Menu>
            <Menu value="30">Last 30 Days</Menu>
            <Menu value="90">Last 90 Days</Menu>
            <Menu value="lifetime">Life Time </Menu>
          </TextField>
        </Grid>
      </Grid>

      {loading ? (
        <Grid ml={"45%"}>
          <CircularProgress></CircularProgress>
        </Grid>
      ) : (
        <Grid container item>
          <table style={{ width: "100%" }}>
            <thead style={{ opacity: "0.4", marginBottom: "5px" }}>
              <tr>
                <td style={{ width: "25%" }}>
                  <TableHeading>Connection</TableHeading>
                </td>

                <td>
                  <TableHeading>Impressions </TableHeading>
                </td>

                <td>
                  <TableHeading>Clicks</TableHeading>
                </td>

                <td>
                  <TableHeading>Unique Clicks</TableHeading>
                </td>

                <td>
                  <TableHeading>CTR</TableHeading>
                </td>

                <td>
                  <TableHeading>Conversions</TableHeading>
                </td>

                <td>
                  <TableHeading>Revenue</TableHeading>
                </td>
              </tr>
            </thead>

            <tbody>
              {connectionRealStats.map((items, index) => {
                return (
                  <Fragment>
                    <tr>
                      <td style={{ width: "20%" }}>
                        <MainHeading>
                          {component === "request"
                            ? activeBrand.brandName
                            : connectionList.filter((connection) => {
                                return (
                                  items.receiver_brandId ===
                                  connection.receiverBrandId
                                );
                              })[0]?.name}
                        </MainHeading>
                      </td>

                      <td>
                        <MainHeading>
                          {items.flightCounts.impressions}
                        </MainHeading>
                      </td>

                      <td>
                        <MainHeading>{items.flightCounts.clicks}</MainHeading>
                      </td>

                      <td>
                        <MainHeading>
                          {items.flightCounts.uniqueclicks || "0"}
                        </MainHeading>
                      </td>

                      <td>
                        <MainHeading>
                          {items.flightCounts.impressions &&
                            Math.round(
                              (items.flightCounts.clicks /
                                items.flightCounts.impressions) *
                                100
                            )}
                          %
                        </MainHeading>
                      </td>

                      <td>
                        <MainHeading>
                          {items.flightCounts.conversions}
                        </MainHeading>
                      </td>

                      <td>
                        <MainHeading>
                          {parseFloat(items.flightCounts.revenue).toFixed(2)}
                        </MainHeading>
                      </td>

                      <td></td>

                      <td>
                        {Boolean(items.flightCounts.impressions) && (
                          <MainHeading>
                            {!dropDown[index] && items.adCount.length && (
                              <AddIcon
                                sx={{ color: "#02A0FC" }}
                                onClick={() => {
                                  setDropDown((prev) =>
                                    prev.map((items, i) =>
                                      i == index ? true : items
                                    )
                                  );
                                }}
                              ></AddIcon>
                            )}
                            {dropDown[index] && items.adCount.length && (
                              <RemoveIcon
                                sx={{ color: "#02A0FC" }}
                                onClick={() => {
                                  setDropDown((prev) =>
                                    prev.map((items, i) =>
                                      i == index ? false : items
                                    )
                                  );
                                }}
                              ></RemoveIcon>
                            )}
                          </MainHeading>
                        )}
                      </td>
                    </tr>

                    <Divider
                      sx={{
                        width: "400%",
                        opacity: !dropDown[index] && "0.4",
                        margin: "5px 0px",
                        border: `${!dropDown[index] ? "1px" : "2px"} solid ${
                          !dropDown[index] ? "#000" : "#02A0FC"
                        }`,
                        borderRadius: "50px",
                        display:
                          !dropDown[index] &&
                          index + 1 === connectionRealStats.length &&
                          "none",
                      }}
                    />

                    {dropDown[index] && items.adCount.length && (
                      <Fragment>
                        {items.adCount.map((ite, indx) => {
                          return (
                            <Fragment>
                              {Boolean(
                                ite.creative_name && ite.count.impressions
                              ) && (
                                <Fragment>
                                  <tr>
                                    <td
                                      style={{
                                        color: "#02A0FC",
                                        width: "20%",
                                        paddingLeft: "15px",
                                      }}
                                    >
                                      <MainHeading
                                        sx={{
                                          color: !ite.is_active && "Grey",
                                        }}
                                      >
                                        {ite.creative_name}
                                      </MainHeading>
                                    </td>
                                    <td>
                                      <RowText>{ite.count.impressions}</RowText>
                                    </td>
                                    <td>
                                      <RowText>{ite.count.clicks}</RowText>
                                    </td>
                                    <td>
                                      <RowText>
                                        {ite.count.uniqueclicks || "0"}
                                      </RowText>
                                    </td>
                                    <td>
                                      <RowText>
                                        {" "}
                                        {ite.count.impressions &&
                                          Math.round(
                                            (ite.count.clicks /
                                              ite.count.impressions) *
                                              100
                                          )}
                                        %
                                      </RowText>
                                    </td>
                                    <td>
                                      <RowText>{ite.count.conversions}</RowText>
                                    </td>
                                    <td>
                                      <RowText>
                                        {parseFloat(ite.count.revenue).toFixed(
                                          2
                                        )}
                                      </RowText>
                                    </td>
                                  </tr>

                                  <Divider
                                    sx={{
                                      width: "400%",
                                      opacity: "0.4",
                                      background: "#02A0FC",
                                      border: "1px solid #02A0FC",
                                      margin: "5px 0px",
                                      borderRadius: "50px",
                                      display:
                                        items.adCount.length === indx + 1 &&
                                        "none",
                                    }}
                                  />
                                  {items.adCount.length === indx + 1 &&
                                    index + 1 !==
                                      connectionRealStats.length && (
                                      <Divider
                                        sx={{
                                          width: "400%",
                                          background: "#02A0FC",
                                          margin: "5px 0px",
                                          border: "2px solid #02A0FC",
                                          borderRadius: "50px",
                                        }}
                                      />
                                    )}
                                </Fragment>
                              )}
                            </Fragment>
                          );
                        })}
                      </Fragment>
                    )}

                    {index + 1 === connectionRealStats.length && (
                      <Divider
                        sx={{
                          width: "400%",
                          background: "#000",
                          margin: "5px 0px",
                          borderRadius: "50px",
                          border: "2px solid black",
                        }}
                      />
                    )}
                  </Fragment>
                );
              })}

              <tr style={{ color: "#02A0FC" }}>
                <td style={{ width: "20%" }}>
                  <MainHeading>Total</MainHeading>
                </td>

                <td>
                  <MainHeading>
                    {connectionRealStats[0].campaignCount.impressions}
                  </MainHeading>
                </td>

                <td>
                  <MainHeading>
                    {connectionRealStats[0].campaignCount.clicks}
                  </MainHeading>
                </td>

                <td>
                  <MainHeading>
                    {connectionRealStats[0].campaignCount.uniqueclicks || "0"}
                  </MainHeading>
                </td>

                <td>
                  <MainHeading>
                    {connectionRealStats[0].campaignCount.impressions &&
                      Math.round(
                        (connectionRealStats[0].campaignCount.clicks /
                          connectionRealStats[0].campaignCount.impressions) *
                          100
                      )}
                    %
                  </MainHeading>
                </td>

                <td>
                  <MainHeading>
                    {connectionRealStats[0].campaignCount.conversions}
                  </MainHeading>
                </td>

                <td>
                  <MainHeading>
                    {parseFloat(
                      connectionRealStats[0].campaignCount.revenue
                    ).toFixed(2)}
                  </MainHeading>
                </td>
              </tr>

              <Divider
                sx={{
                  width: "400%",
                  border: "2px solid black",
                  background: "#000",
                  margin: "5px 0px",
                }}
              />
            </tbody>
          </table>
        </Grid>
      )}
    </Paper>
  );
}
