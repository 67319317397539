import { Grid, Divider } from "@mui/material";
import { useSelector } from "react-redux";

import { Navbar } from "./Navbar";
import SelectRole from "./SelectRole";

export default function Welcome() {
  const { brands, authToken, userID, businessEntityID } = useSelector(
    (state) => state.memberDetails
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Navbar />
      </Grid>

      <Grid item xs={12} style={{ padding: "0 50px" }}>
        {/* <Divider /> */}
      </Grid>

      <Grid item xs={12} style={{ paddingTop: "0px" }}>
        <SelectRole
          brands={brands}
          businessEntityID={businessEntityID}
          authToken={authToken}
          userID={userID}
        />
      </Grid>
    </Grid>
  );
}
